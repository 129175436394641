import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { TCrudType, TExpenditureCategory } from '../../utilities/types';
import useUserData from '../../hooks/customHooks';
import Expenses from '../../utilities/classObjects/Expenses';
import { displayMessage, getStateData, getTableRowId, isAppOnline } from '../../utilities/utilFunctions';
import Settings from '../../utilities/classObjects/Settings';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import { useQueryClient } from '@tanstack/react-query';
import { expenseCategoriesListQuery } from '../../utilities/reactQueryUtils';
import ExpenseCategoryList from '../DataTables/ExpenseCategoryList';

type TExpenseCategoryPage = TExpenditureCategory & {
    isLoading: boolean;
    crudType: TCrudType,
    appState: string,
    categoriesList:TExpenditureCategory[]
}
const INITIAL_STATE: TExpenseCategoryPage = {
    categoryDescription: '',
    categoryId: 0,
    hotelId: 0,
    hotelLocationId: 0,
    isLoading: false,
    crudType: 'save',
    appState: 'onLine',
    categoriesList:[]
};
const expenses = new Expenses();
const setting = new Settings();
const ExpenseCategory = ({reQueryCategoryList}:{reQueryCategoryList:()=>void}) => {
    const [state, setState] = useState<TExpenseCategoryPage>(INITIAL_STATE);
    const toastRef = useRef(null);
    const [userData] = useUserData();
    const setStateValues = (stateValues: Partial<TExpenseCategoryPage>) => {
        setState((prevState) => {
            return { ...prevState!, ...stateValues };
        });
    };
    const queryClient = useQueryClient();

    useEffect(() => {
        const initCategoryPage = async () => {
            const appSettings = await setting.getLocationSettings();
            const categoryList=await queryExpenditureCategory(appSettings.connectivityState);
            setStateValues({
                hotelId: userData.hotelId,
                hotelLocationId: userData.hotelLocationId,
                appState: appSettings.connectivityState,
                categoriesList:categoryList
            });
        };
        initCategoryPage().catch(console.error);
    }, []);
    const getExpenditureState = () => {
        return getStateData<TExpenditureCategory>(['categoryId', 'categoryDescription', 'hotelId', 'hotelLocationId'], state);
    };
    const queryExpenditureCategory=async(appState:string)=>{
        return isAppOnline(appState)?await expenseCategoriesListQuery(queryClient,userData.hotelLocationId):await expenses.getExpenditureCategoriesOffline(userData.hotelLocationId);
    }
    const saveCategory = async () => {
        try {
            const categoryData = getExpenditureState();
            setStateValues({isLoading:true});
            const savedExpenseCategoryResults = isAppOnline(state.appState) ? await expenses.addNewExpenditureCategory(categoryData, state.crudType) : await expenses.addOfflineCategory(categoryData, state.crudType);
            console.log(savedExpenseCategoryResults);
            if (savedExpenseCategoryResults.status === 200) {
                state.crudType === 'save' ? await addRecordToCache<TExpenditureCategory>(queryClient, ['expenditureCategoryList', userData.hotelLocationId],
                    savedExpenseCategoryResults.operatedData!) : await updateCacheRecord<TExpenditureCategory>(queryClient, ['expenditureCategoryList', userData.hotelLocationId],
                    [savedExpenseCategoryResults.operatedData, state.categoryId, 'categoryId']);
                setStateValues({categoryDescription:'',crudType:'save',categoriesList:await queryExpenditureCategory(state.appState)});
                reQueryCategoryList();// refresh dropdown list
                displayMessage({
                    header: 'Success',
                    message: 'Expense Category Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
            }
        } catch (error) {
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    };
    const setupCategoryEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const selectedCategory=state.categoriesList.find(category=>category.categoryId===parseInt(getTableRowId(e,'id')));
        if(selectedCategory!==undefined){
            const {categoryId,categoryDescription,hotelLocationId,hotelId}=selectedCategory;
            setStateValues({
                categoryDescription,
                categoryId,
                hotelLocationId,
                hotelId,
                crudType:'update'
            });
        }
    }
    const promptCategoryDelete=()=>{

    }
    return <>
        {state.isLoading && <Loader />}
        <GeneralPageProps toastRef={toastRef} />
        <div className="card">
            <div className="grid p-fluid">
                <div className="col-4">
                    <div className="field">
                        <label>Category Description</label>
                        <InputText value={state.categoryDescription} onChange={(e) => setStateValues({ categoryDescription: e.target.value })} />
                    </div>
                    <Button onClick={saveCategory}>{state.crudType === 'save' ? 'Save Expense Category' : 'Update Expense Category'}</Button>
                </div>
                <div className="col-8">
                    <ExpenseCategoryList tableData={state.categoriesList} setupTableDataEdit={setupCategoryEdit} promptTableDataDelete={promptCategoryDelete}/>
                </div>
            </div>
        </div>
    </>;
};
export default ExpenseCategory;
