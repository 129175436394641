import Joi from 'joi';
import { TAdjustment, TExpenditure, TGuests, THotel, THotelEvents, THotelLocation, THotelRoom, TItem, TPurchase, TReservations, TSales, TUser } from '../types';

export const validatePersonalDetails:Joi.ObjectSchema<TUser>=Joi.object({
    staffName: Joi.string().required().messages({ 'string.empty': 'Please provide a valid name' }),
    phoneNumber: Joi.string().max(10).messages({ 'string.empty': 'Please provide a valid phone number','string.max':'Phone number should be 10 characters long' }),
    address: Joi.string().required().messages({ 'string.empty': 'Please provide a valid address' }),
    userRole:Joi.string().messages({ 'string.empty': 'Please provide a valid role' }),
    staffType:Joi.string().messages({ 'string.empty': 'Please provide a valid type for staff!' }),
    gender:Joi.string().messages({ 'string.empty': 'Please select gender for member' }),
    username: Joi.string().required().messages({ 'string.empty': 'Please provide a valid username' }),
    password: Joi.string().min(6).required().messages({ 'string.empty': 'Input a valid passphrase', 'string.min': 'Password should not be less than 6 characters' }),
    repeatPassword: Joi.string().valid(Joi.ref('password')).min(6).required().messages({ 'any.only': 'Password and repeated password must be equal!' }),
    dateOfBirth:Joi.date().optional(),
    email:Joi.string().optional().allow(''),
    staffId:Joi.number(),
    modifiedBy:Joi.number()
});
export const validateNewHotelDetails:Joi.ObjectSchema<THotel>=Joi.object({
    hotelId:Joi.number(),
    hotelName: Joi.string().required().messages({ 'string.empty': 'Please provide a valid description for hotel name' }),
    emailAddress: Joi.string().required().messages({ 'string.empty': 'Please provide a valid email Address for hotel' }),
    hotelPhone: Joi.string().max(10).required().messages({ 'string.empty': 'Please provide a valid phone Number for hotel','string.max':'Phone number should be 10 characters long' }),
});
export const validateNewHotelLocation:Joi.ObjectSchema<THotelLocation>=Joi.object({
    locationName: Joi.string().required().messages({ 'string.empty': 'Please provide a valid name for new location' }),
    locationAddress: Joi.string().required().messages({ 'string.empty': 'Please provide a valid name for new location' }),
    locationEmail: Joi.string().required().messages({ 'string.empty': 'Please provide a valid name for new location' }),
    locationPhoneNumber: Joi.string().max(10).required().messages({ 'string.empty': 'Please provide a valid name for new location','string.max':'Phone number should be 10 characters long' }),
    city:Joi.string().required().messages({ 'string.empty': 'Please provide a valid city' }),
    postCode:Joi.string().required().messages({ 'string.empty': 'Please provide a valid post code' }),
    countryCode:Joi.string().required().messages({ 'string.empty': 'Please provide a valid country' }),
    hotelId:Joi.number().optional(),
    hotelLocationId:Joi.number(),
    modifiedBy:Joi.number()
});
export const validateLogin = Joi.object({
    username: Joi.string().messages({ 'string.empty': 'Enter a correct username' }),
    password: Joi.string().messages({ 'string.empty': 'Enter a correct password' })
});
export const validateHotelRoom:Joi.ObjectSchema<THotelRoom>=Joi.object({
    hotelId: Joi.number().min(1).messages({'number.min':'Select a hotel'}),
    locationId:Joi.number().min(1).required().messages({'number.min':'Select a hotel Location'}),
    roomTypeId:Joi.number().min(1).required().messages({'number.min':'Select a room type'}),
    roomNumber:Joi.string().required().messages({'string.base':'Add a room number'}),
    roomRate:Joi.number().min(1).required().messages({'number.min':'Enter a valid rate for room'}),
    amenities:Joi.array().min(1).required().messages({'array.min':'Select an amenities'}),
    capacity:Joi.number().min(1).required().messages({'number.min':'enter a valid capacity'}),
    description:Joi.string().messages({ 'string.empty': 'Enter a description'}),
    roomId:Joi.number(),
    modifiedBy:Joi.number()
});
export const validateGuest:Joi.ObjectSchema<TGuests>=Joi.object({
    guestName:Joi.string().messages({ 'string.empty': 'Please provide a valid guest name' }),
    emailAddress:Joi.string().optional().allow(''),
    phoneNumber:Joi.string().max(10).messages({ 'string.empty': 'Please provide a valid phone number','string.max':'Phone number should be 10 characters long' }),
    nationalIdType:Joi.string().optional().allow(''),
    idNumber:Joi.string().optional().allow(''),
    age:Joi.number().optional().allow(''),
    gender:Joi.string().messages({ 'string.empty': 'Please provide a valid gender' }),
    guestId:Joi.number(),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number(),
    modifiedBy:Joi.number(),
    placeOfStay:Joi.string().optional().allow('')
});
export const validateReservation:Joi.ObjectSchema<TReservations>=Joi.object({
    roomId:Joi.number().min(1).messages({ 'number.min': 'Please select a valid room' }),
    checkInDate:Joi.string().messages({ 'string.base': 'Please provide a valid check-in date' }),
    checkOutDate:Joi.string().messages({ 'string.base': 'Please provide a valid check-out date' }),
    numberOfAdults:Joi.number().min(1).required().messages({ 'number.min': 'Number of adults can not be zero' }),
    numberOfChildren:Joi.number(),
    bookingType:Joi.string().messages({ 'string.empty': 'Please provide a valid booking type' }),
    purposeOfVisit:Joi.string().messages({ 'string.empty': 'Please provide a valid purpose' }),
    financedBy:Joi.string().messages({ 'string.empty': 'Please provide a Financier' }),
    guestCompany:Joi.string().when('financedBy',{is:'Company',then:Joi.string().required().messages({'any.required':'If Company, add paying company'}),otherwise:Joi.optional().allow('')}),
    status:Joi.string(),
    reservationId:Joi.number(),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number(),
    modifiedBy:Joi.number(),
    numberOfDays:Joi.number()
});
export const validateHotelEvent:Joi.ObjectSchema<THotelEvents>=Joi.object({
    eventHost:Joi.string().messages({ 'string.empty': 'Please provide a valid host for event' }),
    eventTypeId:Joi.number().min(1).required().messages({ 'number.min': 'Please provide a valid event type' }),
    eventStartDate:Joi.date(),
    eventEndDate:Joi.date(),
    eventCost:Joi.number().min(1).messages({'number.min':'Provide a valid cost amount for event'}),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number(),
    hotelEventId:Joi.number(),
    modifiedBy:Joi.number()
});
export const validateExpenditures:Joi.ObjectSchema<TExpenditure>=Joi.object({
    expenditureId:Joi.number(),
    expenditureDate:Joi.date().messages({ 'date.base': 'Please selected a valid date' }),
    description:Joi.string().messages({ 'string.empty': 'Please provide a valid description' }),
    hotelId:Joi.number().min(1).messages({'number.min':'Select a valid hotel'}),
    locationId:Joi.number().min(1).messages({'number.min':'Select a valid location'}),
    expenditureCategory:Joi.number().min(1).messages({'number.min':'Select a valid category'}),
    paymentMethod:Joi.string().messages({ 'string.empty': 'Please provide a valid payment method' }),
    expenditureAmount:Joi.number().min(1).required().messages({ 'number.min': 'Please provide a valid amount' }),
    modifiedBy:Joi.number(),
});
export const validateItemsState: Joi.ObjectSchema<TItem> = Joi.object({
    itemId: Joi.number().required().messages({ 'number.base': 'Item Id is supposed to have a value before proceeding' }),
    itemName: Joi.string().messages({ 'string.empty': 'Enter a valid value for item name' }),
    itemDescription: Joi.string().optional().allow(''),
    brandId: Joi.number().optional().allow(''),
    itemCost: Joi.number().min(1).messages({ 'number.min': 'Item cost should be greater than zero (0)' }),
    itemPrice: Joi.number().min(1).messages({ 'number.min': 'Item Price should be greater than zero (0)' }),
    quantityInStock: Joi.number().messages({ 'number.min': 'Item quantity should be 0 or more' }),
    expirationDate: Joi.date(),
    hotelId:Joi.number().min(1),
    hotelLocationId:Joi.number().min(1),
});
export const validatePurchase: Joi.ObjectSchema<TPurchase> = Joi.object({
    purchaseId: Joi.number(),
    selectedSupplier: Joi.number().messages({ 'string.empty': 'Select a supplier for this purchase' }),
    selectedPurchaseListItems: Joi.array().min(1).messages({ 'array.min': 'Select at least one item to continue purchase activity' }),
    purchaseDate: Joi.date(),
    amountPaid: Joi.number(),
    modifiedBy: Joi.number(),
    purchaseDiscount: Joi.object(),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number()
});
export const validateAdjustment: Joi.ObjectSchema<TAdjustment> = Joi.object({
    adjustedItems: Joi.array().min(1).messages({ 'array.min': 'Ensure that at least one item is selected for adjustment' }),
    adjustmentDate: Joi.date(),
    adjustmentType: Joi.string().messages({ 'string.empty': 'Select adjustment type' }),
    adjustmentComment: Joi.string().messages({ 'string.empty': 'Add adjustment comment' }),
    modifiedBy: Joi.number().messages({ 'number.base': 'Adjustment comment cannot be empty. Write something to remind of the adjustment reason.' }),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number(),
});
export const validateSales: Joi.ObjectSchema<TSales> = Joi.object({
    salesId: Joi.number(),
    salesDate: Joi.date(),
    guestId: Joi.number().messages({ 'number.base': 'Select a buyer to proceed to save' }),
    salesItems: Joi.array().min(1).messages({ 'array.min': 'Select at least one item to sell' }),
    amountPaid: Joi.number().messages({'number.base':'Ensure that a correct value is added for amount paid!'}),
    modifiedBy: Joi.number(),
    salesDiscount: Joi.object(),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number()
});
export const validatePayment=Joi.object({
    activityId:Joi.number(),
    paymentDate:Joi.date(),
    amount:Joi.number(),
    paymentMode:Joi.string().required().messages({ 'string.empty': 'Select a valid payment mode' }),
    hotelId:Joi.number(),
    hotelLocationId:Joi.number(),
    modifiedBy:Joi.number(),
    paymentId:Joi.number(),
    paymentType:Joi.string().messages({'string.empty':'Use a payment type to continue'}),
    paymentChange:Joi.number()
});
export const validateReportFetch=Joi.object({
    startDate:Joi.date(),
    endDate:Joi.date().required().min(Joi.ref('startDate')).messages({'date.min':'Ensure that end date is greater than start date!'}),
    hotelLocationId:Joi.number().min(1).messages({'number.min':'Select a location for report!'}),
});
