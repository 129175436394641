import { THotel, TTableProps } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const HotelsList = ({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<THotel>)=>{
    return (
        <>
            <SimpleTable
                tableKey={'hotelId'}
                columnsDef={[
                    {field:'hotelName',header:'Hotel'},
                    {field:'emailAddress',header:'Email'},
                    {field:'hotelPhone',header:'Phone'},
                    {
                        body: (rowData: THotel) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.hotelId?.toString()),
                        header: 'Edit'
                    },
                ]}
                tableData={tableData}
                tableTitle={'Locations'}
                searchValues={['hotelName']}
            />
        </>
    );
}
export default HotelsList;
