import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useRegistrationContext } from '../contextProviders/RegistrationContextProvider';

const Locations = () => {
    const {state,setStateFunction:setStateValues}=useRegistrationContext();

    return (
        <>
            <div className="grid p-fluid">
                <div className="lg:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="locationName">Location Name</label>
                                    <InputText id="locationName" type="text" value={state?.locationName} onChange={(e) => setStateValues!({ locationName: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="locationAddress">Location Address</label>
                                    <InputText id="locationAddress" type="text" value={state?.locationAddress} onChange={(e) => setStateValues!({ locationAddress: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="emailAddress">Email Address</label>
                                    <InputText id="emailAddress" type="text" value={state?.locationEmail} onChange={(e) => setStateValues!({ locationEmail: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputText id="phoneNumber" type="text" value={state?.locationPhoneNumber} onChange={(e) => setStateValues!({ locationPhoneNumber: e.target.value })} autoComplete="off" />
                                </div>
                            </div>
                            <div className="lg:col-6">
                                <div className="field">
                                    <div className="field">
                                        <label htmlFor="city">City</label>
                                        <InputText id="city" type="text" value={state?.city} onChange={(e) => setStateValues!({ city: e.target.value })} autoComplete="off" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="postCode">Post Code</label>
                                        <InputText id="postCode" type="text" value={state?.postCode} onChange={(e) => setStateValues!({ postCode: e.target.value })} autoComplete="off" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="countries">Countries</label>
                                        <Dropdown
                                            value={state?.selectedCountry}
                                            onChange={(e) =>
                                                setStateValues!({
                                                    selectedCountry: e.value,
                                                    countryCode: e.value.code
                                                })
                                            }
                                            options={state?.countries}
                                            optionLabel="name"
                                            placeholder="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Locations;
