import { TRoomType } from '../types';
import axiosFetch from '../axiosConfig';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import { Queue } from './Queue';
const queue = new Queue();
class RoomTypes {

    async addNewRoomType(roomType:TRoomType,crudType:string){
        const data=await axiosFetch<TRoomType>('POST',`${getBaseURL()}/room_type/new_room_type`,{requestBody:{...roomType,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData}
    }

    async addNewRoomTypeLocal(roomType:TRoomType,crudType:string){
        return  crudType==='save'?this.addRoomTypeLocal(roomType):this.updateRoomTypeLocal(roomType);
    }

    async  addRoomTypeLocal (roomType:TRoomType){
        delete roomType.roomTypeId;
        try{
            return db.transaction('rw',[db.roomTypes,db.queuedTables],async ()=>{
                const roomTypeIdNew=await db.roomTypes.add(roomType);
                const newType=await db.roomTypes.get(roomTypeIdNew);
                await queue.enqueue<TRoomType>(newType!,'roomTypes');
                return {status:200,operatedData:newType};
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }

    async updateRoomTypeLocal(roomType:TRoomType){
        try{
            console.log(roomType)
            return db.transaction('rw',[db.roomTypes,db.queuedTables],async ()=>{
                const updateRoomTypeId=await db.roomTypes.put(roomType);
                await queue.updateQueue<TRoomType>(roomType!,'roomTypes','roomTypeId',updateRoomTypeId!);
                return {status:1,operatedData:roomType!};
            });
        }catch(error){
            throw error;
        }
    }

    async getOfflineRoomTypes(hotelLocationId:number){
        try {
            return db.roomTypes.where({locationId:hotelLocationId }).toArray();
        } catch (error) {
            throw error;
        }
    }
}
export default RoomTypes;
