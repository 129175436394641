import { THotelRoom, TReservations } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';

const BookedRoomsList=({ tableData,tableTitle }: {tableData:TReservations[],tableTitle:string})=>{

    return <>
        <h6>{tableTitle}</h6>
        <SimpleTable
            tableKey={'roomId'}
            columnsDef={[
                { field: 'roomNumber', header: 'Room' },
                { field: 'roomTypeDesc', header: 'Room Type' },
                { field: 'checkInDate', header: 'From'},
                { field: 'checkOutDate', header: 'To' },
                { field: 'guestName', header: 'Occupant' },
                { field: 'bookingType', header: 'Type' },
                {
                    body: (rowData: THotelRoom) => <div className="underline text-blue-500 cursor-pointer">See More...</div>,
                    header: 'Amenities'
                }
            ]}
            tableData={tableData}
            tableTitle={'Rooms'}
            searchValues={['roomTypeDesc']}
            tableMinWidth={40}
        />
    </>
}
export default BookedRoomsList;
