import { useGuestReservationContext } from '../contextProviders/GuestReservationContextProvider';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { onInputControlFocus } from '../../utilities/utilFunctions';
import { InputNumber } from 'primereact/inputnumber';

const Guests = () => {
    const { state, setStateFunction: setStateValues } = useGuestReservationContext();
    return (
        <>
            <div className="grid p-fluid">
                <div className="lg:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="guestName">Guest Name</label>
                                    <InputText id="guestName" type="text" value={state?.guestName} onChange={(e) => setStateValues!({ guestName: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="eamilAddress">Email</label>
                                    <InputText id="emailAddress" type="email" value={state?.emailAddress} onChange={(e) => setStateValues!({ emailAddress: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputText id="phoneNumber" type="tel" value={state?.phoneNumber} onChange={(e) => setStateValues!({ phoneNumber: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="nationalIDTypes">National ID Type</label>
                                    <Dropdown
                                        value={state?.selectedNationalID}
                                        onChange={(e) =>
                                            setStateValues!({
                                                selectedNationalID: e.value,
                                                nationalIdType: e.value.name
                                            })
                                        }
                                        options={state?.nationalIDTypes}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>
                            </div>
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="nationalIDNumber">ID Number</label>
                                    <InputText id="guestName" type="text" value={state?.idNumber} onChange={(e) => setStateValues!({ idNumber: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="dateOfBirth">Age</label>
                                    <InputNumber value={state?.age} onChange={(e) => setStateValues!({ age: e.value! })} onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                                </div>
                                <div className="field">
                                    <label htmlFor="genders">Gender</label>
                                    <Dropdown
                                        value={state?.selectedGender}
                                        onChange={(e) =>
                                            setStateValues!({
                                                selectedGender: e.value,
                                                gender: e.value.code
                                            })
                                        }
                                        options={state?.genders}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="guestLocation">Location</label>
                                    <InputText id="guestLocation" type="text" value={state?.placeOfStay} onChange={(e) => setStateValues!({ placeOfStay: e.target.value })} autoComplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Guests;
