import { useGuestReservationContext } from '../contextProviders/GuestReservationContextProvider';
import React from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { DatePicker, FilterSelect } from '../../utilities/components/UtilityComponents';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { changeDateFormat, onInputControlFocus } from '../../utilities/utilFunctions';
import { format } from 'date-fns/format';
import { differenceInCalendarDays } from 'date-fns';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete';

const Reservations = () => {
    const { state, setStateFunction: setStateValues } = useGuestReservationContext();

    const onRoomChange = (e: DropdownChangeEvent) => {
        const selectedRoom = state?.roomsList?.find(room => room.roomId === e.value.code);
        setStateValues!({ roomId: e.value.code, selectedRoom: e.value, roomRate: selectedRoom?.roomRate!, roomTypeDesc: selectedRoom?.roomTypeDesc });

        //TODO: DISPLAY ROOM DETAILS TO USER TO ANNOUNCE TO CUSTOMER;
    };
    const onAmountChange=(e:InputNumberChangeEvent)=>{
        const change=parseFloat((e.value!-(state?.numberOfDays! * state?.roomRate!)).toFixed(2));
        setStateValues!({amount:e.value!,paymentChange:change<0?0:change});
    }
    const search=(event:AutoCompleteCompleteEvent)=>{
        setStateValues!({guestCompanyList:state?.guestCompanyList?.map(guestCompany=>guestCompany)});
    }
    return (
        <>
            <div className="grid p-fluid">
                <div className="lg:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-4">
                                {state?.toolBarVisible && <div className="field">
                                    <label htmlFor="guestId">Guest</label>
                                    <Dropdown
                                        value={state?.selectedGuest}
                                        onChange={(e) =>
                                            setStateValues!({
                                                selectedGuest: e.value,
                                                guestId: e.value.code
                                            })
                                        }
                                        options={state?.guests}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>}
                                <div className="field">
                                    <label htmlFor="guestId">Rooms</label>
                                    <Dropdown
                                        value={state?.selectedRoom}
                                        onChange={onRoomChange}
                                        options={state?.rooms}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="checkInDate">Checked In Date</label>
                                    <DatePicker dateValue={state?.checkInDateDisplay}
                                                onDateChange={(e) => {setStateValues!({ checkInDateDisplay: e.value, checkInDate: format(e.value,'yyyy-MM-dd HH:mm'), numberOfDays: differenceInCalendarDays(state?.checkOutDate!, changeDateFormat(e.value)) })}} labelText="Check In Date" controlId="checkInDate"
                                                selectionType="single" displayTime={true}/>
                                </div>
                                <div className="field">
                                    <label htmlFor="checkOutDate">Checked Out Date</label>
                                    <DatePicker minimumDateValue={state?.checkInDateDisplay!} dateValue={state?.checkOutDateDisplay}
                                                onDateChange={(e) => setStateValues!({ checkOutDateDisplay: e.value, checkOutDate: format(e.value,'yyyy-MM-dd HH:mm'), numberOfDays: differenceInCalendarDays(changeDateFormat(e.value), state?.checkInDate!) })} labelText="Check Out Date" controlId="checkOutDate"
                                                selectionType="single" displayTime={true}/>
                                </div>
                                <div className="field">
                                    <label htmlFor="numberOfAdults">Number Of Adults</label>
                                    <InputNumber id="numberOfAdults" type="text" value={state?.numberOfAdults} onChange={(e) => setStateValues!({ numberOfAdults: e.value! })} onBlur={onInputControlFocus} onFocus={onInputControlFocus} />
                                </div>
                            </div>
                            <div className="lg:col-4">
                                <div className="field">
                                    <label htmlFor="numberOfChildren">Number Of Children</label>
                                    <InputNumber id="numberOfChildren" type="text" value={state?.numberOfChildren} onChange={(e) => setStateValues!({ numberOfChildren: e.value! })} onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                                </div>
                                <div className="field">
                                    <label htmlFor="guestId">Booking Type</label>
                                    <Dropdown
                                        value={state?.selectedBookingType}
                                        onChange={(e) =>
                                            setStateValues!({
                                                selectedBookingType: e.value,
                                                bookingType: e.value.name
                                            })
                                        }
                                        options={state?.bookingTypes}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="field">
                                    <FilterSelect selectableOptions={state?.bookStatusList!} onSelectChange={(e) => setStateValues!({ selectedBookingStatus: e.value, status: e.value.name })}
                                                  elementId="bookingStatus" defaultValue="Booking Status:" selectedOption={state?.selectedBookingStatus} />
                                </div>
                                <div className="field">
                                    <div className="grid">
                                        <div className="col-6">
                                            <FilterSelect selectableOptions={state?.financedByList!} onSelectChange={(e) => setStateValues!({ financedBy: e.value.name, selectedFinancier: e.value })}
                                                          elementId="finanacier" defaultValue="Financed By:" selectedOption={state?.selectedFinancier} />
                                        </div>
                                        <div className="col-6">
                                            <div className="field">
                                                <label>Guest Company</label>
                                                <AutoComplete dropdown completeMethod={search} value={state?.guestCompany} suggestions={state?.guestCompanyList} onChange={(e) => setStateValues!({ guestCompany: e.value })} disabled={state?.financedBy!=='Company'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                <label htmlFor="purposeOfVisit">Purpose of Visit</label>
                                    <InputTextarea id="purposeOfVisit" value={state?.purposeOfVisit} onChange={(e) => setStateValues!({ purposeOfVisit: e.target.value! })} rows={2} />
                                </div>
                            </div>
                            <div className="lg:col-4">
                                <div className="card font-bold">
                                    <div className="grid">
                                        <div className="col-6 p-2 bg-primary">
                                            <div className="flex">
                                                <div className="flex-1">
                                                No. Of Days:
                                                </div>
                                                <div className="flex-1 md:pl-2">
                                                    {state?.numberOfDays}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-2 bg-primary">
                                            <div className="flex">
                                                <div className="flex-1">
                                                    Room Rate:
                                                </div>
                                                <div className="flex-1">
                                                    {state?.roomRate!}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid mt-2">
                                        <div className="col-6 p-2 bg-primary">
                                            <div className="flex">
                                                <div className="flex-1">
                                                    Payable:
                                                </div>
                                                <div className="flex-1">
                                                    {state?.numberOfDays! * state?.roomRate!}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-2 bg-primary">
                                            <div className="flex">
                                                <div className="flex-1">
                                                    Change:
                                                </div>
                                                <div className="flex-1">
                                                    {state?.paymentChange}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    state?.crudeType === 'save' ? <div className="grid">
                                        <div className="col-8">
                                            <div className="field">
                                                <FilterSelect selectableOptions={state?.paymentMethods!} selectedOption={state?.selectedPaymentMethod} onSelectChange={(e) => setStateValues!({ selectedPaymentMethod: e.value, paymentMode: e.value.code })} elementId="amountPaying"
                                                              defaultValue="Select Method" />
                                            </div>
                                            <div className="field">
                                                <label>Payment Amount</label>
                                                <InputNumber value={state?.amount} onFocus={onInputControlFocus} onBlur={onInputControlFocus} onChange={onAmountChange} maxFractionDigits={2}/>
                                            </div>
                                        </div>
                                    </div>:<div></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Reservations;
