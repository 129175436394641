import { getBaseURL } from '../utilFunctions';
import axiosFetch from '../axiosConfig';
import { TAdjustment, TBrand, TCrudType, TItem, TPriceEditItem, TPurchase, TSales } from '../types';
import { db } from '../../offline/db';
import { Queue } from './Queue';
const queue = new Queue();
class Items {
    async createInstance(stateValues: TItem,crudType:TCrudType){
        const data= await axiosFetch<TItem>('post', `${getBaseURL()}/items/new_item`, { requestBody: {...stateValues,crudType} });
        return {status: data.status, operatedData: data.data?.operatedData};
    }

    async deleteInstance(deleteId: string){
        const data= await axiosFetch('delete', `${getBaseURL()}/items/delete_item`, { deleteItemId: deleteId });
        return {status: data.status, operatedData: data.data?.operatedData};
    }

    async newPurchase(purchase: TPurchase){
        const data= await axiosFetch<TPurchase>('post', `${getBaseURL()}/items/new_purchase`, { requestBody: purchase });
        return {status: data.status, operatedData: data.data?.operatedData};
    }
    async updatePurchase(purchase: TPurchase){
        const data= await axiosFetch<TPurchase>('patch', `${getBaseURL()}/items/update_purchase`, { requestBody: purchase });
        return {status: data.status, operatedData: data.data?.operatedData};
    }
    async newAdjustment(adjustmentData: TAdjustment) {
        const data= await axiosFetch<TAdjustment>('post', `${getBaseURL()}/items/new_adjustment`, { requestBody:adjustmentData });
        return {status: data.status, operatedData: data.data?.operatedData};
    }
    async newSales(salesData: TSales){
        const data= await axiosFetch<TSales>('post', `${getBaseURL()}/items/new_sales`, { requestBody:salesData });
        return {status: data.status, operatedData: data.data?.operatedData};
    }
    async updateSales(salesData: TSales){
        const data= await axiosFetch<TSales>('patch', `${getBaseURL()}/items/update_sales`, { requestBody:salesData });
        return {status: data.status, operatedData: data.data?.operatedData};
    }
    async updatePrices(priceEditItems: TPriceEditItem[],updatingItems:string,modifiedBy:number){
        return await axiosFetch<TItem[]>('patch', `${getBaseURL()}/items/update_item_price`, { priceEditItems,updatingItems,modifiedBy });
    }
    async newItemBrand(brandData: TBrand,crudType:string){
        const data= await axiosFetch<TBrand>('post', `${getBaseURL()}/items/new_brand`, { requestBody:{...brandData,crudType} });
        return {status: data.status, operatedData: data.data?.operatedData};
    }

    async addItemOffLine(itemData: TItem,crudType:TCrudType){
        return crudType==='save'?await this.addLocalItem(itemData):this.updateLocalItem(itemData);
    }

    async addLocalItem(item: TItem){
        try{
            return db.transaction('rw',[db.items,db.queuedTables],async()=>{
                const newItemId=await db.items.add(item);
                const newItem=await db.items.get(newItemId);
                await queue.enqueue<TItem>(newItem!,'items');
                return {status:200,operatedData:newItem}
            });
        }catch(error){
            throw error;
        }
    }

    async updateLocalItem(itemData: TItem){
        try{
            return db.transaction('rw',[db.items,db.queuedTables],async()=>{
                const updatedItemId=await db.items.put(itemData);
                await queue.updateQueue<TItem>(itemData,'items','itemId',updatedItemId!);
                return {status:200,operatedData:itemData}
            });
        }catch(error){
            throw error;
        }
    }

    async addOfflineBrand(brandData: TBrand,crudType:TCrudType){
        return crudType==='save'?await this.addLocalBrand(brandData):this.updateLocalBrand(brandData);
    }
    async addLocalBrand(brand: TBrand){
        try{
            return db.transaction('rw',[db.brands,db.queuedTables],async()=>{
                const newBrandId=await db.brands.add(brand);
                const newBrand=await db.brands.get(newBrandId);
                await queue.enqueue<TBrand>(newBrand!,'items');
                return {status:200,operatedData:newBrand}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalBrand(brand: TBrand){
        try{
            return db.transaction('rw',[db.brands,db.queuedTables],async()=>{
                const updatedItemId=await db.brands.put(brand);
                await queue.updateQueue<TBrand>(brand,'brands','brandId',updatedItemId! as number);
                return {status:200,operatedData:brand}
            });
        }catch(error){
            throw error;
        }
    }
    async addLocalPurchase(item: TPurchase){
        const spreadPurchase={...item};
        delete spreadPurchase.purchaseId;
        try{
            return db.transaction('rw',[db.purchases,db.queuedTables],async()=>{
                const newItemId=await db.purchases.add(item);
                const newItem=await db.purchases.get(newItemId);
                await queue.enqueue<TPurchase>(newItem!,'purchases');
                return {status:200,operatedData:newItem}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalPurchase(item: TPurchase){
        try{
            return db.transaction('rw',[db.purchases,db.queuedTables],async()=>{
                const updatedItemId=await db.purchases.put(item);
                await queue.updateQueue<TPurchase>(item,'purchases','purchaseId',updatedItemId! as number);
                return {status:200,operatedData:item}
            });
        }catch(error){
            throw error;
        }
    }
    async addLocalAdjustment(item: TAdjustment){
        const spreadAdjustment={...item};
        delete spreadAdjustment.adjustmentId;
        try{
            return db.transaction('rw',[db.adjustments,db.queuedTables],async()=>{
                const newItemId=await db.adjustments.add(item);
                const newItem=await db.adjustments.get(newItemId);
                await queue.enqueue<TAdjustment>(newItem!,'adjustment');
                return {status:200,operatedData:newItem}
            });
        }catch(error){
            throw error;
        }
    }
    async addLocalSales(item: TSales){
        const spreadSales={...item};
        delete spreadSales.salesId;
        try{
            return db.transaction('rw',[db.sales,db.queuedTables],async()=>{
                const newItemId=await db.sales.add(item);
                const newItem=await db.sales.get(newItemId);
                await queue.enqueue<TSales>(newItem!,'sales');
                return {status:200,operatedData:newItem}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalSales(item: TSales){
        try{
            return db.transaction('rw',[db.sales,db.queuedTables],async()=>{
                const updatedItemId=await db.sales.put(item);
                await queue.updateQueue<TSales>(item,'sales','salesId',updatedItemId! as number);
                return {status:200,operatedData:item}
            });
        }catch(error){
            throw error;
        }
    }

    async getOfflineItems(hotelLocation:number){
        try{
            return db.transaction('rw',[db.items],async()=>{
                return db.items.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflineSales(hotelLocation:number){
        try{
            return db.transaction('rw',[db.sales],async()=>{
                return db.sales.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflinePurchases(hotelLocation:number){
        try{
            return db.transaction('rw',[db.purchases],async()=>{
                return db.purchases.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflineAdjustments(hotelLocation:number){
        try{
            return db.transaction('rw',[db.adjustments],async()=>{
                return db.adjustments.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflineBrands(hotelLocation:number){
        try{
            return db.transaction('rw',[db.brands],async()=>{
                return db.brands.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflineSuppliers(hotelLocation:number){
        try{
            return db.transaction('rw',[db.suppliers],async()=>{
                return db.suppliers.where({hotelLocationId:hotelLocation}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
}
export default Items;
