import React, { useEffect, useReducer, useRef, useState } from 'react';
import { TabMenu, TabMenuTabChangeEvent } from 'primereact/tabmenu';
import { THotel, THotelLocation, TRegistrationState, TUser } from '../../utilities/types';
import {
    displayCountry,
    displayMessage,
    extractStateValues, getGenders, getUserRoles,
    pageDataValidation,
    REDUCER_ACTION_TYPE,
    StateReducerHandler
} from '../../utilities/utilFunctions';
import { Button } from 'primereact/button';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import Locations from '../locations/Locations';
import Hotels from '../hotels/Hotels';
import Staffs from '../staffs/Staffs';
import { RegistrationContext } from '../contextProviders/RegistrationContextProvider';
import { validateNewHotelDetails, validateNewHotelLocation, validatePersonalDetails } from '../../utilities/joiFunctions/joiFunctions';
import RegistrationObject from '../../utilities/classObjects/Registration';
import { useNavigate } from 'react-router-dom';


const INITIAL_STATE: TRegistrationState = {
    city: '',
    countryCode: '',
    locationAddress: '',
    locationName: '',
    postCode: '',
    emailAddress: '',
    hotelId: 0,
    hotelName: '',
    staffName: '',
    address:'',
    password: '',
    phoneNumber: '',
    username: '',
    repeatPassword: '',
    hotelPhone: '',
    isLoading: false,
    selectedCountry:{name:'',code:'0'},
    toolBarVisible:false,
    showInnerSaveButton:false,
    genders:getGenders,
    selectedGender:{name:'',code:0},
    userRoles:getUserRoles,
    selectedUserRole:{name:'',code:0},
    countries:[],
    locationPhoneNumber:'',
    modifiedBy:0
};

const registration=new RegistrationObject();

const Registration = () => {
    const navigateAway=useNavigate();
    const [state, dispatch] = useReducer(StateReducerHandler<TRegistrationState>, INITIAL_STATE);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const toastRef = useRef(null);
    const wizardItems =  [
        {
            label: 'Your Details',
            icon: 'pi pi-fw pi-user',
            command: () => {}
        },
        {
            label: 'Hotel',
            icon: 'pi pi-fw pi-building',
            command: () => {}
        },
        {
            label: 'Location',
            command: () => {}
        }
    ];

    useEffect(()=>{
        setStateValues({
            countries:displayCountry(),
        });
    },[]);
    const setStateValues = (stateValues: Partial<TRegistrationState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getOwnerDetails = () => {
        const props: Array<keyof TUser> = ['staffName', 'phoneNumber', 'address', 'userRole', 'gender', 'username', 'password', 'repeatPassword','modifiedBy'];
        return extractStateValues<TRegistrationState>(props, state);
    };
    const getHotelDetails=()=>{
        const props: Array<keyof THotel> = ['hotelName','hotelPhone','emailAddress'];
        return extractStateValues<TRegistrationState>(props, state);
    }
    const getHotelLocationDetails=()=>{
        const props: Array<keyof THotelLocation> = ['locationName','locationAddress','locationEmail','locationPhoneNumber','city','postCode','countryCode','modifiedBy'];
        return extractStateValues<TRegistrationState>(props, state);
    }
    const onMenuTabChange = (e: TabMenuTabChangeEvent) => {
        switch(e.index){
            case 1:
                if(!pageDataValidation(validatePersonalDetails,getOwnerDetails(),toastRef)) return;
                setActiveIndex(1);
                break;
            case 2:
                if(!pageDataValidation(validateNewHotelDetails,getHotelDetails(),toastRef)) return;
                setActiveIndex(2);
                break;
                default:
                    console.log('defaulting');
        }
        setActiveIndex(e.index);
    };
    const oncompleteRegistration = async () => {
        try {
            if(!pageDataValidation(validatePersonalDetails.concat(validateNewHotelDetails),{...getOwnerDetails(),...getHotelDetails()},toastRef)) return;
            if (!pageDataValidation(validateNewHotelLocation, getHotelLocationDetails(), toastRef)) return;
            const personalData=getOwnerDetails(),hotelData=getHotelDetails(),hotelLocation=getHotelLocationDetails();
            setStateValues({isLoading:true});
            const savedResults = await registration.registerNewHotel(personalData,hotelData,hotelLocation,'save');
            if (savedResults.status === 200) {
                displayMessage({
                    header: 'Registration Success',
                    message: 'Your institution registration was successful',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                setTimeout(()=>{
                    // navigateAway('/login');
                },3000);
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const validateOwnerDetails=()=>{
        return pageDataValidation(validatePersonalDetails,getOwnerDetails(),toastRef);
    }
    const validateHotelDetails=()=>{
        return pageDataValidation(validateNewHotelDetails,getHotelDetails(), toastRef);
    }
    const goToNextTab=(e:React.MouseEvent<HTMLButtonElement>)=>{
        if(activeIndex===0 && !validateOwnerDetails()) return;
        if(activeIndex===1 && !validateHotelDetails()) return;
        if(activeIndex==2) return;
        setActiveIndex(activeIndex+1);
    }
    const goToPreviousTab=(e:React.MouseEvent<HTMLButtonElement>)=>{
        if(activeIndex==0) return;
        setActiveIndex(activeIndex-1);
    }
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <RegistrationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={onMenuTabChange} />
                {activeIndex === 0 && (
                    <Staffs key={'RLStaffs'}/>
                )}
                {activeIndex === 1 && (
                    <Hotels key={'RLHotels'}/>
                )}
                {activeIndex === 2 && (
                    <Locations key={'RLLocations'}/>
                )}
            </RegistrationContext.Provider>
            <div className="field">
                <Button onClick={goToPreviousTab}>Prev</Button>
                {activeIndex!==2 && <Button onClick={goToNextTab} className="ml-2">Next</Button>}
                {activeIndex===2 && <Button onClick={oncompleteRegistration} className="ml-2">Complete Registration</Button>}
            </div>
        </>
    );
};
export default Registration;
