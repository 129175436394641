import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import IconsDemo from './utilities/IconsDemo';
import TimelineDemo from './pages/TimelineDemo';
import Invoice from './pages/Invoice';
import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import Registration from './components/registration/Registration';
import LocationsMain from './components/locations/LocationsMain';
import StaffMain from './components/staffs/StaffMain';
import HotelsMain from './components/hotels/HotelsMain';
import RoomType from './components/rooms/RoomType';
import HotelRoom from './components/rooms/HotelRoom';
import GuestsMain from './components/guests/GuestsMain';
import ReservationsMain from './components/reservations/ReservationsMain';
import GuestReservations from './components/guestReservations/GuestReservations';
import HotelEvents from './components/hotelEvents/HotelEvents';
import AppSettings from './components/settings/AppSettings';
import { GlobalContext } from './components/contextProviders/GlobalContext';
import useUserData from './hooks/customHooks';
import Expenditures from './components/expenditure/Expenditures';
import CalendarBooking from './components/calendar/CalendarBooking';
import ItemStatePage from './components/restaurant/items/Items';
import Sales from './components/restaurant/sales/Sales';
import Purchases from './components/restaurant/purchase/Purchases';
import Adjustments from './components/restaurant/adjustment/Adjustments';
import Reporting from './components/reporting/Reporting';
import { TPageLink } from './utilities/types';

const App = (props: any) => {
    const navigate = useNavigate();
    const appModeState = localStorage.getItem('appState');
    const [userData]=useUserData();
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const [appMode, setAppMode] = useState<string>(appModeState !== null ? appModeState : 'onLine');
    const [currentUser,setCurrentUser]=useUserData();
    const menuPages:TPageLink[]=typeof userData?.userPages==='string'?JSON.parse(userData?.userPages):userData?.userPages;
    PrimeReact.ripple = true;

    const setSubMenus=(parentMenu:string)=>{
        if(menuPages)
        return menuPages.filter(menu=>menu.parent===parentMenu).map((menu)=>{return { label: menu.pageDescription, icon: menu.icon, to: `/${menu.pageLink}` }});
    }

    const menu = [
        {
            label: 'Favorites',
            icon: 'pi pi-home',
            items: setSubMenus('Favorites')
        },
        // {
        //     label: 'Pages',
        //     icon: 'pi pi-briefcase',
        //     items: [
        //         { label: 'Crud', icon: 'pi pi-pencil', to: '/crud' },
        //         { label: 'Calendar', icon: 'pi pi-calendar-plus', to: '/calendar' },
        //         { label: 'Timeline', icon: 'pi pi-calendar', to: '/timeline' },
        //         {
        //             label: 'Landing',
        //             icon: 'pi pi-globe',
        //             url: 'assets/pages/landing.html',
        //             target: '_blank'
        //         },
        //         { label: 'Login', icon: 'pi pi-sign-in', to: '/login' },
        //         { label: 'Invoice', icon: 'pi pi-dollar', to: '/invoice' },
        //         { label: 'Help', icon: 'pi pi-question-circle', to: '/help' },
        //         { label: 'Error', icon: 'pi pi-times-circle', to: '/error' },
        //         {
        //             label: 'Not Found',
        //             icon: 'pi pi-exclamation-circle',
        //             to: '/notfound'
        //         },
        //         { label: 'Access Denied', icon: 'pi pi-lock', to: '/access' },
        //         { label: 'Empty Page', icon: 'pi pi-circle', to: '/empty' }
        //     ]
        // },
        {
            label: 'Hotel',
            icon: 'pi pi-building',
            items: setSubMenus('Hotel')
        },
        {
            label:'Rooms',
            icon:'pi pi-map',
            items:setSubMenus('Rooms')
        },
        {
            label: 'Bookings',
            icon: 'pi pi-book',
            items: setSubMenus('Bookings')
        },
        {
            label: 'Restaurant',
            icon: 'pi pi-gift',
            items: setSubMenus('Restaurant')
        }
    ];

    const routes = [
        { parent: 'Dashboard', label: 'Dashboard' },
        { parent: 'Dashboard', label: 'Registration' },
        { parent: 'Dashboard', label: 'Locations' },
        { parent: 'Dashboard', label: 'Hotels' },
        { parent: 'Dashboard', label: 'Staffs' },
        { parent: 'Dashboard', label: 'Rooms' },
        { parent: 'Dashboard', label: 'Guests' },
        { parent: 'Dashboard', label: 'Booking' },
        { parent: 'Dashboard', label: 'Reservations' },
        { parent: 'Dashboard', label: 'Events' },
        { parent: 'Dashboard', label: 'Settings' },
        { parent: 'Dashboard', label: 'Expenditures' },
        { parent: 'Dashboard', label: 'Spread' },
        { parent: 'Dashboard', label: 'Reports' },
        { parent: 'Restaurant', label: 'Items' },
        { parent: 'Restaurant', label: 'Sales' },
        { parent: 'Restaurant', label: 'Purchase' },
        { parent: 'Restaurant', label: 'Adjustment' },
        { parent: 'Pages', label: 'Crud' },
        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Start', label: 'Documentation' }
    ];

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);
    useEffect(() => {
        if(userData==null) navigate('/login');
        const colorScheme=localStorage.getItem('theme');
        props.onColorSchemeChange(colorScheme!==null?colorScheme:'light');
    }, []);
    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <GlobalContext.Provider value={{
            changeAppMode: setAppMode,
            changeAppColorScheme: props.changeAppColorScheme,
            appColorScheme:props.colorScheme,
            currentAppMode:appMode,
            currentUser
        }}>
            <div className={layoutClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <div className="layout-main">
                    <AppTopbar
                        items={menu}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        activeInlineProfile={activeInlineProfile}
                        onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppMenu
                        model={menu}
                        onRootMenuItemClick={onRootMenuItemClick}
                        onMenuItemClick={onMenuItemClick}
                        onToggleMenu={onToggleMenu}
                        onMenuClick={onMenuClick}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        sidebarActive={sidebarActive}
                        sidebarStatic={sidebarStatic}
                        pinActive={pinActive}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onSidebarMouseOver={onSidebarMouseOver}
                        activeInlineProfile={activeInlineProfile}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} onRightMenuButtonClick={onRightMenuButtonClick} onInputClick={onInputClick} searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                    <div className="layout-main-content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/icons" element={<IconsDemo />} />
                            <Route path="/help" element={<Help />} />
                            <Route path="/invoice" element={<Invoice colorMode={props.colorScheme} location={location} />} />
                            <Route path="/empty" element={<EmptyPage />} />
                            <Route path="/timeline" element={<TimelineDemo />} />
                            <Route path="/registration" element={<Registration />} />
                            <Route path="/locations" element={<LocationsMain />} />
                            <Route path="/staffs" element={<StaffMain />} />
                            <Route path="/hotels" element={<HotelsMain />} />
                            <Route path="/room-types" element={<RoomType />} />
                            <Route path="/rooms" element={<HotelRoom />} />
                            <Route path="/guests" element={<GuestsMain />} />
                            <Route path="/reservations" element={<ReservationsMain />} />
                            <Route path="/booking" element={<GuestReservations />} />
                            <Route path="/events" element={<HotelEvents />} />
                            <Route path="/settings" element={<AppSettings />} />
                            <Route path="/expenditures" element={<Expenditures />} />
                            <Route path="/calendar" element={<CalendarBooking />} />
                            <Route path="/items" element={<ItemStatePage />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/purchase" element={<Purchases />} />
                            <Route path="/adjustment" element={<Adjustments />} />
                            <Route path="/reports" element={<Reporting />} />
                        </Routes>
                    </div>

                    <AppFooter colorScheme={props.colorScheme} />
                </div>

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />

                <AppConfig
                    configActive={configActive}
                    onConfigButtonClick={onConfigButtonClick}
                    onConfigClick={onConfigClick}
                    menuMode={menuMode}
                    changeMenuMode={onMenuModeChange}
                    colorScheme={props.colorScheme}
                    changeColorScheme={props.onColorSchemeChange}
                    theme={props.theme}
                    changeTheme={props.onMenuThemeChange}
                    componentTheme={props.componentTheme}
                    changeComponentTheme={props.onComponentThemeChange}
                    ripple={ripple}
                    onRippleChange={onRippleChange}
                />
            </div>
        </GlobalContext.Provider>
    );
};

export default App;
