
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import React, { useEffect, useRef, useState } from 'react';
import { TReporting } from '../../utilities/types';
import { ReportsContext } from '../contextProviders/ReportContext';
import { AvailableRoomsDialog, BookedReservedRoomsDialog, DailySummaryDialog } from './ReportDialogs';
import { changeDateFormat, defaultSettings, isAppOnline, remakeDropdownSelects } from '../../utilities/utilFunctions';
import { hotelsListQuery } from '../../utilities/reactQueryUtils';
import Settings from '../../utilities/classObjects/Settings';
import { useQueryClient } from '@tanstack/react-query';
import useUserData from '../../hooks/customHooks';
import Hotel from '../../utilities/classObjects/Hotel';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';

const INITIAL_STATE: TReporting  = {
    hotelId: 0,
    hotelLocationId: 0,
    hotelLocations: [],
    hotelLocationsList: [],
    hotels: [],
    hotelsList: [],
    reportingDate: [new Date(), new Date()],
    selectedHotel: {name:'',code:0},
    selectedHotelLocation: {name:'',code:0},
    showDailySummaryDialog: false,
    appSettings:defaultSettings(),
    isLoading:false,
    errorMsg:'',
    showBookedReservedRoomsDialog:false,
    bookedRooms:[],
    showBookedRoomsList:false,
    reportBookingType:'Booking',
    showAvailableRoomsDialog:false,
    availableRooms:[],
    showAvailableRoomsList:false,
    beginDate:changeDateFormat(new Date()),
    endDate:changeDateFormat(new Date()),
    actionCaller:'LocationSummary',
    hotelName:''
}
const settings = new Settings();
const hotel = new Hotel();

const Reporting=()=>{
    const [state,setState]=useState<TReporting>(INITIAL_STATE);
    const queryClient=useQueryClient();
    const [userData]=useUserData();
    const toastRef=useRef(null);
    useEffect(()=>{
        const initReport=async ()=>{
            const appSettings = await settings.getLocationSettings();
            const hotelsList = isAppOnline(appSettings.connectivityState) ? await hotelsListQuery(queryClient, userData.staffId) : await hotel.getOffLineHotelsList(userData.staffId);
            setStateValues({
                hotels:remakeDropdownSelects(hotelsList!, 'hotelName', 'hotelId'),
                user:userData,
                appSettings:appSettings,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                hotelName:userData.hotelName,
            });
        }
        initReport().catch(console.error);
    },[]);
    const setStateValues=(stateValues:Partial<TReporting>)=>{
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    }

    const itemRenderer = (item: any) => (
        <div className="p-menuitem-content">
            <a className="flex align-items-center p-menuitem-link" onClick={item.command}>
                <span className={item.icon} />
                <span className="mx-2">{item.label}</span>
                {item.badge && <Badge className={`ml-auto ${item.bgColor}`} value={item.badge} />}
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </a>
        </div>
    );
    const summaryMenu = [
        {
            label: 'Activity Summary',
            items: [
                {
                    label: 'Daily Summary',
                    icon: 'pi pi-fw pi-file-pdf',
                    command: () => setStateValues({ showDailySummaryDialog: true,actionCaller:'LocationSummary' })
                },
                {
                    label: 'Receptionists Summary',
                    icon: 'pi pi-fw pi-file-pdf',
                    command: () => setStateValues({ showDailySummaryDialog: true,actionCaller:'ReceptionistSummary' })
                },
                {
                    label: 'Location Summary',
                    icon: 'pi pi-fw pi-file-pdf',
                    command: () => setStateValues({ showDailySummaryDialog: true,actionCaller:'LocationsSummary' })
                },
                {
                    label: 'Company Summary',
                    icon: 'pi pi-fw pi-file-pdf',
                    command: () => setStateValues({ showDailySummaryDialog: true,actionCaller:'CompanySummary' })
                },
                {
                    label: 'Companies Payable',
                    icon: 'pi pi-fw pi-file-pdf',
                    command: () => setStateValues({ showDailySummaryDialog: true,actionCaller:'CompaniesPayable' })
                },
            ]
        }
    ];
    const bookingsMenu = [
        {
            label: 'Booking / Reservations',
            items: [
                {
                    label: 'Booked Rooms',
                    icon: 'pi pi-file-pdf',
                    // badge: 'New',
                    bgColor: 'bg-yellow-800',
                    template: itemRenderer,
                    command: () => setStateValues({ showBookedReservedRoomsDialog: true,reportBookingType:'Booking' })
                },
                {
                    label: 'Reserved Rooms',
                    icon: 'pi pi-file-pdf',
                    // badge: 'New',
                    bgColor: 'bg-yellow-800',
                    template: itemRenderer,
                    command: () => setStateValues({ showBookedReservedRoomsDialog: true,reportBookingType:'Reservation' })
                },
                {
                    label: 'Available Rooms',
                    icon: 'pi pi-file-pdf',
                    // badge: 'New',
                    bgColor: 'bg-yellow-800',
                    template: itemRenderer,
                    command: () => setStateValues({ showAvailableRoomsDialog: true })
                }
            ]
        }
    ];
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <ReportsContext.Provider value={{state,setStateFunction:setStateValues}}>
                <div className="p-fluid grid">
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Summary</h5>
                            <Menu model={summaryMenu} className="w-12" />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Booking / Reservations</h5>
                            <Menu model={bookingsMenu} className="w-12" />
                        </div>
                    </div>
                </div>
                {state.showDailySummaryDialog && <DailySummaryDialog />}
                {state.showBookedReservedRoomsDialog && <BookedReservedRoomsDialog />}
                {state.showAvailableRoomsDialog && <AvailableRoomsDialog />}
            </ReportsContext.Provider>
        </>
    )
}
export default Reporting;
