import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DropdownOption, TAppState, TCrudType, TRoomType } from '../../utilities/types';
import { hotelLocationRoomsTypeQuery, hotelLocationsListQuery, hotelsListQuery } from '../../utilities/reactQueryUtils';
import useUserData from '../../hooks/customHooks';
import { useQueryClient } from '@tanstack/react-query';
import { displayMessage, getTableRowId, isAppOnline, pageDataValidation, remakeDropdownSelects } from '../../utilities/utilFunctions';
import { Button } from 'primereact/button';
import RoomTypes from '../../utilities/classObjects/RoomTypes';
import Joi from 'joi';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import RoomTypesList from '../DataTables/RoomTypesList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import Settings from '../../utilities/classObjects/Settings';
import Hotel from '../../utilities/classObjects/Hotel';
import HotelLocation from '../../utilities/classObjects/HotelLocation';
import { useGlobalContext } from '../contextProviders/GlobalContext';

const roomType = new RoomTypes();
const settings = new Settings();
const hotel = new Hotel();
const locationObject=new HotelLocation();
const validateRoomType = Joi.object({
    hotelId: Joi.number().min(1).messages({ 'number.min': 'Select a valid hotel for room Type' }),
    hotelLocationId: Joi.number().min(1).messages({ 'number.min': 'Select a valid hotel Location for room Type' }),
    roomTypeDesc: Joi.string().required().messages({ 'string.empty': 'Add a valid description for type' }),
    roomTypeId: Joi.number(),
    locationId: Joi.number().min(1).messages({ 'number.min': 'Select a valid location' }),
    modifiedBy:Joi.number()
});
const RoomType = ({reQueryRoomTypes}:{reQueryRoomTypes?:()=>void}) => {
    const queryClient = useQueryClient();
    const [roomTypeId, setRoomTypeId] = useState<number>(0);
    const [roomTypeDescription, setRoomTypeDescription] = React.useState<string>('');
    const [hotels, setHotels] = React.useState<DropdownOption[]>([]);
    const [hotelLocations, setHotelLocations] = React.useState<DropdownOption[]>([]);
    const [selectedHotel, setSelectedHotel] = React.useState<DropdownOption>({ name: '', code: '0' });
    const [selectedHotelLocation, setSelectedHotelLocation] = React.useState<DropdownOption>({ name: '', code: '0' });
    const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
    const [crudType, setCrudeType] = useState<TCrudType>('save');
    const [connectivity, setConnectivity] = useState<TAppState>('onLine');
    const [isLoading, setIsLoading] = useState(false);
    const [roomTypesList, setRoomTypesList] = React.useState<TRoomType[]>([]);
    const {currentAppMode}=useGlobalContext();
    const [userData] = useUserData();
    const toastRef = useRef(null);

    useEffect(() => {
        const initRoomTypes = async () => {
            const appSettings = await settings.getLocationSettings();
            const hotelsList = isAppOnline(appSettings.connectivityState) ? await hotelsListQuery(queryClient, userData.staffId) : await hotel.getOffLineHotelsList(userData.staffId);
            setHotels(remakeDropdownSelects(hotelsList!, 'hotelName', 'hotelId'));
            setConnectivity(appSettings.connectivityState);
        };
        initRoomTypes().catch(console.error);
    }, []);
    const onHotelChange = async (e: any) => {
        setSelectedHotel(e.value);
        const hotelLocations = isAppOnline(connectivity)?await hotelLocationsListQuery(queryClient, e.value.code):await locationObject.getOfflineLocations(e.value.code);
        setHotelLocations(remakeDropdownSelects(hotelLocations!, 'locationName', 'hotelLocationId'));
    };
    const onHotelLocationChange = (e: any) => {
        setSelectedHotelLocation(e.value);
    };
    const addNewRoomType = async () => {
        try {
            const roomTypeData: TRoomType = {
                roomTypeDesc: roomTypeDescription,
                hotelId: parseInt(selectedHotel.code as string),
                locationId: parseInt(selectedHotelLocation.code as string),
                roomTypeId: roomTypeId,
                modifiedBy:userData.staffId
            };
            if (!pageDataValidation(validateRoomType, roomTypeData, toastRef)) return;
            setIsLoading(true);
            const roomTypeResults =isAppOnline(connectivity) ? await roomType.addNewRoomType(roomTypeData, crudType) : await roomType.addNewRoomTypeLocal({ ...roomTypeData, hotelName: selectedHotel.name, locationName: selectedHotelLocation.name }, crudType);

            if (roomTypeResults.status === 200) {
                crudType === 'save'
                    ? await addRecordToCache<TRoomType>(queryClient, ['roomTypesList', userData.hotelLocationId], roomTypeResults.operatedData!)
                    : await updateCacheRecord<TRoomType>(queryClient, ['roomTypesList', userData.hotelLocationId], [roomTypeResults.operatedData, roomTypeId, 'roomTypeId']);
                displayMessage({
                    header: 'Success',
                    message: 'Room Type Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                if(reQueryRoomTypes) reQueryRoomTypes();
                resetRoomType();
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };
    const setupRoomTypeEdit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedRoomType = roomTypesList.find((room) => room.roomTypeId === parseInt(getTableRowId(e, 'id')));
        if (selectedRoomType !== undefined) {
            setRoomTypeDescription(selectedRoomType.roomTypeDesc);
            await onHotelChange({ value: { name: selectedRoomType.hotelName, code: selectedRoomType.hotelId } });
            onHotelLocationChange({ value: { name: selectedRoomType.locationName, code: selectedRoomType.locationId } });
            setRoomTypeId(selectedRoomType.roomTypeId!);
            setCrudeType('update');
            setShowDialogBox(false);
        }
    };
    const resetRoomType = () => {
        setRoomTypeDescription('');
        setSelectedHotel({ name: '', code: 0 });
        setSelectedHotelLocation({ name: '', code: 0 });
    };
    const onDialogShow = async () => {
        try {
            setIsLoading(true);
            const roomTypesList = isAppOnline(connectivity) ? await hotelLocationRoomsTypeQuery(queryClient, userData.hotelLocationId)
                : await roomType.getOfflineRoomTypes(userData.hotelLocationId);
            setRoomTypesList(roomTypesList!);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar
                    start={<LeftToolBar
                        firstButton={{title:'List',icon:'pi pi-list',onClickEvent:() => setShowDialogBox(true)}}
                    />}
                    end={<RightToolBar/>}></Toolbar>
            </div>
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="col-6">
                                <div className="field">
                                    <label htmlFor="hotel">Hotels</label>
                                    <Dropdown value={selectedHotel} onChange={onHotelChange} options={hotels} optionLabel="name" placeholder="Select" />
                                </div>
                                <div className="field">
                                    <label htmlFor="hotelLocations">Locations</label>
                                    <Dropdown value={selectedHotelLocation} onChange={onHotelLocationChange} options={hotelLocations} optionLabel="name" placeholder="Select" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                    <label htmlFor="roomType">Room Type</label>
                                    <InputText id="roomType" type="text" value={roomTypeDescription} onChange={(e) => setRoomTypeDescription(e.target.value)} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <Button onClick={addNewRoomType} className="mt-4">
                                        {crudType === 'save' ? 'New Room Type' : 'Update Room Type'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDialogBox && <div className="lg:col-6 md:col-4 sm:col-4">
                <Dialog onHide={() => setShowDialogBox(false)} visible={showDialogBox} position={'top'} onShow={onDialogShow}>
                    <RoomTypesList tableData={roomTypesList} setupTableDataEdit={setupRoomTypeEdit} promptTableDataDelete={() => {}} />
                </Dialog>
            </div>}
        </>
    );
};
export default RoomType;
