import React, { useEffect, useRef, useState } from 'react';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber, InputNumberChangeEvent, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Badge } from 'primereact/badge';
import Joi from 'joi';
import { DropdownOption, TItem, TLocationSettings, TPurchase, TSelectedPurchaseListItem, TSuppliers } from '../../../utilities/types';
import Items from '../../../utilities/classObjects/Items';
import { changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId,
    isAppOnline, makeSelectableItemList, onInputControlFocus, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../../../utilities/utilFunctions';
import useUserData from '../../../hooks/customHooks';
import { DatePicker, DiscountComponent, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, tableEditOption } from '../../../utilities/components/UtilityComponents';
import { addRecordToCache, updateCacheRecord } from '../../../utilities/reactQueryFunctions';
import SimpleTable from '../../../utilities/components/SimpleTable';
import { Menubar } from 'primereact/menubar';
import { itemsListQuery, purchaseListQuery, suppliersListQuery } from '../../../utilities/reactQueryUtils';
import Settings from '../../../utilities/classObjects/Settings';
import { validatePurchase } from '../../../utilities/joiFunctions/joiFunctions';

const _ = require('lodash');

type TPurchaseState = TPurchase & {
    purchaseListItems: DropdownOption[];
    selectedItem: string;
    suppliersList: DropdownOption[];
    purchaseTotalCost: number;
    showPurchaseDialog: boolean;
    isLoading: boolean;
    editState: boolean;
    quantityEditOptions: string[];
    selectedEditOption: string;
    editQtyValue: number;
    editingItemId: string;
    itemsInEdit: TSelectedPurchaseListItem[];
    discountOptions: string[];
    discountValue: string;
    purchaseList: TPurchase[],
    suppliers: TSuppliers[],
    itemsList: TItem[],
    purchaseSupplier: DropdownOption,
    purchaseItem: DropdownOption,
    displayDate:Date,
    appSettings:TLocationSettings
};

const INITIAL_STATE: TPurchaseState = {
    purchaseListItems: [],
    selectedItem: '',
    selectedPurchaseListItems: [],
    purchaseDate: changeDateFormat(new Date()),
    suppliersList: [],
    selectedSupplier: 0,
    purchaseTotalCost: 0,
    amountPaid: 0,
    purchaseId: 0,
    modifiedBy: 0,
    showPurchaseDialog: false,
    isLoading: false,
    editState: false,
    quantityEditOptions: ['Add', 'Return'],
    selectedEditOption: '',
    editQtyValue: 0,
    editingItemId: '0',
    itemsInEdit: [],
    purchaseDiscount: { discountOption: 'Cash Discount', discountAmount: '0', discountPercentage: 0 },
    discountOptions: ['Cash Discount', 'Percentage Discount'],
    discountValue: '0',
    purchaseList: [],
    suppliers: [],
    itemsList: [],
    purchaseItem: { name: '', code: 0 },
    purchaseSupplier: { name: '', code: 0 },
    hotelLocationId:0,
    hotelId:0,
    discountAmount:'0',
    displayDate:new Date(),
    appSettings:defaultSettings()
};


const validateItemQuantityEdit = Joi.object({
    selectedEditOption: Joi.string().messages({ 'string.empty': 'Select edit action for quantity edit' }),
    editQtyValue: Joi.number().min(1).messages({ 'number.min': 'Ensure that editing quantity is greater than 0' })
});
const item = new Items();
const settings = new Settings();
const Purchases = () => {
    const queryClient = useQueryClient();
    const quantityEditRef = useRef<OverlayPanel>(null);
    const [userData] = useUserData();
    const toastRef = useRef(null);
    const discountOPRef = useRef<OverlayPanel>(null);
    const [purchaseState, setPurchaseState] = useState<TPurchaseState>(INITIAL_STATE);

    useEffect(() => {
        const initPurchase = async () => {
            const appSettings = await settings.getLocationSettings();
            const itemsList = await queryItemsList(appSettings.connectivityState);
            const suppliers = await querySuppliersList(appSettings.connectivityState);
            const purchaseList=await queryPurchaseList(appSettings.connectivityState);
            setStateValue({
                purchaseListItems: makeSelectableItemList(itemsList!),
                suppliersList: remakeDropdownSelects(suppliers!, 'supplierName', 'supplierId'),
                modifiedBy: userData.staffId,
                itemsList: itemsList!,
                suppliers: suppliers!,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                purchaseList,
                appSettings
            });
        };
        initPurchase().catch(console.error);
    }, []);
    const queryItemsList = async (appState: string) => {
        return isAppOnline(appState) ? await itemsListQuery(queryClient, userData.hotelLocationId) : await itemsListQuery(queryClient, userData.hotelLocationId);
    };
    const queryPurchaseList=async(appState:string)=>{
        return isAppOnline(appState) ? await purchaseListQuery(queryClient, userData.hotelLocationId) : await item.getOfflinePurchases(userData.hotelLocationId);
    }
    const querySuppliersList = async (appState: string) => {
        return isAppOnline(appState) ? await suppliersListQuery(queryClient, userData.hotelLocationId) : await item.getOfflineSuppliers(userData.hotelLocationId);
    };
    const setStateValue = (stateValues: Partial<TPurchaseState>) => {
        setPurchaseState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getPurchaseStateValues = () => {
        const props: Array<keyof TPurchase> = ['purchaseId','selectedPurchaseListItems','purchaseDate','purchaseDiscount','selectedSupplier','amountPaid','modifiedBy','hotelId','hotelLocationId'];
        return extractStateValues<TPurchase>(props, purchaseState);
    };
    const purchaseValidation = () => {
        return pageDataValidation<Partial<TPurchaseState>>(validatePurchase, getPurchaseStateValues(), toastRef);
    };

    const onItemSelection = (e: DropdownChangeEvent) => {
        const selectedItem = purchaseState.itemsList!.find((item: TItem) => item.itemId === parseInt(e.value.code));

        const objectExists=_.some(purchaseState.selectedPurchaseListItems,(item: TSelectedPurchaseListItem)=>item.itemId===selectedItem?.itemId);
        if(objectExists) return;
        const { itemCost, itemName, itemPrice } = selectedItem!;
        setStateValue({
            selectedItem: e.value.code,
            purchaseItem: e.value,
            selectedPurchaseListItems: [
                ...purchaseState.selectedPurchaseListItems,
                {
                    itemName: itemName,
                    itemId: e.value.code,
                    itemCost: parseFloat(itemCost),
                    itemQty: 1,
                    itemTotalCost: parseFloat(itemCost),
                    itemSell: parseFloat(itemPrice),
                    qtyAdded: 0,
                    qtyReturned: 0
                }
            ]
        });
    };
    const itemCostInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemCost} inputButtonsClick={onItemCostButtonChange} inputValueChange={onItemCostValueChange} numberInputId={rowData.itemId.toString()} allowDecimalValues />;
    };
    const itemQuantityInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons allowDecimalValues disableState={purchaseState.editState} inputValue={rowData.itemQty} inputButtonsClick={onItemQuantityButtonClicked} inputValueChange={onItemQuantityValueChange} numberInputName={rowData.itemId.toString()} />;
    };
    const itemSellPriceInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemSell} inputButtonsClick={onItemSellPriceValueChange} numberInputName={rowData.itemId.toString()} allowDecimalValues/>;
    };
    const onItemCostButtonChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(e.target.id));
        if (editingItem !== undefined)
            setPurchaseItemCostChange(editingItem, e.value!);
    };
    const onItemCostValueChange = (e: InputNumberChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt((e.originalEvent.target as HTMLElement).parentElement?.id!));
        if (editingItem !== undefined)
            setPurchaseItemCostChange(editingItem, e.value!);
    };
    const onItemQuantityButtonClicked = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(e.target.name));
        if (editingItem !== undefined) {
            editingItem.itemQty = e.value!;
            editingItem.itemTotalCost = editingItem.itemCost * e.value!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const onItemQuantityValueChange = (e: InputNumberChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(((e.originalEvent.target as HTMLElement).parentElement?.firstChild as HTMLElement).getAttribute('name')!));
        if (editingItem !== undefined)
            setPurchaseItemQuantityChange(editingItem!, e.value!);
    };
    const setPurchaseItemQuantityChange = (editingItem: TSelectedPurchaseListItem, quantityValue: number) => {
        if (editingItem !== undefined) {
            editingItem.itemQty = quantityValue!;
            editingItem.itemTotalCost = editingItem.itemCost * quantityValue!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const setPurchaseItemCostChange = (editingItem: TSelectedPurchaseListItem, costValue: number) => {
        if (editingItem !== undefined) {
            editingItem.itemCost = costValue!;
            editingItem.itemTotalCost = (editingItem.itemQty + editingItem.qtyAdded - editingItem.qtyReturned) * costValue!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const onItemSellPriceValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(e.target.name));
        if (editingItem !== undefined) {
            editingItem.itemSell = e.value!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const removeListItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = parseInt(getTableRowId(e, 'id'));
        if (purchaseState.editState) {
            if (_.find(purchaseState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === itemId)) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Remove Lock',
                    message: 'Item cannot be removed from list. You can choose to return all the quantities!',
                    infoType: 'warn',
                    life: 5000
                });
                return;
            }
        }

        const listItems = purchaseState.selectedPurchaseListItems.filter((item) => item.itemId !== itemId);
        setStateValue({ selectedPurchaseListItems: listItems });
    };
    const getItemsTotalCost = () => {
        if (purchaseState.editState) {
            return purchaseState.selectedPurchaseListItems.reduce((previousValue, currentValue) => {
                return previousValue + (currentValue.itemQty - currentValue.qtyReturned + currentValue.qtyAdded) * currentValue.itemCost;
            }, 0);
        }

        return purchaseState.selectedPurchaseListItems.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.itemTotalCost;
        }, 0);
    };

    const onPurchasePaidButtonClick = (e: InputNumberValueChangeEvent) => {
        setStateValue({ amountPaid: e.value! });
    };
    const onPurchasePaidInputValueChange=(e:InputNumberChangeEvent)=>{
        setStateValue({ amountPaid: e.value! });
    }
    const completePurchase = async () => {
        try {
            if (!purchaseValidation()) return;
            const stateValues: TPurchase = getPurchaseStateValues();
            setStateValue({ isLoading: true });
            const responseData = isAppOnline(purchaseState.appSettings.connectivityState)?await item.newPurchase(stateValues):await item.addLocalPurchase(stateValues);
            if (responseData.status === 200) {
                await addRecordToCache(queryClient, ['purchasesList'], responseData.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Purchase was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const resetState = () => {
        setStateValue({ purchaseId: 0, selectedPurchaseListItems: [], selectedSupplier: 0, purchaseDate: changeDateFormat(new Date()), amountPaid: 0, purchaseDiscount: { discountAmount: '0', discountPercentage: 0, discountOption: 'Cash Discount' } });
    };
    const setupPurchaseEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const purchaseId = parseInt(getTableRowId(e, 'id'));

        const selectedPurchase: TPurchase = _.find(purchaseState.purchaseList, (listItem: TPurchase) => listItem.purchaseId === purchaseId);

        if (selectedPurchase !== undefined) {
            const { purchaseId, purchaseDate, selectedPurchaseListItems, selectedSupplier, modifiedBy, amountPaid, discountAmount,supplierName } = selectedPurchase;
            const parsedList = typeof selectedPurchaseListItems !== 'object' ? JSON.parse(selectedPurchaseListItems) : selectedPurchaseListItems;
            const listItems = parsedList.map((listItem: TSelectedPurchaseListItem) => {
                return { ...listItem, itemTotalCost: (listItem.itemQty - listItem.qtyReturned + listItem.qtyAdded) * listItem.itemCost };
            });

            setStateValue({
                purchaseId,
                purchaseDate: changeDateFormat(new Date(purchaseDate)),
                displayDate:new Date(purchaseDate),
                selectedSupplier,
                selectedPurchaseListItems: listItems,
                amountPaid,
                modifiedBy,
                showPurchaseDialog: true,
                editState: true,
                itemsInEdit: parsedList,
                purchaseDiscount: { ...purchaseState.purchaseDiscount, discountAmount: discountAmount! },
                purchaseSupplier:{name:supplierName!,code:selectedSupplier}
            });
        }
    };

    const deletePurchase = () => {
    };
    const onDialogHide = () => {
        setStateValue({ showPurchaseDialog: false, editState: false });
    };
    const purchasesMenu = () => {
        return [
            {
                label: 'New Purchase',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showPurchaseDialog: true, selectedPurchaseListItems: [], selectedItem: '', selectedSupplier: 0 })
            }
        ];
    };
    const updatePurchase = async () => {
        try {
            if (!purchaseValidation()) return;
            const stateValues: TPurchase = getPurchaseStateValues();
            setStateValue({ isLoading: true });
            const responseData = isAppOnline(purchaseState.appSettings.connectivityState)?await item.updatePurchase(stateValues):await item.updateLocalPurchase(stateValues);
            if (responseData.status === 200) {
                await updateCacheRecord(queryClient, ['purchaseList',userData.hotelLocationId], [responseData.operatedData, purchaseState.purchaseId, 'purchaseId']);
                setStateValue({purchaseList:await queryPurchaseList(purchaseState.appSettings.connectivityState)})
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Purchase was successfully updated!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const editItemQty = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'name');
        setStateValue({ editingItemId: itemId });
        quantityEditRef.current?.toggle(e);
    };
    const onQuantityEditOptionSelect = (e: SelectButtonChangeEvent) => {
        if (e.value === 'Return' && !_.find(purchaseState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(purchaseState.editingItemId))) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: 'You cannot issue return for this item. It is just being added!',
                infoType: 'warn',
                life: 5000
            });
            return;
        }
        setStateValue({ selectedEditOption: e.value });
    };
    const onEditQtyValueChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ editQtyValue: e.value! });
    };

    const completeQuantityEditAction = async () => {
        if (!pageDataValidation(validateItemQuantityEdit, { selectedEditOption: purchaseState.selectedEditOption, editQtyValue: purchaseState.editQtyValue }, toastRef)) {
            return;
        }

        const affectingItem: TSelectedPurchaseListItem = _.find(purchaseState.selectedPurchaseListItems, (listItem: TSelectedPurchaseListItem) => listItem.itemId === parseInt(purchaseState.editingItemId));
        const addedQty = purchaseState.selectedEditOption === 'Add' ? purchaseState.editQtyValue : 0;
        const returnedQty = purchaseState.selectedEditOption === 'Return' ? purchaseState.editQtyValue : 0;
        const itemTotalCost = (affectingItem.itemQty - (affectingItem.qtyReturned + returnedQty) + (affectingItem.qtyAdded + addedQty)) * affectingItem.itemCost;
        if (!_.find(purchaseState.itemsInEdit, (purchaseItem: TSelectedPurchaseListItem) => purchaseItem.itemId === parseInt(purchaseState.editingItemId))) {
            const updatedItem: TSelectedPurchaseListItem = { ...affectingItem, itemTotalCost, itemQty: addedQty };
            const newItems = await updateCacheItem<TSelectedPurchaseListItem>(updatedItem, affectingItem, purchaseState.selectedPurchaseListItems);
            setStateValue({ selectedPurchaseListItems: newItems, editQtyValue: 0 });
            return;
        }
        const affectingItemEdited: TSelectedPurchaseListItem = { ...affectingItem, qtyReturned: returnedQty + affectingItem.qtyReturned, qtyAdded: addedQty + affectingItem.qtyAdded, itemTotalCost };
        const newSelectedItemsState = await updateCacheItem<TSelectedPurchaseListItem>(affectingItemEdited, affectingItem, purchaseState.selectedPurchaseListItems);
        setStateValue({ selectedPurchaseListItems: newSelectedItemsState, editQtyValue: 0 });
    };
    const onDiscountOptionChange = (e: SelectButtonChangeEvent) => {
        setStateValue({ purchaseDiscount: { ...purchaseState.purchaseDiscount, discountOption: e.value } });
    };
    const getPurchaseBalance = () => {
        return getItemsTotalCost() - (purchaseState.amountPaid + parseFloat(purchaseState.purchaseDiscount.discountAmount));
    };
    const onApplyDiscount = () => {
        const purchaseTotals = getItemsTotalCost();
        const discountPercent = purchaseState.purchaseDiscount.discountOption === 'Cash Discount' ? (parseFloat(purchaseState.discountValue) / purchaseTotals) * 100 : parseFloat(purchaseState?.discountValue!);
        const discountAmount = purchaseState.purchaseDiscount.discountOption === 'Cash Discount' ? purchaseState.discountValue : ((parseFloat(purchaseState?.discountValue!) / 100) * purchaseTotals).toFixed(2);
        setStateValue({ purchaseDiscount: { ...purchaseState.purchaseDiscount, discountPercentage: discountPercent, discountAmount } });
        displayMessage({
            toastComponent: toastRef,
            header: 'Discount Applied',
            message: 'Discount was successfully applied to purchase',
            infoType: 'success',
            life: 5000
        });
    };
    return (
        <>
            {purchaseState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <Menubar model={purchasesMenu()} />
                <SimpleTable
                    tableKey={'purchaseId'}
                    columnsDef={[
                        { body: (rowData: TPurchase) => <div>{changeDateFormat(new Date(rowData.purchaseDate))}</div>, header: 'Date' },
                        { field: 'supplierName', header: 'Supplier' },
                        { field: 'username', header: 'User' },
                        { body:()=><div><span className="underline text-blue-600 cursor-pointer">View Items List</span></div>, header: 'Items In Purchase' },
                        { body: (rowData: TPurchase) => tableEditOption(setupPurchaseEdit, deletePurchase, rowData.purchaseId!.toString()), header: 'Edit' }
                    ]}
                    tableData={purchaseState.purchaseList}
                    tableTitle="Purchases List"
                    lastTableUpdate={new Date().getTime()}
                    searchValues={['supplierName', 'purchaseDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Supplier Name, Purchase Date or Purchase Person"
                />
            </div>
            <Dialog onHide={onDialogHide} visible={purchaseState.showPurchaseDialog} maximized>
                <div className="lg:min-h-full">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <label htmlFor="purchaseDate">Purchase Date</label>
                                    <DatePicker
                                        dateValue={purchaseState.displayDate as Date}
                                        onDateChange={(e: CalendarChangeEvent) =>setStateValue({displayDate:e.value! as Date,purchaseDate:changeDateFormat(e.value! as Date)})}
                                        labelText="Purchase Date"
                                        controlId="purchaseDate"
                                        selectionType="single"
                                        displayButtonBar={true}
                                        displayTime={false}
                                    />
                                </div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={purchaseState?.suppliersList!} selectedOption={purchaseState?.purchaseSupplier!} onSelectChange={(e) => setStateValue({ purchaseSupplier: e.value, selectedSupplier: e.value.code })} elementId="selectedSupplier"
                                                  defaultValue="Suppliers" />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={purchaseState?.purchaseListItems!} selectedOption={purchaseState?.purchaseItem!} onSelectChange={onItemSelection} elementId="selectedItem" defaultValue="Items List" />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="grid">
                                <DataTable value={purchaseState.selectedPurchaseListItems} emptyMessage="Select an item to continue">
                                    <Column
                                        header="Item"
                                        style={{ width: '30rem' }}
                                        body={(rowData: TSelectedPurchaseListItem) => (
                                            <div>
                                                {`${rowData.itemName}`}{' '}
                                                <span>
                                                    {rowData.qtyReturned > 0 || rowData.qtyAdded > 0 ? (
                                                        <>
                                                            <Badge value={rowData.qtyReturned} severity="danger" className="mr-2" />
                                                            <Badge value={rowData.qtyAdded} severity="success" />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    ></Column>
                                    <Column field="itemCost" header="Cost Price" style={{ width: '20rem' }} body={itemCostInput}></Column>
                                    <Column header="Sell Price" style={{ width: '20rem' }} body={itemSellPriceInput}></Column>
                                    <Column header="Qty" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                                    <Column field="itemTotalCost" header="Total Cost" style={{ width: '20rem' }}></Column>
                                    <Column
                                        body={(rowData: TSelectedPurchaseListItem) => (
                                            <div>
                                                <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId.toString()} onClick={removeListItem} />

                                                <Button icon="pi pi-pencil" className="ml-2 p-button-warning" name={rowData.itemId.toString()} onClick={editItemQty} />
                                            </div>
                                        )}
                                        header="Edit"
                                        style={{ width: '10rem' }}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Total Cost:</div>
                        <div className="col-6 align-items-start">{getItemsTotalCost()}</div>
                    </div>
                    <div className="grid mt-3">
                        <div className="col-3">Total Paid:</div>
                        <div className="col-6 align-items-start">
                            <NumberInputWithButtons inputValue={purchaseState.amountPaid} inputButtonsClick={onPurchasePaidButtonClick} inputValueChange={onPurchasePaidInputValueChange} numberInputId="purchaseTotal" allowDecimalValues/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Discount:</div>
                        <div className="col-6 align-items-start">{purchaseState.purchaseDiscount.discountAmount}</div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Balance:</div>
                        <div className="col-6 align-items-start">{getPurchaseBalance()}</div>
                    </div>
                    <div className="grid mt-4">
                        <div className="col-2">
                            <Button onClick={(e) => discountOPRef.current?.toggle(e)}>Add Purchase Discount</Button>
                        </div>
                        <div className="col-2">
                            <Button onClick={!purchaseState.editState ? completePurchase : updatePurchase}>{!purchaseState.editState ? `Complete Purchase` : `Update Purchase`}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <OverlayPanel ref={quantityEditRef} className="lg:w-3">
                <div className="card">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <label>Select What to do</label>
                            <SelectButton className="mt-2" value={purchaseState.selectedEditOption} onChange={onQuantityEditOptionSelect} options={purchaseState.quantityEditOptions} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-2">
                        <div className="field">
                            <label>Editing Quantity</label>
                            <InputNumber className="mt-2" value={purchaseState.editQtyValue} onValueChange={onEditQtyValueChange} onFocus={onInputControlFocus} onBlur={onInputControlFocus}/>
                        </div>
                        <Button className="mt-2 w-7" onClick={completeQuantityEditAction}>{`${purchaseState.selectedEditOption} Quantity`}</Button>
                    </div>

                </div>
            </OverlayPanel>
            <DiscountComponent
                discountOPRef={discountOPRef}
                discountOptions={purchaseState.discountOptions}
                onDiscountOptionChange={onDiscountOptionChange}
                discountSelectedOption={purchaseState.purchaseDiscount.discountOption}
                discountAmount={purchaseState.discountValue}
                onDiscountAmountChange={(e) => setStateValue({ discountValue: e.target.value })}
                applyDiscountAction={onApplyDiscount}
            />
        </>
    );
};

export default Purchases;
