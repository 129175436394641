import React, { useEffect, useReducer, useRef } from 'react';
import {
    changeDateFormat, defaultSettings, displayMessage, extractStateValues, getGenders,
    getTableRowId, getUserRoles, isAppOnline, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, staffDepartments, StateReducerHandler
} from '../../utilities/utilFunctions';
import { DropdownOption, TAppState, TCrudType, TLocationSettings, TRegistrationState, TUser } from '../../utilities/types';
import { validatePersonalDetails } from '../../utilities/joiFunctions/joiFunctions';
import AppUsers from '../../utilities/classObjects/AppUsers';
import useUserData from '../../hooks/customHooks';
import { useQueryClient } from '@tanstack/react-query';
import { locationStaffQuery } from '../../utilities/reactQueryUtils';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { RegistrationContext } from '../contextProviders/RegistrationContextProvider';
import Staffs from './Staffs';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import StaffList from '../DataTables/staffList';
import Settings from '../../utilities/classObjects/Settings';


type TStaffState = TRegistrationState & {
    staffList: TUser[];
    crudType: TCrudType,
    appState: TAppState,
    appSettings: TLocationSettings,
};
const INITIAL_STATE: TStaffState = {
    toolBarVisible: true,
    staffName: '',
    phoneNumber: '',
    address: '',
    genders: getGenders,
    selectedGender: { name: '', code: 0 },
    userRoles: getUserRoles,
    selectedUserRole: { name: '', code: 0 },
    email: '',
    dateOfBirthDisplay: new Date(),
    username: '',
    password: '',
    repeatPassword: '',
    dateOfBirth: changeDateFormat(new Date()),
    staffList: [],
    crudType: 'save',
    staffId: 0,
    appState: 'onLine',
    appSettings: defaultSettings(),
    staffTypes: [],
    staffType:''
};
const user = new AppUsers();
const settings = new Settings();
const StaffMain = () => {
    const toastRef = useRef(null);
    const [state, dispatch] = useReducer(StateReducerHandler<TStaffState>, INITIAL_STATE);
    const [userData, setUserData] = useUserData();
    const queryClient = useQueryClient();

    useEffect(() => {
        const initStaff = async () => {
            const appSettings = await settings.getLocationSettings();
            const staffs = isAppOnline(appSettings.connectivityState) ? await locationStaffQuery(queryClient, userData.hotelLocationId)
                : await user.getOfflineUsers(userData.hotelLocationId);
            setStateValues({ staffList: staffs, appSettings, appState: appSettings.connectivityState, modifiedBy: userData.staffId, staffTypes: remakeDropdownSelects(staffDepartments, 'name', 'name') });
        };
        initStaff().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TStaffState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getStaffDetails = () => {
        const props: Array<keyof TUser> = ['staffName', 'phoneNumber', 'address', 'userRole', 'gender', 'email',
            'dateOfBirth', 'username', 'password', 'repeatPassword', 'staffId', 'modifiedBy','staffType'];
        return extractStateValues<TUser>(props, state);
    };
    const saveStaffOnly = async () => {
        if (!isAppOnline(state.appState)) {
            displayMessage({
                header: 'Not Allowed',
                message: 'You cannot add or edit Staff Members locally. Switch to online first.',
                life: 3000,
                infoType: 'warn',
                toastComponent: toastRef
            });
            return;
        }
        try {
            const userDetails: TUser = getStaffDetails();
            if (!pageDataValidation(validatePersonalDetails, userDetails, toastRef)) return;
            setStateValues({ isLoading: true });
            const savedStaffResults = await user.addNewUser(userDetails, userData.hotelId, userData.hotelLocationId, state.crudType);
            if (savedStaffResults.status === 200) {
                displayMessage({
                    header: 'Activity Success',
                    message: 'Staff data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetStaff();
            }
        } catch (error) {
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupStaffEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedStaff = state.staffList.find(staff => staff.staffId === parseInt(getTableRowId(e, 'id')));
        if (selectedStaff !== undefined) {
            const { staffId, staffName, phoneNumber, username, address, hotelId, hotelLocationId, gender, status, userRole, email, staffType } = selectedStaff;
            setStateValues({
                staffId, staffName, phoneNumber, username, address, hotelId, hotelLocationId, gender, status, userRole, email: email == null ? '' : email,staffType,
                selectedStaffType:{name:staffType!,code:staffType!},
                selectedUserRole: { name: userRole!, code: userRole! }, repeatPassword: '12345678', password: '12345678',
                selectedGender: { name: gender!, code: gender! },
                crudType: 'update', showDialogBox: false
            });
        }
    };
    const resetStaff = () => {
        setStateValues({
            staffId: 0, staffName: '', phoneNumber: '', username: '', address: '', hotelId: 0, hotelLocationId: 0, gender: '', status: 'Active', userRole: 'Admin', email: '',
            selectedUserRole: { name: ''!, code: '' }, repeatPassword: '', password: '',
            selectedGender: { name: '', code: '' },
            crudType: 'save'
        });
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{ title: 'List', icon: 'pi pi-list', onClickEvent: () => setStateValues({ showDialogBox: true }) }} />} end={<RightToolBar />}></Toolbar>
            </div>
            <RegistrationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <Staffs key={'SelfStaff'} />
            </RegistrationContext.Provider>
            <Button onClick={saveStaffOnly}>{state.crudType === 'save' ? 'Save Staff' : 'Update Staff'}</Button>
            {state.showDialogBox && (
                <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position={'top'}>
                    <StaffList tableData={state.staffList} setupTableDataEdit={setupStaffEdit} promptTableDataDelete={() => {
                    }} />
                </Dialog>
            )}
        </>
    );
};
export default StaffMain;
