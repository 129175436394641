import { THotelLocation } from '../types';
import axiosFetch from '../axiosConfig';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';

class HotelLocation {
    async addNewLocation(hotelLocation:THotelLocation,crudType:string){
        const data=await axiosFetch<THotelLocation>('POST',`${getBaseURL()}/hotel_locations/new_location`,{requestBody:{...hotelLocation,crudType},});
        return {status:data.status,operatedData:data.data?.operatedData}
    }

    async getOfflineLocations(hotelId:number){
        return db.hotelLocation.where({hotelId}).toArray();
    }
}
export default HotelLocation;
