import SimpleTable from '../../utilities/components/SimpleTable';
import { THotelEvents, TTableProps } from '../../utilities/types';
import { tableEditOption } from '../../utilities/components/UtilityComponents';
import React from 'react';
import { changeDateFormat } from '../../utilities/utilFunctions';
import { Button } from 'primereact/button';

const HotelEventsList=({tableData,setupTableDataEdit,promptTableDataDelete,showEventDetails}:TTableProps<THotelEvents> & {showEventDetails?:(e:React.MouseEvent<HTMLButtonElement>)=>void})=>{

    return <>
        <SimpleTable
            tableKey="hotelEventId"
            columnsDef={[
                {field:'eventHost',header:'Host'},
                {header:'Start Date',body:(rowData:THotelEvents)=><div>{changeDateFormat(new Date(rowData.eventStartDate))}</div>},
                {header:'End Date',body:(rowData:THotelEvents)=><div>{changeDateFormat(new Date(rowData.eventEndDate))}</div>},
                {field:'eventTypeDesc',header:'Event Type'},
                {field:'eventCost',header:'Event Cost'},
                {body:(rowData:THotelEvents)=><div><Button icon="pi pi-money-bill" data-event-id={rowData.hotelEventId} onClick={showEventDetails}/></div>,header:'Event Payments'},
                {
                    body: (rowData: THotelEvents) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.hotelEventId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Events'}
            searchValues={['eventHost']}
        />
    </>
}
export default HotelEventsList;
