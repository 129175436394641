import React, { useEffect, useReducer, useRef } from 'react';
import { defaultSettings, displayMessage, extractStateValues, getTableRowId, isAppOnline, pageDataValidation, REDUCER_ACTION_TYPE, StateReducerHandler } from '../../utilities/utilFunctions';
import { TAppState, TCrudType, THotel, TLocationSettings, TRegistrationState } from '../../utilities/types';
import { RegistrationContext } from '../contextProviders/RegistrationContextProvider';
import { Button } from 'primereact/button';
import { validateNewHotelDetails } from '../../utilities/joiFunctions/joiFunctions';
import Hotel from '../../utilities/classObjects/Hotel';
import Hotels from './Hotels';
import useUserData from '../../hooks/customHooks';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import HotelsList from '../DataTables/HotelsList';
import { hotelsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import Settings from '../../utilities/classObjects/Settings';

type THotelState = TRegistrationState & {
    hotelsList: THotel[];
    crudType:TCrudType,
    appState:TAppState,
    appSettings:TLocationSettings
};
const INITIAL_STATE: THotelState = {
    toolBarVisible: true,
    hotelsList: [],
    crudType:'save',
    appState:'onLine',
    appSettings:defaultSettings()
};
const hotel = new Hotel();
const settings=new Settings();
const HotelsMain = () => {
    const [state, dispatch] = useReducer(StateReducerHandler<THotelState>, INITIAL_STATE);
    const [userData] = useUserData();
    const toastRef = useRef(null);
    const queryClient = useQueryClient();
    useEffect(() => {
        const initHotel = async () => {
            const appSettings=await settings.getLocationSettings();
            const hotelLists = isAppOnline(appSettings.connectivityState)?await hotelsListQuery(queryClient, userData.staffId):await hotel.getOffLineHotelsList(userData.staffId);
            setStateValues({ hotelsList: hotelLists,appState:appSettings.connectivityState,appSettings:appSettings });
        };
        initHotel().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<THotelState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getHotelDetails = () => {
        const props: Array<keyof THotel> = ['hotelName', 'hotelPhone', 'emailAddress','hotelId'];
        return extractStateValues<THotel>(props, state);
    };
    const addNewHotel = async () => {
        if(!isAppOnline(state.appState)){
            displayMessage({
                header: 'Not Allowed',
                message: 'You cannot add or edit Hotels locally. Switch to online first.',
                life: 3000,
                infoType: 'warn',
                toastComponent: toastRef
            });
            return;
        }
        try {
            const hotelData: THotel = getHotelDetails();
            if (!pageDataValidation(validateNewHotelDetails, hotelData, toastRef)) return;
            setStateValues({ isLoading: true });
            const saveHotelResults = await hotel.addNewHotel(hotelData, userData.staffId!,state.crudType);
            if (saveHotelResults.status === 200) {
                displayMessage({
                    header: 'Success',
                    message: 'Hotel Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetHotel();
            }
        } catch (error) {
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupHotelEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const selectedHotel=state.hotelsList.find(hotel=>hotel.hotelId===parseInt(getTableRowId(e,'id')));
        if(selectedHotel!==undefined){
            setStateValues({
                hotelName:selectedHotel.hotelName,
                emailAddress:selectedHotel.emailAddress,
                hotelPhone:selectedHotel.hotelPhone,
                hotelId:selectedHotel.hotelId,
                showDialogBox:false,
                crudType:'update'
            });
        }
    }
    const resetHotel=()=>{
        setStateValues({
            hotelName:'',
            emailAddress:'',
            hotelPhone:'',
            hotelId:0,
            crudType:'save'
        });
    }
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{title:'List',icon:'pi pi-list',onClickEvent:() => setStateValues({ showDialogBox: true })}} />} end={<RightToolBar/>}></Toolbar>
            </div>
            <RegistrationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <Hotels key={'SelfHotels'} />
            </RegistrationContext.Provider>
            <Button onClick={addNewHotel}>{state.crudType=='save'?'Save Hotel':'Update Hotel'}</Button>
            {state.showDialogBox && (
                <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position={'top'}>
                    <HotelsList tableData={state.hotelsList} setupTableDataEdit={setupHotelEdit} promptTableDataDelete={() => {}} />
                </Dialog>
            )}
        </>
    );
};
export default HotelsMain;
