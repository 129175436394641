import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumberChangeEvent, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useQueryClient } from '@tanstack/react-query';
import { DropdownOption, TAdjustment, TAdjustmentItems, TItem, TLocationSettings, TSelectedPurchaseListItem } from '../../../utilities/types';
import Items from '../../../utilities/classObjects/Items';
import useUserData from '../../../hooks/customHooks';
import { changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId, isAppOnline, makeSelectableItemList, pageDataValidation, remakeDropdownSelects } from '../../../utilities/utilFunctions';
import { DatePicker, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, tableEditOption } from '../../../utilities/components/UtilityComponents';
import { addRecordToCache } from '../../../utilities/reactQueryFunctions';
import SimpleTable from '../../../utilities/components/SimpleTable';
import { Menubar } from 'primereact/menubar';
import { adjustmentsListQuery, itemsListQuery } from '../../../utilities/reactQueryUtils';
import Settings from '../../../utilities/classObjects/Settings';
import { validateAdjustment } from '../../../utilities/joiFunctions/joiFunctions';
const _ = require('lodash');

type TAdjustmentState = TAdjustment & {
    isLoading: boolean;
    showAdjustmentDialog: boolean;
    adjustmentTypes: DropdownOption[];
    adjustmentItemsList: DropdownOption[];
    selectedItem: DropdownOption;
    editState: boolean;
    itemsList:TItem[],
    adjustmentList:TAdjustment[],
    selectedAdjustment:DropdownOption,
    appSettings:TLocationSettings,
    displayDate:Date
};
const INITIAL_STATE: TAdjustmentState = {
    adjustedItems: [],
    adjustmentDate: changeDateFormat(new Date()),
    adjustmentId: 0,
    adjustmentType: '',
    modifiedBy: '',
    isLoading: false,
    showAdjustmentDialog: false,
    adjustmentTypes: [],
    adjustmentItemsList: [],
    selectedItem: {name:'',code:''},
    editState: false,
    adjustmentComment: '',
    itemsList:[],
    adjustmentList:[],
    selectedAdjustment:{name:'',code:0},
    appSettings:defaultSettings(),
    hotelId:0,
    hotelLocationId:0,
    displayDate:new Date()
};


const items = new Items();
const settings = new Settings();

const Adjustments = () => {
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const [adjustmentState, setAdjustmentState] = useState<TAdjustmentState>(INITIAL_STATE);
    const [userData] = useUserData();

    useEffect(() => {
        const initAdjustment=async ()=>{
            const appSettings = await settings.getLocationSettings();
            const itemsList = await queryItemsList(appSettings.connectivityState);
            const adjustmentsList=isAppOnline(appSettings.connectivityState) ? await adjustmentsListQuery(queryClient, userData.hotelLocationId) : await items.getOfflineAdjustments(userData.hotelLocationId);
            setStateValue({
                adjustmentTypes: remakeDropdownSelects([{ adjustmentType: 'Adjustment In' }, { adjustmentType: 'Adjustment Out' }], 'adjustmentType', 'adjustmentType'),
                modifiedBy: userData.staffId,
                adjustmentItemsList: makeSelectableItemList(itemsList!),
                itemsList,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                adjustmentList:adjustmentsList
            });
        }
        initAdjustment().catch(console.error);
    }, []);
    const setStateValue = (stateValues: Partial<TAdjustmentState>) => {
        setAdjustmentState((prevState) => {
            return { ...prevState!, ...stateValues };
        });
    };
    const queryItemsList = async (appState: string) => {
        return isAppOnline(appState) ? await itemsListQuery(queryClient, userData.hotelLocationId) : await itemsListQuery(queryClient, userData.hotelLocationId);
    };
    const getStateValues = () => {
        const props: Array<keyof TAdjustment> = ['adjustedItems','adjustmentDate','adjustmentType','adjustmentComment','modifiedBy','hotelId','hotelLocationId','modifiedBy'];
        return extractStateValues<TAdjustment>(props, adjustmentState);
    };
    const adjustmentMenu = () => {
        return [
            {
                label: 'New Adjustment',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showAdjustmentDialog: true, adjustedItems: [], adjustmentType: '' })
            }
        ];
    };
    const onHideAdjustmentDialog = () => {
        setStateValue({ showAdjustmentDialog: false, editState: false });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ selectedAdjustment: e.value,adjustmentType:e.value.name });
    };
    const onItemSelection = (e: DropdownChangeEvent) => {
        const selectedItem = adjustmentState.itemsList!.find((item: TItem) => item.itemId === e.value.code);

        const { itemName } = selectedItem!;
        setStateValue({
            selectedItem: e.value,
            adjustedItems: [
                ...adjustmentState.adjustedItems,
                {
                    itemId: e.value.code,
                    adjustmentQty: 1,
                    itemName: itemName
                }
            ]
        });
    };
    const onItemQuantityValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = adjustmentState.adjustedItems.find((listItem: TAdjustmentItems) => listItem.itemId === parseInt(e.target.name));
        if(editingItem!==undefined)
        setAdjustmentItemChange(editingItem,e.value!);
    };
    const onAdjustmentItemQuantityChange=(e:InputNumberChangeEvent)=>{
        const itemId=parseInt(((e.originalEvent.target as HTMLElement).parentElement?.firstChild as HTMLElement).getAttribute('name')!);
        const editingItem = adjustmentState.adjustedItems.find((listItem: TAdjustmentItems) => listItem.itemId === itemId);
        if(editingItem!==undefined)
            setAdjustmentItemChange(editingItem,e.value!);
    }
    const setAdjustmentItemChange=(editingItem:TAdjustmentItems,quantity:number)=>{
        if (editingItem !== undefined) {
            editingItem.adjustmentQty = quantity;
        }
        setStateValue({ adjustedItems: [...adjustmentState.adjustedItems] });
    }
    const itemQuantityInput = (rowData: TAdjustmentItems) => {
        return <NumberInputWithButtons allowDecimalValues={false}
                                       disableState={adjustmentState.editState} inputValue={rowData.adjustmentQty}
                                       inputButtonsClick={onItemQuantityValueChange} numberInputName={rowData.itemId.toString()}
                                       inputValueChange={onAdjustmentItemQuantityChange}/>;
    };
    const deleteFromList = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (adjustmentState.editState) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Delete Avoided',
                message: 'Delete is not currently available',
                infoType: 'warn',
                life: 3000
            });
            return;
        }
        const itemId = parseInt(getTableRowId(e, 'id'));
        const listItems = adjustmentState.adjustedItems.filter((item) => item.itemId !== itemId);
        setStateValue({ adjustedItems: listItems });
    };
    const completeAdjustment = async () => {
        try {
            if (adjustmentState.editState) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Aborted',
                    message: 'Save action is not currently available',
                    infoType: 'warn',
                    life: 3000
                });
                return;
            }
            if (!pageDataValidation<TAdjustment>(validateAdjustment, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const adjustmentResponse = isAppOnline(adjustmentState.appSettings.connectivityState)?await items.newAdjustment(getStateValues()):await items.addLocalAdjustment(getStateValues());

            if (adjustmentResponse.status === 200) {
                await addRecordToCache<TAdjustment>(queryClient, ['adjustmentsList',userData.hotelLocationId], adjustmentResponse.operatedData!);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Adjustment was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupEditAdjustment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const adjustmentId = parseInt(getTableRowId(e, 'id'));
        const selectedAdjustment: TAdjustment = _.find(adjustmentState.adjustmentList, (listItem: TAdjustment) => listItem.adjustmentId === adjustmentId);
        if (selectedAdjustment !== undefined) {
            const { adjustmentId, adjustmentType, adjustedItems, adjustmentDate, adjustmentComment, modifiedBy } = selectedAdjustment;
            const parsedListItems = typeof adjustedItems !== 'object' ? JSON.parse(adjustedItems) : adjustedItems;
            setStateValue({ adjustmentId, adjustmentType, adjustmentDate: changeDateFormat(new Date(adjustmentDate)), adjustedItems: parsedListItems, modifiedBy, adjustmentComment, showAdjustmentDialog: true, editState: true });
        }
    };
    const deleteAdjustment = () => {};
    const resetState = () => {
        setStateValue({ adjustmentId: 0, adjustedItems: [], adjustmentType: '', adjustmentDate: changeDateFormat(new Date()), adjustmentComment: '' });
    };
    return (
        <>
            {adjustmentState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <Menubar model={adjustmentMenu()}/>
                <SimpleTable
                    tableKey={'adjustmentId'}
                    columnsDef={[
                        { body: (rowData: TAdjustment) => <div>{changeDateFormat(new Date(rowData.adjustmentDate))}</div>, header: 'Date' },
                        { field: 'adjustmentType', header: 'Adjustment Type'},
                        { field: 'username', header: 'User', },
                        { body: ()=><div><span className="underline text-blue-600 cursor-pointer">View Items</span></div>, header: 'Adjustment Items', },
                        { body: (rowData: TAdjustment) => tableEditOption(setupEditAdjustment, deleteAdjustment, rowData.adjustmentId?.toString()), header: 'See More'}
                    ]}
                    tableData={adjustmentState.adjustmentList}
                    tableTitle="Adjustments List"
                    lastTableUpdate={new Date().getTime()}
                    searchValues={['adjustmentType', 'adjustmentDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Adjustment Type, Adjustment Date or Adjustment Person"
                />
            </div>
            <Dialog onHide={onHideAdjustmentDialog} visible={adjustmentState.showAdjustmentDialog} maximized>
                <div className="card">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="adjustmentDate">Adjustment Date</label>
                                <DatePicker
                                    dateValue={adjustmentState.displayDate}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValue({ adjustmentDate: changeDateFormat(e.value as Date),displayDate:e.value! as Date })}
                                    labelText="Adjustment Date"
                                    controlId="adjustmentDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={adjustmentState?.adjustmentTypes!} selectedOption={adjustmentState?.selectedAdjustment!} onSelectChange={onSelectChange} elementId="adjustmentType" defaultValue="Adjustment Types" />
                            </div>
                            <div className="field lg:col-12 md:col-12 col-12">
                                <FilterSelect selectableOptions={adjustmentState?.adjustmentItemsList!} selectedOption={adjustmentState?.selectedItem!} onSelectChange={onItemSelection} elementId="selectedItem" defaultValue="Items List" />
                            </div>
                        </div>
                    </div>
                    <DataTable value={adjustmentState.adjustedItems} emptyMessage="Select at least one item" key="itemId" stripedRows={true}>
                        <Column field="itemName" header="Item" style={{ width: '30rem' }}></Column>
                        <Column header="Adjusting Quantity" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                        <Column
                            body={(rowData: TSelectedPurchaseListItem) => (
                                <div>
                                    <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId.toString()} onClick={deleteFromList} />
                                </div>
                            )}
                            header="Del"
                            style={{ width: '10rem' }}
                        ></Column>
                    </DataTable>
                </div>
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-12 md:col-12 col-12">
                            <label htmlFor="adjustmentComment">Adjustment Comment</label>
                            <InputTextarea value={adjustmentState.adjustmentComment} onChange={(e) => setStateValue({ adjustmentComment: e.target.value })} rows={3} cols={170} />
                        </div>
                        <div className="field lg:col-2 md:col-12 col-12">
                            <Button onClick={completeAdjustment}>Complete Adjustment</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default Adjustments;
