import { THotel } from '../types';
import axiosFetch from '../axiosConfig';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';

class Hotel {
    async addNewHotel(hotel:THotel,userId:number,crudeType:string){
        const data=await axiosFetch<THotel>('POST',`${getBaseURL()}/hotels/new_hotel`,{requestBody:{...hotel,userId,crudeType}});
        return {status:data.status,operatedData:data.data?.operatedData};
    }

    async getOffLineHotelsList(ownerId:number){
        return db.hotels.where({personId:ownerId}).toArray();
    }
}
export default Hotel;
