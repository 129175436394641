import { db } from '../../offline/db';
import { TCompanyAndPayments, TDashboardMeta, TExpenditure, THotelEvents, TPayment, TReservations, TUser } from '../types';
import { TDailySummary } from '../types';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { isEqual } from 'date-fns/isEqual';
import { changeDateFormat, getBaseURL } from '../utilFunctions';
import axiosFetch from '../axiosConfig';
import { startOfYear } from 'date-fns/startOfYear';
import { endOfYear } from 'date-fns/endOfYear';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { format } from 'date-fns/format';

const _ = require('lodash');

class ReportActions {
    async getLocalSummary(hotelLocationId: number, startDate: string, endDate: string): Promise<TDailySummary[]> {
        const start = new Date(startDate);
        const end = new Date(endDate);
        try {
            return db.transaction('rw', [db.hotelReservations, db.hotelEvents, db.expenses, db.payments], async () => {

                const localSummary=await this.getLocalSummaryBasic(hotelLocationId,start,end);

                const bookings = localSummary.bookings;

                const reservations = localSummary.reservations;

                const hotelEvents = localSummary.hotelEvents;

                const expenditures = localSummary.expenditures;

                const payments = localSummary.payments;

                const finesseSummary=await this.finesseLocalSummary(bookings,reservations,hotelEvents,payments,expenditures);
                return [{
                    dailySummary: {
                        reservationsCount:finesseSummary.reservationsCount, bookingsCount:finesseSummary.bookingsCount, reservationsPayment: (finesseSummary.sumOfReservations),
                        eventsPayment: finesseSummary.sumOfEvents, totalExpenditure: finesseSummary.sumOfExpenditure, eventsCount: finesseSummary.hotelEventsCount, bookingsPayment: finesseSummary.sumOfBookings,
                        expectedReservations:finesseSummary.sumOfExpectedReservations,expectedBookings:finesseSummary.sumOfExpectedBookings,expectedEvents:finesseSummary.sumOfExpectedEvents
                    }
                }];
            });
        } catch (error) {
            throw error;
        }
    }
    async finesseLocalSummary(bookings:TReservations[],reservations:TReservations[],hotelEvents:THotelEvents[],payments:TPayment[],expenditures:TExpenditure[]){
        const bookingsCount = bookings.length;
        const reservationsCount = reservations.length;
        const hotelEventsCount = hotelEvents.length;
        const reservationsIds=reservations.map(reservation=>reservation.reservationId);
        const bookingsIds=bookings.map(booking=>booking.reservationId);

        const bookingPayments=payments.filter(payment=>bookingsIds.includes(payment.activityId));

        const reservationPayments=payments.filter(payment=>reservationsIds.includes(payment.activityId));

        const sumOfBookings = bookingPayments.reduce((previousValue, currentValue: TPayment) => {
            return previousValue + currentValue.amount;
        }, 0);

        const sumOfReservations = reservationPayments.reduce((previousValue, currentValue: TPayment) => {
            return previousValue + currentValue.amount;
        }, 0);

        const sumOfEvents = payments.filter(payment => payment.paymentType === 'event').reduce((previousValue, currentValue: TPayment) => {
            return previousValue + (currentValue.amount);
        }, 0);

        const sumOfExpenditure = expenditures.reduce((previousValue, currentValue: TExpenditure) => {
            return previousValue + (currentValue?.expenditureAmount);
        }, 0);

        const sumOfExpectedReservations=reservations.reduce((previousValue,currentValue:TReservations)=>{
            return previousValue+(currentValue.numberOfDays!*currentValue.roomRate!);
        },0);
        const sumOfExpectedBookings=bookings.reduce((previousValue,currentValue:TReservations)=>{
            return previousValue+(currentValue.numberOfDays!*currentValue.roomRate!);
        },0);
        const sumOfExpectedEvents=hotelEvents.reduce((previousValue,currentValue:THotelEvents)=>{
            return previousValue+currentValue.eventCost;
        },0);
        return {bookingsCount,reservationsCount,hotelEventsCount,sumOfBookings,sumOfReservations,sumOfEvents,sumOfExpenditure,sumOfExpectedReservations,sumOfExpectedBookings,sumOfExpectedEvents};
    }
    async getLocalSummaryReceptionists(hotelLocationId: number, startDate: string, endDate: string){
        const start = new Date(startDate);
        const end = new Date(endDate);
        const staffType='Receptionist';
        try {
            return db.transaction('rw', [db.hotelReservations, db.hotelEvents, db.expenses, db.payments,db.hotelStaff], async () => {
                const staffSummary:TDailySummary[]=[];
                const localStaffs=await db.hotelStaff.where({hotelLocationId}).and(staff=>staff.staffType===staffType).toArray();
                for(const staff of localStaffs){
                    const localSummary=await this.getLocalSummaryBasic(hotelLocationId,start,end);

                    const bookings = localSummary.bookings.filter(booking=>booking.modifiedBy===staff.staffId);

                    const reservations = localSummary.reservations.filter(reservation=>reservation.modifiedBy===staff.staffId);

                    const hotelEvents = localSummary.hotelEvents.filter(hotelEvent=>hotelEvent.modifiedBy===staff.staffId);

                    const expenditures = localSummary.expenditures.filter(expenditure=>expenditure.modifiedBy===staff.staffId);

                    const payments = localSummary.payments.filter(payment=>payment.modifiedBy===staff.staffId);

                    const finesseSummary=await this.finesseLocalSummary(bookings,reservations,hotelEvents,payments,expenditures);
                    staffSummary.push(
                        {
                            dailySummary: {
                                reservationsCount:finesseSummary.reservationsCount, bookingsCount:finesseSummary.bookingsCount, reservationsPayment: (finesseSummary.sumOfReservations),
                                eventsPayment: finesseSummary.sumOfEvents, totalExpenditure: finesseSummary.sumOfExpenditure, eventsCount: finesseSummary.hotelEventsCount, bookingsPayment: finesseSummary.sumOfBookings,
                                expectedReservations:finesseSummary.sumOfExpectedReservations,expectedBookings:finesseSummary.sumOfExpectedBookings,expectedEvents:finesseSummary.sumOfExpectedEvents
                            }
                            ,staffName:staff.staffName!
                        }
                    )
                }

                return staffSummary;
            });
        } catch (error) {
            throw error;
        }
    }
    async getLocalSummaryLocations(hotelId: number, startDate: string, endDate: string){
        const start = new Date(startDate);
        const end = new Date(endDate);
        try {
            return db.transaction('rw', [db.hotelReservations, db.hotelEvents, db.expenses, db.payments,db.hotelLocation], async () => {
                const staffSummary:TDailySummary[]=[];
                const locations=await db.hotelLocation.where({hotelId}).toArray();
                for(const hotelLocation of locations){
                    const localSummary=await this.getLocalSummaryBasic(hotelLocation.hotelLocationId!,start,end);

                    const bookings = localSummary.bookings.filter(booking=>booking.hotelLocationId===hotelLocation.hotelLocationId);

                    const reservations = localSummary.reservations.filter(reservation=>reservation.hotelLocationId===hotelLocation.hotelLocationId);

                    const hotelEvents = localSummary.hotelEvents.filter(hotelEvent=>hotelEvent.hotelLocationId===hotelLocation.hotelLocationId);

                    const expenditures = localSummary.expenditures.filter(expenditure=>expenditure.locationId===hotelLocation.hotelLocationId);

                    const payments = localSummary.payments.filter(payment=>payment.hotelLocationId===hotelLocation.hotelLocationId);

                    const finesseSummary=await this.finesseLocalSummary(bookings,reservations,hotelEvents,payments,expenditures);
                    staffSummary.push(
                        {
                            dailySummary: {
                                reservationsCount:finesseSummary.reservationsCount, bookingsCount:finesseSummary.bookingsCount, reservationsPayment: (finesseSummary.sumOfReservations),
                                eventsPayment: finesseSummary.sumOfEvents, totalExpenditure: finesseSummary.sumOfExpenditure, eventsCount: finesseSummary.hotelEventsCount, bookingsPayment: finesseSummary.sumOfBookings,
                                expectedReservations:finesseSummary.sumOfExpectedReservations,expectedBookings:finesseSummary.sumOfExpectedBookings,expectedEvents:finesseSummary.sumOfExpectedEvents
                            }
                            ,locationName:hotelLocation.locationName!
                        }
                    )
                }

                return staffSummary;
            });
        } catch (error) {
            throw error;
        }
    }
    async getLocalSummaryBasic(hotelLocationId: number, start: Date, end: Date){
        const bookings=await db.hotelReservations.where({ hotelLocationId: hotelLocationId, bookingType: 'Booking' })
            .and(booking => {
                const bookingDate = new Date(changeDateFormat(new Date(booking.dateCreated!)));
                return (
                    (isEqual(bookingDate, start) || isAfter(bookingDate, start)) && (isEqual(bookingDate, end) || isBefore(bookingDate, end))
                );
            }).toArray();
        const reservations = await db.hotelReservations.where({ hotelLocationId: hotelLocationId, bookingType: 'Reservation' })
            .and(reservation => {
                const bookingDate = new Date(changeDateFormat(new Date(reservation.dateCreated!)));
                return (
                    (isEqual(bookingDate, start) || isAfter(bookingDate, start)) && (isEqual(bookingDate, end) || isBefore(bookingDate, end))
                );
            }).toArray();

        const hotelEvents = await db.hotelEvents.where({ hotelLocationId: hotelLocationId })
            .and(hotelEvent => {
                const eventDate = new Date(changeDateFormat(new Date(hotelEvent.dateCreated!)));
                return (
                    (isEqual(eventDate, start) || isAfter(eventDate, start)) && (isEqual(eventDate, end) || isBefore(eventDate, end))
                );
            }).toArray();

        const expenditures = await db.expenses.where({ locationId: hotelLocationId })
            .and(expenditure => {
                const expenditureDate = new Date(changeDateFormat(new Date(expenditure.expenditureDate!)));
                return (
                    (isEqual(expenditureDate, start) || isAfter(expenditureDate, start)) && (isEqual(expenditureDate, end) || isBefore(expenditureDate, end))
                );
            }).toArray();
        const payments = await db.payments.where({ hotelLocationId: hotelLocationId }).and(payment => {
            const paymentDate = new Date(format(payment.paymentDate,'yyyy-MM-dd'));
            return (
                (isEqual(paymentDate, start) || isAfter(paymentDate, start)) && (isEqual(paymentDate, end) || isBefore(paymentDate, end))
            );
        }).toArray();
        return {bookings,reservations,hotelEvents,expenditures,payments}
    }
    async getDashBoardMeta(hotelLocationId: number) {
        const data = await axiosFetch<TDashboardMeta>('GET', `${getBaseURL()}/general/get_dash_board_meta?hotelLocationId=${hotelLocationId}`, {});
        return { status: data.status, operatedData: data.data?.operatedData };
    }
    async getLocalDashboardMeta(hotelLocationId: number) {
        const currentDate = new Date();
        const startOfCurrentYear = startOfYear(currentDate);
        const endOfCurrentYear = endOfYear(currentDate);
        try {
            return db.transaction('rw', [db.hotelReservations, db.expenses, db.payments, db.hotelRooms], async () => {
                const bookingsReservations = await db.hotelReservations.where({ hotelLocationId }).and(booking => isWithinInterval(booking.dateCreated!, { start: startOfCurrentYear, end: endOfCurrentYear })).toArray();

                const payments = await db.payments.where({ hotelLocationId }).and(payment => isWithinInterval(payment.paymentDate, { start: startOfCurrentYear, end: endOfCurrentYear })).toArray();

                const expenditures = await db.expenses.where({ locationId: hotelLocationId }).and(expense => isWithinInterval(expense.expenditureDate, { start: startOfCurrentYear, end: endOfCurrentYear })).toArray();

                const rooms = await db.hotelRooms.where({ locationId: hotelLocationId }).toArray();

                const roomBookedCounts = _.countBy(bookingsReservations, 'roomId');

                const bookedRooms = rooms.map((room) => {
                    return { roomId: room.roomId, roomNumber: room.roomNumber, bookedCount: roomBookedCounts[room.roomId!]!==undefined?roomBookedCounts[room.roomId!]:0 };
                });

                const incomingReservations = await db.hotelReservations.where({ hotelLocationId }).and((reservation) => isAfter(new Date(reservation.checkInDate!), new Date())).toArray();

                return {
                    operatedData: {
                        bookingsReservations, payments: payments.map((payment) => {
                            return { ...payment, dateCreated: payment.paymentDate };
                        }), expenditures, roomsBooking: bookedRooms, incomingReservations: incomingReservations
                    }
                };
            });
        } catch (error) {
            throw error;
        }
    }
    async getCompaniesPayable(hotelLocationId:number,startDate: string, endDate: string){
        const start = new Date(startDate);
        const end = new Date(endDate);
        const reservations=await db.hotelReservations.where({hotelLocationId}).and(reservation=>{
            const bookingDate = new Date(changeDateFormat(new Date(reservation.dateCreated!)));
            return (
                (isEqual(bookingDate, start) || isAfter(bookingDate, start)) && (isEqual(bookingDate, end) || isBefore(bookingDate, end))
            );
        }).toArray();
        const guestCompanies= reservations.filter(reservation=>reservation.financedBy==='Company').map(reservation=>reservation.guestCompany);
        const identifiedCompanies=new Set(guestCompanies);
        const companyAndPayments:TCompanyAndPayments[]=[];
        for(const company of identifiedCompanies){
            //get company reservation and payment for that reservation;
            const companyReservations=reservations.filter(reservation=>reservation.guestCompany===company);

            for(const reservation of companyReservations){
                const reservationsPayments=await db.payments.where({activityId:reservation.reservationId}).toArray();

                const totalBookingReservationCost=companyReservations.reduce((previousValue,currentValue:TReservations)=>{
                    return previousValue + (currentValue.numberOfDays!*currentValue.roomRate!);
                },0);
                const totalPayments=reservationsPayments.reduce((previousValue,currentValue:TPayment)=>{
                    return previousValue+(currentValue.amount-currentValue.paymentChange);
                },0);
                companyAndPayments.push({ companyReservationsCost: totalBookingReservationCost, reservationPayments: totalPayments, companyName:company});
            }
        }
        return companyAndPayments;
    }
}

export default ReportActions;
