import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Dialog } from 'primereact/dialog';
import { useReportContext } from '../contextProviders/ReportContext';
import { Button } from 'primereact/button';
import { DatePicker, FilterSelect, GeneralPageProps } from '../../utilities/components/UtilityComponents';
import { changeDateFormat, isAppOnline, pageDataValidation, remakeDropdownSelects, straightLine } from '../../utilities/utilFunctions';
import { CalendarChangeEvent } from 'primereact/calendar';
import { availableRoomsListQuery, bookedRoomsListQuery, getCompaniesPayable, getDailySummaryQuery, getHotelLocationsSummary, getHotelsSummary, getReceptionistSummaryQuery, hotelLocationsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import HotelLocation from '../../utilities/classObjects/HotelLocation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { TDailySummary, TDailySummaryProps } from '../../utilities/types';
import ReportActions from '../../utilities/classObjects/ReportActions';
import React, { useRef } from 'react';
import HotelRoomClass from '../../utilities/classObjects/HotelRoom';
import BookedRoomsList from '../DataTables/BookedRoomsList';
import AvailableRoomsList from '../DataTables/AvailableRoomsList';
import { validateReportFetch } from '../../utilities/joiFunctions/joiFunctions';

pdfmake.vfs = pdfFonts.vfs;
const locationObject = new HotelLocation();
const report = new ReportActions();
const hotelRoomObject = new HotelRoomClass();

export const DailySummaryDialog = () => {
    const queryClient = useQueryClient();
    const { state, setStateFunction } = useReportContext();
    const toastRef = useRef(null);

    const getActivitiesSummary = async (dateFirst: string, dateSecond: string) => {
        setStateFunction!({ isLoading: true });
        const data = isAppOnline(state?.appSettings.connectivityState!) ? await getDailySummaryQuery(queryClient, dateFirst, dateSecond, state?.hotelLocationId!)
            : await report.getLocalSummary(state?.hotelLocationId!, state?.beginDate!, state?.endDate!);
        setStateFunction!({ isLoading: false });
        return data;
    };
    const dailySummaryReport = async () => {
        try{
            if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
            setStateFunction!({isLoading:true});
            const record = await getActivitiesSummary(state?.beginDate!, state?.endDate!);
            const dailySummary: TDailySummaryProps = typeof record![0].dailySummary === 'string' ? JSON.parse(record![0].dailySummary) : record![0].dailySummary;

            const documentDefinitions: any = {
                info: {
                    title: `Daily Summary`,
                    author: 'Harmony Hotel'
                },

                content: [
                    textDisplayComponent({ titleText: `ACTIVITIES SUMMARY FROM (${state?.beginDate} TO ${state?.endDate})`, bold: true, textDecoration: 'underline' }),
                    documentSummary(dailySummary)
                ]
            };
            pdfmake.createPdf(documentDefinitions).open();
        }catch(error){
            console.log(error);
        }finally {
            setStateFunction!({ isLoading: false });
        }
    };
    const receptionistSummaryReport = async () => {
        try {
            if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
            setStateFunction!({ isLoading: true });
            const data = isAppOnline(state?.appSettings.connectivityState!) ? await getReceptionistSummaryQuery(queryClient, state?.beginDate!, state?.endDate!, state?.hotelLocationId!)
                : await report.getLocalSummaryReceptionists(state?.hotelLocationId!, state?.beginDate!, state?.endDate!);
            const documentDefinitions: any = {
                info: {
                    title: `RECEPTIONISTS Summary`,
                    author: 'Harmony Hotel'
                },
                content: [
                    textDisplayComponent({ titleText: `RECEPTIONISTS SUMMARY FROM (${state?.beginDate} TO ${state?.endDate})`, bold: true, textDecoration: 'underline' }),
                    data?.map((summaryData: TDailySummary) => {
                        const dailySummary: TDailySummaryProps = typeof summaryData.dailySummary === 'string' ? JSON.parse(summaryData.dailySummary) : summaryData.dailySummary;
                        return [
                            textDisplayComponent({ titleText: summaryData.staffName!.toUpperCase(), bold: true, textDecoration: 'underline' }),
                            documentSummary(dailySummary)
                        ];
                    })]
            };
            pdfmake.createPdf(documentDefinitions).open();
        } catch (error) {
            throw error;
        } finally {
            setStateFunction!({ isLoading: false });
        }
    };
    const locationsSummaryReport = async () => {
        try {
            if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
            setStateFunction!({ isLoading: true });
            const data = isAppOnline(state?.appSettings.connectivityState!) ? await getHotelLocationsSummary(queryClient, state?.beginDate!, state?.endDate!, state?.hotelId!)
                : await report.getLocalSummaryLocations(state?.hotelId!, state?.beginDate!, state?.endDate!);
            const documentDefinitions: any = {
                info: {
                    title: `Locations Summary`,
                    author: 'Harmony Hotel'
                },
                content: [
                    textDisplayComponent({ titleText: `LOCATIONS SUMMARY FROM (${state?.beginDate} TO ${state?.endDate})`, bold: true, textDecoration: 'underline' }),
                    data?.map((summaryData: TDailySummary) => {
                        const dailySummary: TDailySummaryProps = typeof summaryData.dailySummary === 'string' ? JSON.parse(summaryData.dailySummary) : summaryData.dailySummary;
                        return [
                            textDisplayComponent({ titleText: summaryData.locationName!.toUpperCase(), bold: true, textDecoration: 'underline' }),
                            documentSummary(dailySummary)
                        ];
                    })
                ]
            };
            pdfmake.createPdf(documentDefinitions).open();
        } catch (error) {
            throw error;
        } finally {
            setStateFunction!({ isLoading: false });
        }
    };

    const companySummaryReport = async () => {
        try {
            if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
            setStateFunction!({ isLoading: true });
            const data = isAppOnline(state?.appSettings.connectivityState!) ? await getHotelsSummary(queryClient, state?.beginDate!, state?.endDate!, state?.hotelId!)
                : await report.getLocalSummaryLocations(state?.hotelLocationId!, state?.beginDate!, state?.endDate!);

            const documentDefinitions: any = {
                info: {
                    title: `Hotel Summary`,
                    author: 'Harmony Hotel'
                },
                content: [
                    textDisplayComponent({ titleText: `HOTEL SUMMARY FROM (${state?.beginDate} TO ${state?.endDate})`, bold: true, textDecoration: 'underline' }),
                    data?.map((summaryData: TDailySummary) => {
                        const dailySummary: TDailySummaryProps = typeof summaryData.dailySummary === 'string' ? JSON.parse(summaryData.dailySummary) : summaryData.dailySummary;
                        return [
                            textDisplayComponent({ titleText: state!.hotelName.toUpperCase(), bold: true, textDecoration: 'underline' }),
                            documentSummary(dailySummary)
                        ];
                    })
                ]
            };
            pdfmake.createPdf(documentDefinitions).open();
        } catch (error) {
            throw error;
        } finally {
            setStateFunction!({ isLoading: false });
        }
    };

    const companiesPayable = async () => {
        try{
            if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
            setStateFunction!({isLoading:true});
            const results = isAppOnline(state?.appSettings.connectivityState!)?await getCompaniesPayable(queryClient,state?.hotelLocationId!,state?.beginDate!,state?.endDate!):await report.getCompaniesPayable(state?.hotelLocationId!,state?.beginDate!,state?.endDate!);
            let totalCompaniesOwing=0;
            const documentDefinitions: any = {
                info: {
                    title: `Hotel Summary`,
                    author: 'Harmony Hotel'
                },
                content: [
                    textDisplayComponent({ titleText: `COMPANIES BOOKING SUMMARY FROM (${state?.beginDate} TO ${state?.endDate})`, bold: true, textDecoration: 'underline',alignment:'center' }),
                    {
                        columns:[
                            textDisplayComponent({titleText:'Company',bold:true,marginBottom:0}),
                            textDisplayComponent({titleText:'Booking Cost',bold:true,marginBottom:0}),
                            textDisplayComponent({titleText:'Payments Total',bold:true,marginBottom:0}),
                            textDisplayComponent({titleText:'Payable',bold:true,marginBottom:0}),
                        ]
                    },
                    straightLine(),
                    results?.map((companyAndPayments) => {
                        const payable=(companyAndPayments.reservationPayments!-companyAndPayments.companyReservationsCost);
                        totalCompaniesOwing+=payable;
                        return [
                            {
                                columns:[
                                    textDisplayComponent({ titleText: companyAndPayments.companyName!,bold:false }),
                                    textDisplayComponent({ titleText: companyAndPayments.companyReservationsCost.toFixed(2)!,bold:false }),
                                    textDisplayComponent({ titleText: companyAndPayments.reservationPayments.toFixed(2)!,bold:false }),
                                    textDisplayComponent({ titleText: payable >0?payable.toFixed(2):`(${Math.abs(payable).toFixed(2)})`,bold:false })
                                ]
                            },
                            straightLine()
                        ]
                    }),
                    textDisplayComponent({titleText:`Total Receivable : ${Math.abs(totalCompaniesOwing)}`,bold:true,marginBottom:10}),
                ]
            };
            pdfmake.createPdf(documentDefinitions).open();
        }catch(error:any){

        }finally {
            setStateFunction!({ isLoading: false });
        }
    };
    return (
        <>
            <GeneralPageProps toastRef={toastRef} />
            <Dialog onHide={() => setStateFunction!({ showDailySummaryDialog: false })} visible={state?.showDailySummaryDialog} position="top" className="w-5">
                <ReportDates />
                <HotelAndLocationSelect />
                <Button label={state?.actionCaller === 'LocationSummary' ? 'Open This Location Summary' : state?.actionCaller === 'ReceptionistSummary' ? 'Open Receptionists Summary' : state?.actionCaller === 'LocationsSummary' ? 'Open Locations Summary' :state?.actionCaller==='CompanySummary'? 'Open Company Summary':'Open Guest Companies Payable'} icon="pi pi-money-bill"
                        onClick={state?.actionCaller === 'LocationSummary' ? dailySummaryReport : state?.actionCaller === 'ReceptionistSummary' ? receptionistSummaryReport : state?.actionCaller === 'LocationsSummary' ? locationsSummaryReport :state?.actionCaller==='CompanySummary'? companySummaryReport:companiesPayable} />
            </Dialog>
        </>
    );
};

export const BookedReservedRoomsDialog = () => {
    const { state, setStateFunction } = useReportContext();
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const onBookedRoomShow = async () => {
        const reportingDate = state?.reportingDate! as Date[];
        const dateFirst = changeDateFormat(new Date(reportingDate[0]));
        const dateSecond = changeDateFormat(new Date(reportingDate[1]));
        try {
            setStateFunction!({ isLoading: true });
            const bookedRooms = isAppOnline(state?.appSettings.connectivityState!) ? await bookedRoomsListQuery(queryClient, state?.reportBookingType!, dateFirst, dateSecond, state?.hotelLocationId!)
                : await hotelRoomObject.getOfflineBookedRooms(state?.hotelLocationId!, dateFirst, dateSecond, state?.reportBookingType!);
            setStateFunction!({
                bookedRooms: bookedRooms?.map((bookedRoom) => {
                    return { ...bookedRoom, checkInDate: changeDateFormat(new Date(bookedRoom.checkInDate!)), checkOutDate: changeDateFormat(new Date(bookedRoom.checkOutDate!)) };
                })
            });
        } catch (error) {
            throw error;
        } finally {
            setStateFunction!({ isLoading: false });
        }
    };
    const showBookedRooms = () => {
        if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
        setStateFunction!({ showBookedRoomsList: true });
    };
    return (
        <>
            <GeneralPageProps toastRef={toastRef} />
            <Dialog onHide={() => setStateFunction!({ showBookedReservedRoomsDialog: false })} visible={state?.showBookedReservedRoomsDialog} position="top" className="w-5">
                <ReportDates />
                <HotelAndLocationSelect />
                <Button label="Open Booked Rooms" icon="pi pi-book" onClick={showBookedRooms} />
                <Dialog onHide={() => setStateFunction!({ showBookedRoomsList: false })} visible={state?.showBookedRoomsList} className="w-7" position="top" onShow={onBookedRoomShow}>
                    <BookedRoomsList tableData={state?.bookedRooms!} key="bkRooms" tableTitle={state?.reportBookingType === 'Booking' ? 'Booked Rooms' : 'Reserved Rooms'} />
                </Dialog>
            </Dialog>
        </>
    );
};
export const AvailableRoomsDialog = () => {
    const { state, setStateFunction } = useReportContext();
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const onAvailableRoomsShow = async () => {
        try {
            setStateFunction!({ isLoading: true });
            const availableRooms = isAppOnline(state?.appSettings.connectivityState!) ? await availableRoomsListQuery(queryClient, state?.beginDate!, state?.endDate!, state?.hotelLocationId!)
                : await hotelRoomObject.getOfflineAvailableRooms(state?.hotelLocationId!, state?.beginDate!, state?.endDate!);
            setStateFunction!({ availableRooms });
        } catch (error) {
            throw error;
        } finally {
            setStateFunction!({ isLoading: false });
        }
    };
    const openAvailableRoomsDialog = () => {
        if (!pageDataValidation(validateReportFetch, { startDate: state?.beginDate, endDate: state?.endDate, hotelLocationId: state?.hotelLocationId }, toastRef)) return;
        setStateFunction!({ showAvailableRoomsList: true });
    };
    return (
        <>
            <GeneralPageProps toastRef={toastRef} />
            <Dialog onHide={() => setStateFunction!({ showAvailableRoomsDialog: false })} visible={state?.showAvailableRoomsDialog} position="top" className="w-5">
                <ReportDates />
                <HotelAndLocationSelect />
                <Button label="Open Available Rooms" icon="pi pi-book" onClick={openAvailableRoomsDialog} />
                <Dialog onHide={() => setStateFunction!({ showAvailableRoomsList: false })} visible={state?.showAvailableRoomsList} position="top" onShow={onAvailableRoomsShow} className="w-7">
                    <AvailableRoomsList tableData={state?.availableRooms!} />
                </Dialog>
            </Dialog>
        </>
    );
};
export const HotelAndLocationSelect = () => {
    const { state, setStateFunction } = useReportContext();
    const queryClient = useQueryClient();
    const onHotelChange = async (e: any) => {
        setStateFunction!({ selectedHotel: e.value });
        const hotelLocations = isAppOnline(state?.appSettings.connectivityState!) ? await hotelLocationsListQuery(queryClient, e.value.code) : await locationObject.getOfflineLocations(e.value.code);
        setStateFunction!({ hotelLocations: remakeDropdownSelects(hotelLocations!, 'locationName', 'hotelLocationId'), hotelId: e.value.code });
    };
    const onHotelLocationChange = (e: DropdownChangeEvent) => {
        setStateFunction!({ selectedHotelLocation: e.value, hotelLocationId: e.value.code });
    };
    return <>
        <div className="grid p-fluid">
            <div className="col-6">
                <FilterSelect selectableOptions={state?.hotels!} onSelectChange={onHotelChange} selectedOption={state?.selectedHotel} elementId="hotels" defaultValue="Select Hotel" />
            </div>
            <div className="col-6">
                <FilterSelect selectableOptions={state?.hotelLocations!} onSelectChange={onHotelLocationChange} selectedOption={state?.selectedHotelLocation} elementId="hotelLocation" defaultValue="Select Location" />
            </div>
        </div>
    </>;
};
export const ReportDates = () => {
    const { state, setStateFunction } = useReportContext();
    const onReportDatesChange = (e: CalendarChangeEvent) => {
        const dates = e.value as Date[];
        const beginDate = changeDateFormat(dates[0]);
        const endDate = dates[1] == null ? changeDateFormat(new Date()) : changeDateFormat(dates[1]);
        setStateFunction!({ reportingDate: e.value as Date, beginDate, endDate });
    };
    return (
        <>
            {`${state?.beginDate} ${state?.endDate}`}
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="field">
                        <label htmlFor="reportDate">Report Date</label>
                        <DatePicker dateValue={state?.reportingDate! as Date}
                                    onDateChange={onReportDatesChange} labelText="Reporting Date" controlId="reportingDate"
                                    selectionType="range" showCalendarAsInline={true} />
                    </div>
                </div>
            </div>
        </>
    );
};
const textDisplayComponent = ({ titleText, fontSize = 12, bold = true, marginBottom = 10, marginTop = 10, textDecoration,alignment="left" }:
                                  { titleText: string, fontSize?: number, bold?: boolean, marginTop?: number, marginBottom?: number, textDecoration?: string,alignment?:string }) => {
    return {
        text: titleText,
        fontSize: fontSize,
        bold: bold,
        marginTop: marginTop,
        marginBottom: marginBottom,
        decoration: textDecoration,
        alignment:alignment
    };
};
const documentSummary = (dailySummary: TDailySummaryProps) => {
    const totalEarned = parseFloat(dailySummary.reservationsPayment as string) + parseFloat(dailySummary.eventsPayment as string) + parseFloat(dailySummary.bookingsPayment as string);
    const profitLoss = totalEarned - parseFloat(dailySummary.totalExpenditure as string);
    return [
        straightLine(),
        {
            columns: [
                textDisplayComponent({ titleText: `No. Of Bookings: ${dailySummary.bookingsCount}`, bold: true }),
                textDisplayComponent({ titleText: `No. Of Reservations: ${dailySummary.reservationsCount}`, bold: true }),
                textDisplayComponent({ titleText: `No. of Events: ${dailySummary.eventsCount}`, bold: true })
            ]
        },
        straightLine(),
        textDisplayComponent({ titleText: `Expected Amounts`, bold: true, textDecoration: 'underline' }),
        {
            columns: [
                textDisplayComponent({ titleText: `Reservation: ${dailySummary.expectedReservations}`, bold: true }),
                textDisplayComponent({ titleText: `Bookings: ${dailySummary.expectedBookings}`, bold: true }),
                textDisplayComponent({ titleText: `Hotel Events: ${dailySummary.expectedEvents} `, bold: true }),
                textDisplayComponent({ titleText: `Total: ${parseFloat(dailySummary.expectedReservations as string) + parseFloat(dailySummary.expectedBookings as string) + parseFloat(dailySummary.expectedEvents as string)} `, bold: true })
            ]
        },
        straightLine(),
        textDisplayComponent({ titleText: `Receipts`, bold: true, textDecoration: 'underline' }),
        {
            columns: [
                textDisplayComponent({ titleText: `Reservation: ${dailySummary.reservationsPayment}`, bold: true }),
                textDisplayComponent({ titleText: `Bookings: ${dailySummary.bookingsPayment}`, bold: true }),
                textDisplayComponent({ titleText: `Hotel Events: ${dailySummary.eventsPayment} `, bold: true }),
                textDisplayComponent({ titleText: `Payments Sum: ${totalEarned} `, bold: true })
            ]
        },
        straightLine(),
        textDisplayComponent({ titleText: `Expenditures`, bold: true, textDecoration: 'underline' }),
        {
            columns: [
                textDisplayComponent({ titleText: `Expenses: ${dailySummary.totalExpenditure}`, bold: true })
            ]
        },
        straightLine(),
        textDisplayComponent({ titleText: `Summary`, bold: true, textDecoration: 'underline' }),
        textDisplayComponent({ titleText: `${totalEarned > parseFloat(dailySummary.totalExpenditure as string) ? 'Excess of Income/Expenditure' : 'Excess of Expenditure/Income'} : ${profitLoss < 0 ? `(${Math.abs(profitLoss)})` : profitLoss}`, bold: true })
    ];
};
