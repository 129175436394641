import { Nullable } from 'primereact/ts-helpers';
import { Calendar } from 'primereact/calendar';
import { DropdownOption, GeneralPagePropsProps, TCheckBoxProps, TDiscountComponentProps, THotelEvents, TIconTextInput, TNumberInputWithButtons, TRadioButtonProps, TReservations } from '../types';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import React, { SVGProps } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { FilterSelectProps } from '../types';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { changeDateFormat, onInputControlFocus } from '../utilFunctions';

export interface DatePickerProps {
    dateValue: Nullable<Date>;
    // @ts-ignore
    onDateChange: (event: FormEvent<(Date | null)[], SyntheticEvent<Element, Event>>) => void;
    labelText: string;
    controlId: string;
    dateFormat?: string;
    displayTime?: boolean;
    selectionType: 'single'|'multiple'|'range';
    displayButtonBar?: boolean;
    minimumDateValue?: Date;
    maximumDateValue?: Date;
    view?: 'month' | 'year' | 'date';
    showCalendarAsInline?: boolean;
    onClearDates?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    pageTabIndex?: number;
}

export const DatePicker: React.FC<DatePickerProps> = ({
                                                          dateValue,
                                                          onDateChange,
                                                          labelText,
                                                          controlId,
                                                          dateFormat = 'yy-mm-dd',
                                                          displayTime = false,
                                                          selectionType = 'single',
                                                          displayButtonBar = false,
                                                          minimumDateValue,
                                                          maximumDateValue,
                                                          view = 'date',
                                                          showCalendarAsInline = false,
                                                          pageTabIndex,
                                                          onClearDates = () => {}
                                                      }) => {
    return (
        <Calendar
            name={controlId}
            id={controlId}
            placeholder={labelText}
            className="expanded-control mr-3"
            value={dateValue}
            onChange={onDateChange}
            dateFormat={dateFormat}
            selectionMode={selectionType}
            showButtonBar={displayButtonBar}
            showTime={displayTime}
            minDate={minimumDateValue}
            maxDate={maximumDateValue}
            view={view}
            inline={showCalendarAsInline}
            autoZIndex={true}
            showIcon={true}
            onClearButtonClick={onClearDates}
            tabIndex={pageTabIndex}
        />
    );
};
export const GeneralPageProps: React.FC<GeneralPagePropsProps> = ({ toastRef, toastPosition = 'bottom-right' }) => {
    return (
        <>
            <Toast ref={toastRef} position={toastPosition} />
            <ConfirmPopup />
        </>
    );
};

export const tableEditOption = (editClick: (e: React.MouseEvent<HTMLButtonElement>) => void, deleteClick: (e: React.MouseEvent<HTMLButtonElement>) => void, idProperty = '', recordStatus?: 'online' | 'offline') => {
    return (
        <>
            <Button id={idProperty} icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={editClick} />
            <Button icon="pi pi-trash" name={idProperty} className="p-button-rounded p-button-warning" onClick={deleteClick} />
        </>
    );
};
export const Loader = () => {
    return (
        <div className="loader-container" style={{ zIndex: 99999999 }}>
            <ProgressSpinner strokeWidth="4" animationDuration="1.0s" />
        </div>
    );
};

export const CheckboxInput = ({ checkLabel, checkedState, onCheckChange, inputId, inputName, disabled = false, classValues }: TCheckBoxProps) => {
    return (
        <div className="field grid">
            <div className="col-12 md:col-12">
                <div className="field-checkbox">
                    <Checkbox id={inputId} name={inputName} value={checkLabel} checked={checkedState} onChange={onCheckChange} disabled={disabled} />
                    <label htmlFor={inputId} className={classValues}>
                        {checkLabel}
                    </label>
                </div>
            </div>
        </div>
    );
};

export const RadioButtonInput = ({ inputId, inputName, checkedValue, onRadioChange, radioLabel, customClasses }: TRadioButtonProps) => {
    return (
        <div className="flex align-items-center">
            <RadioButton id={inputId} className={customClasses} name={inputName} value={radioLabel} onChange={onRadioChange} checked={checkedValue} />
            <label htmlFor={inputId} className="ml-2">
                {radioLabel}
            </label>
        </div>
    );
};
export const WifiOff = (props: SVGProps<SVGSVGElement>)=> {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256" {...props}><path fill="currentColor" d="M213.92 210.62a8 8 0 1 1-11.84 10.76l-52-57.15a60 60 0 0 0-57.41 7.24a8 8 0 1 1-9.42-12.93A75.43 75.43 0 0 1 128 144c1.28 0 2.55 0 3.82.1l-26.92-29.61A108 108 0 0 0 61 135.31A8 8 0 0 1 49.73 134A8 8 0 0 1 51 122.77a124.3 124.3 0 0 1 41.71-21.66L69.37 75.4a155.4 155.4 0 0 0-40.29 24A8 8 0 0 1 18.92 87A172 172 0 0 1 58 62.86L42.08 45.38a8 8 0 1 1 11.84-10.76ZM128 192a12 12 0 1 0 12 12a12 12 0 0 0-12-12M237.08 87A172.3 172.3 0 0 0 106 49.4a8 8 0 1 0 2 15.87A158 158 0 0 1 128 64a156.25 156.25 0 0 1 98.92 35.37A8 8 0 0 0 237.08 87M195 135.31a8 8 0 0 0 11.24-1.3a8 8 0 0 0-1.3-11.24a124.25 124.25 0 0 0-51.73-24.2a8 8 0 1 0-3.21 15.67a108.1 108.1 0 0 1 45 21.07"></path></svg>);
}

export const WifiOn=(props: SVGProps<SVGSVGElement>)=> {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} color="currentColor"><path d="M12 18.5h.012m-3.762-3c2-2 5.5-2 7.5 0m2.75-3c-3.768-3.333-9-3.333-13 0"></path><path d="M2 9.5c6.316-5.333 13.684-5.333 20 0"></path></g></svg>);
}
type TBigButtonProps = {
    buttonAction:(e:React.MouseEvent<HTMLButtonElement>)=>void;
    buttonTitle:string;
}
export const BigButton=({buttonTitle,buttonAction}:TBigButtonProps)=>{
    return (
        <button aria-label="New Entry" className="p-button p-component p-button-outlined h-4rem mr-2 w-full" onClick={buttonAction}>
            <span className="p-button-label p-c">{buttonTitle}</span>
            <span role="presentation" className="p-ink"></span>
        </button>
    )
}

export const FilterSelect: React.FC<FilterSelectProps> = ({ selectableOptions = [], selectedOption = '', onSelectChange, customClasses = '', elementId = '', defaultValue = '', showClearIcon = false, showLabel = true, pageTabIndex }) => {
    const selectedValueTemplate = (option: DropdownOption) => {
        if (option) {
            return (
                <div className="item-value flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>Select</span>;
    };

    const optionsTemplate = (option: { name: string }) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className={`filter-select ${customClasses}`}>
            {showLabel ? (
                <div className="mb-2">
                    <label htmlFor="dateTo">{defaultValue}</label>
                </div>
            ) : (
                ''
            )}
            <Dropdown
                value={selectedOption}
                options={selectableOptions}
                onChange={onSelectChange}
                optionLabel="name"
                filter
                filterBy="name"
                placeholder={defaultValue}
                id={elementId}
                defaultValue={defaultValue}
                valueTemplate={selectedValueTemplate}
                itemTemplate={optionsTemplate}
                name={elementId}
                className="w-full"
                showClear={showClearIcon}
                tabIndex={pageTabIndex}
            />
        </div>
    );
};
export const ReservationMoreDetails=({panelInfo}:{panelInfo:TReservations})=>{

    return (
        <>
            <div className="card">
                <Splitter className="h-10rem">
                    <SplitterPanel className="pl-3 pt-2">
                        <h5>Personal Data:</h5>
                        <div className="grid">
                            <div className="col-4">
                                <label>Guest Name</label> : <span className="font-bold">{panelInfo.guestName}</span>
                            </div>
                            <div className="col-4">
                                <label>Check In </label> : <span className="font-bold">{changeDateFormat(new Date(panelInfo.checkInDate!))}</span>
                            </div>
                            <div className="col-4">
                                <label>Check Out</label> : <span className="font-bold">{changeDateFormat(new Date(panelInfo.checkOutDate!))}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col-4">
                                <label>Number of Days</label> : <span className="font-bold">{`${panelInfo.numberOfDays} (${panelInfo.roomRate})`}</span>
                            </div>
                            <div className="col-4">
                                <label>Adults </label> : <span className="font-bold">{panelInfo.numberOfAdults}</span>
                            </div>
                            <div className="col-4">
                                <label>Children</label> : <span className="font-bold">{panelInfo.numberOfChildren}</span>
                            </div>
                        </div>
                    </SplitterPanel>
                    <SplitterPanel className="pl-3 pt-2">
                        <h5>Other Data:</h5>
                        <div className="grid">
                            <div className="col-4">
                                <label>Room</label> : <span className="font-bold">{panelInfo.roomNumber}</span>
                            </div>
                            <div className="col-4">
                                <label>Type</label> : <span className="font-bold">{panelInfo.bookingType}</span>
                            </div>
                            <div className="col-4">
                                <label>Purpose</label> : <span className="font-bold">{panelInfo.purposeOfVisit}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col-4">
                                <label>Room Type</label> : <span className="font-bold">{panelInfo.roomTypeDesc}</span>
                            </div>
                            <div className="col-4">
                                <label>Status </label> : <span className="font-bold">{panelInfo.status}</span>
                            </div>
                            <div className="col-4">
                                <label>Cost</label> : <span className="font-bold">{panelInfo?.roomRate! * panelInfo.numberOfDays!}</span>
                            </div>
                        </div>
                    </SplitterPanel>
                </Splitter>
            </div>
        </>
    )
}
export const EventMoreDetails=({panelInfo}:{panelInfo:THotelEvents})=>{

    return (
        <>
            <div className="card">
                <Splitter className="h-10rem">
                    <SplitterPanel className="pl-3 pt-2">
                        <h5>Event Data:</h5>
                        <div className="grid">
                            <div className="col-4">
                                <label>Event Host</label> : <span className="font-bold">{panelInfo.eventHost}</span>
                            </div>
                            <div className="col-4">
                                <label>Start Date </label> : <span className="font-bold">{changeDateFormat(new Date(panelInfo.eventStartDate!))}</span>
                            </div>
                            <div className="col-4">
                                <label>End Date</label> : <span className="font-bold">{changeDateFormat(new Date(panelInfo.eventEndDate!))}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col-4">
                                <label>Event Cost</label> : <span className="font-bold">{`${panelInfo.eventCost}`}</span>
                            </div>
                            <div className="col-4">
                                <label>Type </label> : <span className="font-bold">{panelInfo.eventTypeDesc}</span>
                            </div>
                            <div className="col-4">
                                {/*<label>Children</label> : <span className="font-bold">{panelInfo.numberOfChildren}</span>*/}
                            </div>
                        </div>
                    </SplitterPanel>
                </Splitter>
            </div>
        </>
    )
}
export const NumberInputWithButtons = ({ inputValue, inputButtonsClick, numberInputName, numberInputId, disableState = false, allowDecimalValues = true,inputValueChange }: TNumberInputWithButtons) => {
    return (
        <InputNumber
            inputId="horizontal-buttons"
            id={numberInputId}
            name={numberInputName}
            value={inputValue}
            onValueChange={inputButtonsClick}
            onChange={inputValueChange}
            showButtons
            buttonLayout="horizontal"
            step={1}
            decrementButtonClassName="p-button-danger"
            incrementButtonClassName="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            mode="decimal"
            min={0}
            disabled={disableState}
            minFractionDigits={0}
            maxFractionDigits={allowDecimalValues ? 2 : undefined}
            onBlur={onInputControlFocus}
            onFocus={onInputControlFocus}
        />
    );
};
export const IconTextInput = ({ value, onInputChange, placeholderValue, iconText, componentId, customClasses }: TIconTextInput) => {
    return (
        <>
            <div className={`expanded-control ${customClasses}`}>
                <div className="mb-2">
                    <label htmlFor={componentId} className="text-blue-200">
                        {placeholderValue}
                    </label>
                </div>
                <span className="p-input-icon-left">
                    <i className={`pi ${iconText}`} />
                    <InputText type="text" id={componentId} value={value} placeholder={placeholderValue} onChange={onInputChange} autoComplete="off" />
                </span>
            </div>
        </>
    );
};
export const DiscountComponent = ({ discountOPRef, discountOptions, onDiscountOptionChange, discountSelectedOption, discountAmount, onDiscountAmountChange, applyDiscountAction }: TDiscountComponentProps) => {
    return (
        <OverlayPanel ref={discountOPRef} className="lg:w-4">
            <h6>Transaction Discount</h6>
            <div className="p-fluid">
                <div className="grid p-formgrid">
                    <div className="field lg:col-12 md:col-12 col-12">
                        <label>Select Discount Type</label>
                        <SelectButton className="mt-2" value={discountSelectedOption} onChange={onDiscountOptionChange} options={discountOptions} />
                    </div>
                    <div className="field lg:col-12 md:col-12 col-12">
                        <label>Discount Amount</label>
                        <InputText type="number" value={discountAmount} onChange={onDiscountAmountChange} id="price" min={0} />
                    </div>
                    <div className="field lg:col-4 md:col-12 col-12">
                        <Button onClick={applyDiscountAction}>Give Discount</Button>
                    </div>
                </div>
            </div>
        </OverlayPanel>
    );
};
export const RoomsDistributionLabel=({backgroundColor,roomNumber,percentShare}:{backgroundColor:string,roomNumber:string,bookedCount:number,percentShare:number})=>{
    return <li className="flex justify-content-between align-items-center">
        <div className="flex justify-content-between align-items-center">
            <div className="color" style={{ backgroundColor: backgroundColor}}></div>
            <span>{roomNumber}</span>
        </div>
        <span>{percentShare.toFixed(2)} %</span>
    </li>
}
export const DashboardSnippet = ({snippetData,title,keyValue}:{snippetData:number,title:string,keyValue:string}) => {
    return (
        <>
            <div className="col-12 md:col-3" key={keyValue}>
                <div className={`card widget-overview-box widget-overview-box-3`}>
                    <span className="overview-title">{title}</span>
                    <div className="flex justify-content-between">
                        <div className="overview-detail flex justify-content-between">
                            <div className="overview-badge flex justify-content-center align-items-center">
                            </div>
                            <div className="overview-text">{snippetData}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
