import { TRoomType, TTableProps } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const RoomTypesList = ({ tableData, setupTableDataEdit, promptTableDataDelete }: TTableProps<TRoomType>) => {
    return (
        <>
            <SimpleTable
                tableKey={'roomTypeId'}
                columnsDef={[
                    { field: 'roomTypeDesc', header: 'Room Type' },
                    {
                        body: (rowData: TRoomType) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.roomTypeId?.toString()),
                        header: 'Edit'
                    }
                ]}
                tableData={tableData}
                tableTitle={'Room Types'}
                searchValues={['roomTypeDesc']}
            />
        </>
    );
};
export default RoomTypesList;
