import React from "react";
import Barcode from 'react-barcode';
import { TReservations, TUser } from '../../utilities/types';
type TPOSPrinterProps = {
    userData:TUser,
    reservationDetails:TReservations,
    amountPaid:number,
    invoiceNumber:string,
}
const POSPrinter:React.ForwardRefRenderFunction<HTMLDivElement,TPOSPrinterProps>=({userData,reservationDetails,amountPaid,invoiceNumber},ref)=>{
    const reservationTotalCost=reservationDetails.roomRate! * reservationDetails.numberOfDays!;
    const paymentChange=amountPaid-reservationTotalCost;
    return(
        <>
            <div ref={ref}>
                <div className="font-format" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>{new Date().toLocaleString()}</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <b className="text-center">Receipt #</b>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="company"><b>{userData.hotelName!}</b></div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="company"><b>{userData.locationName}</b></div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="company"><b>Phone Number: </b></div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                    <div className="company"><b>Guest: {reservationDetails.guestName}</b></div>
                </div>
                <br />
                <table style={{ marginBottom: 20, width: '100%' }}>
                    <thead>
                    <tr>
                        <th style={{ width: '10%', textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px dashed black' }}>Days</th>
                        <th style={{ width: '50%', textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px dashed black' }}>Rate</th>
                        <th style={{ width: '40%', textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px dashed black' }}>Sub Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ width: '10%', borderBottom: '1px dashed black', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bolder' }}>
                            {reservationDetails.numberOfDays}
                        </td>
                        <td style={{ width: '50%', borderBottom: '1px dashed black', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bolder' }}>
                            {reservationDetails.roomRate}
                        </td>
                        <td style={{ width: '40%', borderBottom: '1px dashed black', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bolder' }}>
                            {reservationDetails.roomRate! * reservationDetails.numberOfDays!}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div style={{ display: "flex", marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Subtotal</b>:
                    </div>
                    <div style={{ flex: 1 }}>
                        <b>{reservationDetails.roomRate! * reservationDetails.numberOfDays!}</b>
                    </div>
                </div>
                <div style={{ display: "flex", marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Tax:</b>:
                    </div>
                    <div style={{ flex: 1 }}>
                        0
                    </div>
                </div>
                <div style={{ display: "flex", marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Total:</b>
                    </div>
                    <div style={{ flex: 1 }}>
                        <b>{reservationDetails.roomRate! * reservationDetails.numberOfDays!}</b>
                    </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Tendered:</b>
                    </div>
                    <div style={{ flex: 1 }}>
                        {amountPaid}
                    </div>
                </div>

                {paymentChange>0 && <div style={{ display: "flex", marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Change Given:</b>
                    </div>
                    <div style={{ flex: 1 }}>
                        <b> {paymentChange}</b>
                    </div>
                </div>}
                {paymentChange<0 && <div style={{ display: "flex", marginBottom: '5px' }}>
                    <div style={{ flex: 1 }}>
                        <b>Total Unpaid:</b>
                    </div>
                    <div style={{ flex: 1 }}>
                        <b> {Math.abs(paymentChange)}</b>
                    </div>
                </div>}
                <Barcode value={`${invoiceNumber !== '' ? invoiceNumber : '123456789123456'}`}
                         lineColor={'rgba(35,34,34,0.84)'}
                         height={50}
                />
                <br />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                    <div className="company"><b>Receptionist: {userData.username}</b></div>
                </div>
                <br />
                <div className="developer" style={{ display: "flex", justifyContent: "center", alignItems: "center", pageBreakAfter: "always" }}>
                    <span>Harmony Hotels - 233 598 619 122</span>
                </div>
            </div>
        </>
    )
}
export default React.forwardRef(POSPrinter);
