import SimpleTable from '../../utilities/components/SimpleTable';
import { THotelRoom } from '../../utilities/types';

const AvailableRoomsList=({ tableData }: {tableData:THotelRoom[]})=>{

    return <>
        <h6>Available Rooms</h6>
        <SimpleTable
            tableKey={'roomId'}
            columnsDef={[
                { field: 'roomNumber', header: 'Room' },
                { field: 'roomTypeDesc', header: 'Room Type' },
                { field: 'roomRate', header: 'Room Rate' },
                {
                    body: (rowData: THotelRoom) => <div className="underline text-blue-500 cursor-pointer">See More...</div>,
                    header: 'Amenities'
                }
            ]}
            tableData={tableData}
            tableTitle={'Rooms'}
            searchValues={['roomTypeDesc']}
            tableMinWidth={40}
        />
    </>
}
export default AvailableRoomsList;
