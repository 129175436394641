import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { useGlobalContext } from './components/contextProviders/GlobalContext';
import { WifiOff, WifiOn } from './utilities/components/UtilityComponents';
import isOnline from 'is-online';
import { Tooltip } from 'primereact/tooltip';

const AppBreadcrumb = (props: any) => {
    const [search, setSearch] = useState('');
    const location = useLocation();
    const { currentAppMode } = useGlobalContext();
    const [internetState,setInternetState]=useState<boolean>();
    const [realAppState,setRealAppState]=useState<string>();
    useEffect(() => {
        const checkAppState=async()=>{
            const haveInternet=await isOnline();
            setRealAppState(haveInternet?'Internet Available':'No Internet');
            setInternetState(haveInternet);
        };

        let interval: string | number | NodeJS.Timeout | undefined = setInterval(checkAppState, 10000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    },[currentAppMode]);

    const activeRoute = props.routes.filter((route: any) => {
        return route.label.replace(/\s/g, '').toLowerCase() === location.pathname.toLowerCase().replace(/\s/g, '').slice(1);
    });

    let items;

    if (location.pathname === '/') {
        items = [{ label: 'Dashboard' }, { label: 'Sales Dashboard' }];
    } else if (!activeRoute.length) {
        items = [{ label: '' }, { label: '' }];
    } else {
        items = [{ label: activeRoute[0].parent }, { label: activeRoute[0].label }];
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    return (
        <>
            <Tooltip target=".custom-target-icon" content={`${currentAppMode==='onLine'?`Online - ${realAppState}`:`Offline - ${realAppState}`}`}/>
            <div className="layout-breadcrumb-container">
                <div className="layout-breadcrumb-left-items">
                    {isStatic() && (
                        <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-bars"></i>
                        </button>
                    )}

                    <BreadCrumb model={items} className="layout-breadcrumb" />
                </div>
                <div className={`flex justify-content-center flex-wrap`}>
                    <i className='custom-target-icon'>{currentAppMode === 'onLine' ? <WifiOn /> : <WifiOff />}</i>
                    <Badge className="fadeout animation-duration-1000 animation-iteration-infinite" severity={`${internetState ? 'success' : 'danger'}`}></Badge>
                    {/*<span className={'text-orange-500 ml-2'}> {currentUser.outletDescription}</span>*/}
                </div>
                <div className="layout-breadcrumb-right-items">
                    <button tabIndex={0} className="search-icon p-link" onClick={props.breadcrumbClick}>
                        <i className="pi pi-search"></i>
                    </button>

                    <div className={classNames('search-wrapper', { 'active-search-wrapper': props.searchActive })}>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} onClick={props.onInputClick} />
                    </span>
                    </div>

                    <span className="layout-rightmenu-button-desktop">
                    <Button label="Today" icon="pi pi-bookmark" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                </span>

                    <span className="layout-rightmenu-button-mobile">
                    <Button icon="pi pi-bookmark" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                </span>
                </div>
            </div>
        </>
    );
};

export default AppBreadcrumb;
