import SimpleTable from '../../utilities/components/SimpleTable';
import { TTableProps, TUser } from '../../utilities/types';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const StaffList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<TUser>)=>{
    return <>
        <SimpleTable
            tableKey={'staffId'}
            columnsDef={[
                {field:'staffName',header:'Staff'},
                {field:'username',header:'User Name'},
                {field:'phoneNumber',header:'User Name'},
                {field:'email',header:'Email'},
                {
                    body: (rowData: TUser) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.staffId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Users'}
            searchValues={['staffName','phoneNumber']}
        />
    </>
}
export default StaffList;
