import SimpleTable from '../../utilities/components/SimpleTable';
import React from 'react';
import { THotelLocation, TTableProps } from '../../utilities/types';
import { getName } from 'country-list';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const LocationsList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<THotelLocation>)=>{
    return (
        <>
            <SimpleTable
                tableKey="hotelLocationId"
                columnsDef={[
                    {field:'hotelName',header:'Hotel'},
                    {field:'locationName',header:'Name'},
                    {field:'locationEmail',header:'Email'},
                    {field:'city',header:'City'},
                    {field:'locationPhoneNumber',header:'Telephone'},
                    {body:(rowData:THotelLocation)=><div>{getName(rowData.countryCode!)}</div>,header:'Country'},
                    {
                        body: (rowData: THotelLocation) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.hotelLocationId?.toString()),
                        header: 'Edit'
                    },
                ]}
                tableData={tableData}
                tableTitle={'Locations'}
                searchValues={['locationName']}
            />
        </>
    );
}
export default LocationsList;
