import Dexie, { EntityTable } from 'dexie';
import {
    TAdjustment,
    TBrand,
    TCategory,
    TEventTypes,
    TExpenditure,
    TExpenditureCategory,
    TGuests,
    THotel,
    THotelEvents,
    THotelLocation,
    THotelRoom,
    TItem,
    TLocationSettings,
    TPayment,
    TPurchase,
    TQueuedTables,
    TReservations,
    TRoomType,
    TSales,
    TSuppliers,
    TUser, TUserMenu
} from '../utilities/types';

const db = new Dexie('HotelDatabase') as Dexie & {
    roomTypes:EntityTable<TRoomType,'roomTypeId'>,
    queuedTables:EntityTable<TQueuedTables,'tableId'>,
    hotelRooms:EntityTable<THotelRoom,'roomId'>,
    hotelGuests:EntityTable<TGuests,'guestId'>,
    hotelReservations:EntityTable<TReservations,'reservationId'>,
    hotelEvents:EntityTable<THotelEvents,'hotelEventId'>,
    hotelStaff:EntityTable<TUser,'staffId'>,
    hotels:EntityTable<THotel,'hotelId'>,
    hotelLocation:EntityTable<THotelLocation,'hotelLocationId'>,
    appSettings:EntityTable<TLocationSettings,'settingsId'>,
    eventTypes:EntityTable<TEventTypes,'eventTypeId'>,
    expenses:EntityTable<TExpenditure,'expenditureId'>,
    expenditureCategory:EntityTable<TExpenditureCategory,'categoryId'>,
    items:EntityTable<TItem,'itemId'>,
    brands:EntityTable<TBrand,'brandId'>,
    purchases:EntityTable<TPurchase,'purchaseId'>,
    adjustments:EntityTable<TAdjustment,'adjustmentId'>,
    sales:EntityTable<TSales,'salesId'>,
    suppliers:EntityTable<TSuppliers,'supplierId'>,
    itemCategory:EntityTable<TCategory,'categoryId'>,
    payments:EntityTable<TPayment,'paymentId'>,
    userMenu:EntityTable<TUserMenu,'userId'>,
};
db.version(2).stores({
    roomTypes:'++roomTypeId,roomTypeDesc,locationId',
    hotelRooms:'++roomId,roomNumber,locationId',
    queuedTables:'++tableId,tablesDesc',
    hotelGuests:'++guestId,guestName,hotelLocationId',
    hotelReservations:'++reservationId,guestName,hotelLocationId,[hotelLocationId+bookingType],checkInDate,checkOutDate,modifiedBy,guestCompany',
    hotelEvents:'++hotelEventId,eventHost,hotelLocationId,modifiedBy',
    hotelStaff:'++staffId,staffName,username,hotelLocationId',
    hotels:'hotelId,hotelName,staffId,personId',
    hotelLocation:'hotelLocationId,locationName,hotelId',
    appSettings:'settingsId,appName',
    eventTypes:'++eventTypeId,eventTypeDesc,hotelLocationId',
    expenses:'++expenditureId,hotelId,locationId,modifiedBy',
    expenditureCategory:'++categoryId,categoryDescription,hotelLocationId',
    items:'++itemId,itemName,hotelLocationId',
    brands:'++brandId,brandDescription,hotelLocationId',
    purchases:'++purchaseId,purchaseDate,hotelLocationId',
    adjustments:'++adjustmentId,adjustmentType,adjustmentDate,hotelLocationId',
    sales:'++salesId,guestName,salesDate,hotelLocationId',
    suppliers:'++supplierId,supplierName,hotelLocationId',
    itemCategory:'++categoryId,categoryName,hotelLocationId',
    payments:'++paymentId,hotelLocationId,[activityId+paymentType],modifiedBy',
    userMenu:'userId'
});
export {db}
