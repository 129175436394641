import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { THotelLocation, TLocationSettings, TPageLink, TUser } from '../../utilities/types';
import React, { useEffect, useReducer, useRef } from 'react';
import { displayMessage, isAppOnline, promptUserAction, REDUCER_ACTION_TYPE, StateReducerHandler } from '../../utilities/utilFunctions';
import Settings from '../../utilities/classObjects/Settings';
import { BigButton, GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import useUserData from '../../hooks/customHooks';
import { useGlobalContext } from '../contextProviders/GlobalContext';
import { Dialog } from 'primereact/dialog';
import SimpleTable from '../../utilities/components/SimpleTable';
import { Button } from 'primereact/button';
import { locationStaffQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import AppUsers from '../../utilities/classObjects/AppUsers';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataView } from 'primereact/dataview';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { updateCacheRecord } from '../../utilities/reactQueryFunctions';
const rolesList:TPageLink[]=[
    {icon:'pi pi-home',parent:'Favorites',pageDescription:'Dashboard',pageLink:''},
    {icon:'pi pi-cog',parent:'Favorites',pageDescription:'Settings',pageLink:'settings'},
    {icon:'pi pi-calendar',parent:'Favorites',pageDescription:'Calendar',pageLink:'calendar'},
    {icon:'pi pi-file-pdf',parent:'Favorites',pageDescription:'Reports',pageLink:'reports'},
    {icon:'pi pi-building',parent:'Hotel',pageDescription:'Hotels',pageLink:'hotels'},
    {icon:'pi pi-map-marker',parent:'Hotel',pageDescription:'Locations',pageLink:'locations'},
    {icon:'pi pi-users',parent:'Hotel',pageDescription:'Staffs',pageLink:'staffs'},
    {icon:'pi pi-money-bill',parent:'Hotel',pageDescription:'Expenditure',pageLink:'expenditures'},
    {icon:'pi pi-box',parent:'Rooms',pageDescription:'Rooms Types',pageLink:'room-types'},
    {icon:'pi pi-bookmark',parent:'Rooms',pageDescription:'Rooms',pageLink:'rooms'},
    {icon:'pi pi-key',parent:'Bookings',pageDescription:'Booking / Reservations',pageLink:'booking'},
    {icon:'pi pi-user',parent:'Bookings',pageDescription:'Guests',pageLink:'guests'},
    {icon:'pi pi-pencil',parent:'Bookings',pageDescription:'Reservations',pageLink:'reservations'},
    {icon:'pi pi-bell',parent:'Bookings',pageDescription:'Events',pageLink:'events'},
    {icon:'pi pi-shopping-cart',parent:'Restaurant',pageDescription:'Inventory Items',pageLink:'items'},
    {icon:'pi pi-shopping-cart',parent:'Restaurant',pageDescription:'sales',pageLink:'sales'},
    {icon:'pi pi-cart-plus',parent:'Restaurant',pageDescription:'Purchases',pageLink:'purchase'},
    {icon:'pi pi-shopping-cart',parent:'Restaurant',pageDescription:'Adjustments',pageLink:'adjustment'},
]
type TSettingsPage=TLocationSettings & {
    isLoading:boolean;
    connectivityModes:string[];
    showUserRolesDialog:boolean;
    staffList:TUser[],
    availablePages:string[],
    userPages:string[],
    selectedPages:TPageLink[],
    selectedUser?:TUser
}
const INITIAL_STATE:TSettingsPage = {
    connectivityModes: ['onLine', 'offLine'],
    isLoading:false,
    connectivityState:'onLine',
    showUserRolesDialog:false,
    staffList:[],
    availablePages:rolesList.map(role=>role.pageDescription),
    userPages:[],
    selectedPages:[]
}
const settings=new Settings();
const user=new AppUsers();
const AppSettings =()=> {
    const [state,dispatch]=useReducer(StateReducerHandler<TSettingsPage>,INITIAL_STATE);
    const {changeAppMode}=useGlobalContext();
    const [userData]=useUserData();
    const toastRef=useRef(null);
    const queryClient=useQueryClient();
    const OPRef=useRef<OverlayPanel>(null);
    useEffect(() => {
        const initSettings=async ()=>{
            const setting=await settings.getLocationSettings();

            setStateValues({connectivityState:setting.connectivityState,userPages:[]});
        }
        initSettings().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TSettingsPage>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };

    const onConnectivityModeChange=(e:SelectButtonChangeEvent)=>{
        if(e.value===null) return;
        changeAppMode!(e.value);
        localStorage.setItem('appState',e.value);
        setStateValues({connectivityState:e.value});
    }
    const updateSettings=async ()=>{
        settings.saveSettings({connectivityState:state.connectivityState});
        displayMessage({
            header: 'Success',
            message: 'Your settings have been saved!',
            life: 3000,
            infoType: 'success',
            toastComponent: toastRef
        });
    }
    const syncRemoteToLocal=async ()=>{
        try{
            setStateValues({isLoading:true});
            const syncResults=await settings.syncRemoteDataToLocal(userData.staffId,userData.hotelId,userData.hotelLocationId);
            if(syncResults?.status===200){
                displayMessage({
                    header: 'Sync Success',
                    message: 'Local DB was successfully populated with remote data',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
            }
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const syncLocalToRemote=async ()=>{
        try{
            setStateValues({isLoading:true});
            const syncResults=await settings.syncLocalDataToRemote(userData.staffId,userData.hotelId,userData.hotelLocationId);
            if(syncResults.operatedData===1){
                displayMessage({
                    header: 'Sync Success',
                    message: 'Local queued data was successfully synced!. Queued data will now be cleared!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
            }else{
                displayMessage({
                    header: 'Sync Error',
                    message: 'An error occurred and sync did not complete. Try again!',
                    life: 3000,
                    infoType: 'error',
                    toastComponent: toastRef
                });
            }
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const promptSyncToRemote=async (event:React.MouseEvent<HTMLButtonElement>)=>{
        promptUserAction({ yesAction: syncLocalToRemote, event, displayText: 'This will take locally queued data to remote server. Action cannot be undone. Do you want to proceed?' });
    }
    const promptSyncToLocal=(event:React.MouseEvent<HTMLButtonElement>)=>{
        promptUserAction({ yesAction: syncRemoteToLocal, event, displayText: 'Action will sync available remote data to your local storage. Do you want to proceed?' });
    }
    const onUserRolesDialogShow=async ()=>{
        try{
            const appSettings=await settings.getLocationSettings();
            setStateValues({isLoading:true});
            const staffs = isAppOnline(appSettings.connectivityState)? await locationStaffQuery(queryClient, userData.hotelLocationId)
                :await user.getOfflineUsers(userData.hotelLocationId);
            setStateValues({staffList:staffs});
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const onPageAdd=(e:CheckboxChangeEvent)=>{
        if (e.checked === true) {
            setStateValues({ userPages: [...state.userPages, e.target.value] });
        } else {
            setStateValues({ userPages: state.userPages.filter((userPage) => userPage !== e.target.value) });
        }
    }
    const updateUserPages=async ()=>{
        try{
            const selectedUserPages=rolesList.filter(rolesList=>state.userPages.includes(rolesList.pageDescription));
            setStateValues({isLoading:true});
            const updateResults=isAppOnline(state.connectivityState)?await user.updateUserPages(selectedUserPages,state.selectedUser?.staffId!):await user.updateUserMenuOffline(selectedUserPages,state.selectedUser?.staffId!);

            if(updateResults.status===200){
                const updatedUser={...state.selectedUser,userPages:selectedUserPages};

                await updateCacheRecord<TUser>(queryClient,['staffList'],
                    [updatedUser,state.selectedUser?.staffId,'staffId']);

                setStateValues({staffList:state.staffList.map((staff)=>{
                    if(staff.staffId===state.selectedUser?.staffId){
                        return updatedUser;
                    }
                    return staff;
                    })});

                displayMessage({
                    header: 'User Pages Update Success',
                    message: 'Selected pages were successfully updated for user!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
            }
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const PagesListDisplayTemplate = (pageDescription: string) => {
        return (
            <div className="col-12">
                <div className="card">
                    <div className="grid">
                        <Checkbox id={pageDescription} onChange={onPageAdd} checked={state.userPages.includes(pageDescription)} value={pageDescription}/>
                        <label className="ml-3">{pageDescription}</label>
                    </div>
                </div>
            </div>
        );
    };
    const onUserSelect=(e:React.MouseEvent<HTMLElement>)=>{
        const selectedStaff=state.staffList.find(staff=>staff.staffId===parseInt((e.target as HTMLElement)?.id));
        OPRef.current?.toggle(e);
        const menuPages:TPageLink[]=typeof selectedStaff?.userPages==='string'?JSON.parse(selectedStaff.userPages):selectedStaff?.userPages;
        setStateValues({selectedUser:selectedStaff,userPages:menuPages.map(menu=>menu.pageDescription)})
    }
    const onPanelHide=()=>{
        setStateValues({userPages:[]});
    }
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="surface-card shadow-2 border-round p-4">
                <div className="text-xl text-900 font-medium mb-5">Customised User Settings</div>
                <div className="grid">
                    <div className="col-12 lg:col-6 md:col-12">
                        <ul className="list-none p-0 m-0">
                            <li className="pb-3 border-1 border-round surface-border">
                                <div className="grid">
                                    <div className="col-4 pl-3">
                                        <div className="line-height-3 text-600">App Mode</div>
                                        <div className="justify-content-center">
                                            <SelectButton id="appMode" name="appMode" value={state.connectivityState} onChange={onConnectivityModeChange} options={state.connectivityModes} />
                                        </div>
                                    </div>
                                    <div className="col-4 pl-3 pt-5">
                                        <Button onClick={()=>setStateValues({showUserRolesDialog:true})}>Assign User Pages</Button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-6 md:col-12">
                        <ul className="list-none p-0 m-0">
                            <li className="pb-3 border-1 border-round surface-border">
                                <div className="grid">
                                    <div className="col-6 lg:col-6 p-3">
                                        <BigButton buttonAction={promptSyncToLocal} buttonTitle="Sync Online Data To Local"/>
                                    </div>
                                    <div className="col-6 lg:col-6 p-3">
                                        <BigButton buttonAction={promptSyncToRemote} buttonTitle="Sync Local Data To Remote"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <BigButton buttonAction={updateSettings} buttonTitle="Update Settings" />
                    </div>
                </div>
            </div>
            <Dialog onHide={()=>setStateValues({showUserRolesDialog:false})} visible={state.showUserRolesDialog} position="top" onShow={onUserRolesDialogShow}>
                <h6>Assign Roles</h6>
                <SimpleTable
                    tableKey={'staffId'}
                    columnsDef={[
                        { field: 'staffName', header: 'Staff' },
                        { field: 'userRole', header: 'Role' },
                        {
                            body: (rowData: TUser) => <div className="underline text-blue-500 cursor-pointer" onClick={onUserSelect} id={rowData.staffId?.toString()}>Assign Pages...</div>,
                            header: 'Assign Pages'
                        }
                    ]}
                    tableData={state.staffList}
                    tableTitle={'Staffs'}
                    searchValues={['staffName']}
                    tableMinWidth={40}
                />
            </Dialog>
            <OverlayPanel ref={OPRef} className="w-3" onHide={onPanelHide}>
                <DataView value={state.availablePages} itemTemplate={PagesListDisplayTemplate} paginator rows={5} />

                <div className="flex justify-content-evenly flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <Button className="mt-2" onClick={updateUserPages}>Update User Pages</Button>
                    </div>
                </div>
            </OverlayPanel>
        </>
    );
}
export default AppSettings;
