import { TGuestAndReservationsState, TGuests, TReservations } from '../../utilities/types';
import { GuestReservationContext } from '../contextProviders/GuestReservationContextProvider';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
    changeDateFormat, displayMessage, extractStateValues, getBookingStatus, getBookingTypes, getGenders, getInvoiceNumber, getNationalIdTypes, isAppOnline,
    pageDataValidation, paymentsMethods, REDUCER_ACTION_TYPE, remakeDropdownSelects, StateReducerHandler
} from '../../utilities/utilFunctions';
import Guests from '../guests/Guests';
import { TabMenu, TabMenuTabChangeEvent } from 'primereact/tabmenu';
import Reservations from '../reservations/Reservations';
import { Button } from 'primereact/button';
import { getGuestCompanyList, hotelLocationRoomsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import useUserData from '../../hooks/customHooks';
import { validateGuest, validateReservation } from '../../utilities/joiFunctions/joiFunctions';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import { addRecordToCache } from '../../utilities/reactQueryFunctions';
import HotelRoomClass from '../../utilities/classObjects/HotelRoom';
import Settings from '../../utilities/classObjects/Settings';
import POSPrinter from '../POSPrinter/POSPrinter';
import { useReactToPrint } from 'react-to-print';

const INITIAL_STATE:TGuestAndReservationsState={
    financedByList: [],
    selectedFinancier: {name:'',code:''},
    appState: 'onLine',
    toolBarVisible: false,
    isLoading:false,
    guestName:'',
    emailAddress:'',
    phoneNumber:'',
    nationalIdType:'',
    idNumber:'',
    age:0,
    genders:getGenders,
    gender:'',
    selectedGender:{name:'',code:0},
    rooms:[],
    selectedRoom:{name:'',code:0},
    roomId:0,
    checkOutDateDisplay:new Date(),
    checkInDateDisplay:new Date(),
    checkOutDate:changeDateFormat(new Date()),
    checkInDate:changeDateFormat(new Date()),
    numberOfAdults:0,
    numberOfChildren:0,
    bookingType:'Booking',
    purposeOfVisit:'',
    nationalIDTypes:[],
    bookingTypes:[],
    roomsList:[],
    roomRate:0,
    numberOfDays:0,
    status:'',
    hotelId:0,
    hotelLocationId:0,
    modifiedBy:0,
    crudeType:'save',
    paymentMethods:remakeDropdownSelects(paymentsMethods,'name','name'),
    amount:0,
    selectedBookingStatus:{name:'',code:''},
    invoiceNumber:'',
    guestCompany:'',
    guestCompanyList:[],
    paymentChange:0,
    selectedPaymentMethod:{name:'Cash',code:'Cash'},
    paymentMode:'Cash'
}
const reservation=new BookingReservation();
const roomObject=new HotelRoomClass();
const setting=new Settings();
const GuestReservations=()=>{
    const [state,dispatch]=useReducer(StateReducerHandler<TGuestAndReservationsState>, INITIAL_STATE);
    const [activeIndex,setActiveIndex]=useState(0);
    const queryClient=useQueryClient();
    const [userData]=useUserData();
    const toastRef=useRef(null);
    const componentRef=useRef(null);
    const reactToPrintFn=useReactToPrint({contentRef:componentRef!,ignoreGlobalStyles:true});
    useEffect(()=>{
        const initReservation=async ()=>{
            const appSetting= await setting.getLocationSettings();
            const roomsList=isAppOnline(appSetting.connectivityState)?await hotelLocationRoomsListQuery(queryClient,userData.hotelLocationId)
                :await roomObject.getOfflineHotelRooms(userData.hotelLocationId);
            const guestsCompany=await getGuestCompanyList(queryClient, userData.hotelLocationId);
            setStateValues({
                roomsList,
                rooms:remakeDropdownSelects(roomsList!,'roomNumber','roomId'),
                nationalIDTypes:remakeDropdownSelects(getNationalIdTypes,'name','code'),
                bookingTypes:remakeDropdownSelects(getBookingTypes,'name','code'),
                appState:appSetting.connectivityState,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                modifiedBy:userData.staffId,
                financedByList:remakeDropdownSelects([{name:'Individual',code:'Individual'},{name:'Company',code:'Company'}],'name','name'),
                bookStatusList:remakeDropdownSelects(getBookingStatus,'name','name'),
                invoiceNumber:getInvoiceNumber(userData.locationName,Math.floor(Math.random() * 100) + 1),
                guestCompanyList:guestsCompany?.map(guestsCompany=>guestsCompany.guestCompany)
            });
        }
        initReservation().catch(console.error);
    },[]);

    const setStateValues = (stateValues: Partial<TGuestAndReservationsState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getGuestDetails=():TGuests=>{
        const props: Array<keyof TGuests> = ['guestName','emailAddress','phoneNumber','nationalIdType','idNumber','age',
            'gender','hotelId','hotelLocationId','modifiedBy'];
        return extractStateValues<TGuests>(props, state);
    }
    const getReservationDetails=():TReservations=>{
        const props: Array<keyof TReservations> = ['roomId','checkOutDate','checkInDate','numberOfAdults','numberOfChildren','bookingType',
            'purposeOfVisit','status','hotelId','hotelLocationId','modifiedBy','numberOfDays','financedBy','guestCompany'];
        return extractStateValues<TReservations>(props, state);
    }
    const wizardItems = [
        {
            label: 'Guest Details',
            icon: 'pi pi-fw pi-user',
            command: () => {}
        },
        {
            label: 'Reservation',
            icon: 'pi pi-fw pi-building',
            command: () => {}
        }
    ];
    const onMoveNext=()=>{
        if(activeIndex === 0){
            if(!pageDataValidation(validateGuest,getGuestDetails(),toastRef)) return;
            setActiveIndex(1);
        }
    }
    const getPayment=()=>{
        return  { paymentMode: state.paymentMode, amount: state.amount, paymentChange: state.paymentChange, paymentId: state.paymentId, paymentDate: changeDateFormat(new Date()), paymentType: 'booking' };
    }
    const addReservation=async ()=>{
        try{
            const guestDetails=getGuestDetails();
            const reservationDetails=getReservationDetails();

            if(!pageDataValidation(validateGuest.concat(validateReservation),{...guestDetails,...reservationDetails},toastRef)) return;
            setStateValues({isLoading:true});
            const bookingResults=isAppOnline(state.appState)?await reservation.addNewGuestReservation(guestDetails,reservationDetails,userData.hotelId,userData.hotelLocationId,'save',getPayment())
                :await reservation.addGuestReservationLocal(guestDetails,{...reservationDetails,status:state.status,roomRate:state.roomRate,
                numberOfDays:state.numberOfDays,roomNumber:state.selectedRoom?.name,roomTypeDesc:state.roomTypeDesc,
                guestName:state.guestName},{paymentMode:state.paymentMode,amount:state.amount,paymentChange:state.paymentChange,paymentType:'booking'});
            if(bookingResults.status===200){
                await addRecordToCache<TGuests>(queryClient,['guestsList',userData.hotelLocationId],bookingResults.guest!);
                await addRecordToCache<TReservations>(queryClient,['hotelReservations',userData.hotelLocationId],bookingResults.reservation!);
                displayMessage({
                    header: 'Guest Added',
                    message: 'Guest bookings was successfully confirmed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                setTimeout(()=>{
                    resetGR();
                },1500);
                setStateValues({printableReservation: { ...reservationDetails,roomRate:state.roomRate,guestName:state.guestName }});
                setTimeout(()=>{
                    reactToPrintFn();
                },1000);

                setActiveIndex(0);
            }
        }catch (error:any){
            {
                displayMessage({
                    header: 'Error Occurred',
                    message: error.message,
                    life: 3000,
                    infoType: 'error',
                    toastComponent: toastRef
                });
            }
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const onTabMenuTabChange=(e:TabMenuTabChangeEvent)=>{
        if(e.index==1){
            if(!pageDataValidation(validateGuest,getGuestDetails(),toastRef)) return;
            setActiveIndex(1);
        }else{
            setActiveIndex(0);
        }
    }
    const resetGR = () => {
        setStateValues({
            guestId: 0,
            guestName: '',
            emailAddress: '',
            age: 0,
            gender: '',
            selectedGender: { name: '', code: '' },
            selectedNationalID: { name: '', code: '' },
            nationalIdType: '',
            hotelId: 0,
            hotelLocationId: 0,
            idNumber: '',
            phoneNumber: '',
            printableReservation:undefined
        });
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <GuestReservationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={onTabMenuTabChange} />
                {activeIndex === 0 && (<Guests />)}
                {activeIndex === 1 && (<Reservations />)}
            </GuestReservationContext.Provider>
            {
                activeIndex === 0 ? <div className="field">
                    <Button onClick={onMoveNext}>Next</Button>
                </div>:<><Button className="mr-2" onClick={()=>setActiveIndex(0)}>Prev</Button><Button disabled={state.isLoading} onClick={addReservation}>{`Add ${state.bookingType}`}</Button></>
            }
            <div className="hidden">
                {state.printableReservation!==undefined?<POSPrinter
                    reservationDetails={state.printableReservation}
                    ref={componentRef}
                    userData={userData}
                    amountPaid={state.amount!}
                    invoiceNumber={state.invoiceNumber!}
                />:<div></div>}
            </div>
        </>
    )
}
export default GuestReservations;
