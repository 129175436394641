import axiosFetch from '../axiosConfig';
import { TLoginResponse, TPageLink, TUser } from '../types';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import bcrypt from 'bcryptjs';
class AppUsers {
    async addNewUser(userDetails:TUser,hotelId:number,hotelLocationId:number,crudType:string){
        const data=await axiosFetch<TUser>('POST',`${getBaseURL()}/users/new_user`,{userDetails:{...userDetails,crudType},hotelId,hotelLocationId});
        return {status: data.status, data: data.data?.operatedData};
    }

    async performUserLogin(password: string, username: string) {
        const data= await axiosFetch<TLoginResponse>('POST', `${getBaseURL()}/users/auth`, { password, username });
        return {isUser: data.data?.operatedData?.isUser!, user: data.data?.operatedData?.user};
    }
    async updateUserPages(userPages:TPageLink[],staffId:number){
        const data=await axiosFetch<TUser>('POST',`${getBaseURL()}/users/update_user_pages`,{userPageDetails:{userPages,staffId}});
        return {status: data.status, data: data.data?.operatedData};
    }
    async getOfflineUsers(hotelLocationId:number){
        try{
            return db.hotelStaff.where({hotelLocationId}).toArray();
        }catch(error){
            throw error;
        }
    }
    async updateUserMenuOffline(userPages:TPageLink[],staffId:number){
        try{
            await db.userMenu.put({userId:staffId,userPages});
            return {status:200}
        }catch(error){
            throw error;
        }
    }
    /**
     * Verifies a new user login locally without resorting to remote assistance.
     * @param username
     * @param password
     */
    async verifyLocalLogin(username: string, password: string) {
        const user = await db.hotelStaff.get({ username });
        const userMenu=await db.userMenu.get({userId:user?.staffId});
        if (user) {
            const userState = await bcrypt.compare(password, user.password!);
            if (userState) {
                // const storeSubscription: TStoreSubscription[] = await localDB.storeSubscriptions.toArray();
                // const subscriptionDetails = storeSubscription[0];
                // const daysLeft = differenceInDays(new Date(subscriptionDetails.dateExpiring), new Date());

                return { user:{...user,userPages:userMenu?.userPages}, daysLeftToExpiry: 0,isUser:true };
            }
        }
        return undefined;
    }
}
export default AppUsers;
