import React, { useEffect, useRef } from 'react';
import { TAppState, TCrudType, TEventTypes, THotelEvents } from '../../utilities/types';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import { useQueryClient } from '@tanstack/react-query';
import { displayMessage, getTableRowId, isAppOnline } from '../../utilities/utilFunctions';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import HotelEventTypesList from '../DataTables/HotelEventTypesList';
import { hotelEventTypesListQuery } from '../../utilities/reactQueryUtils';
type EventTypePageProps = {
    userId:number,
    hotelId:number,
    hotelLocationId:number,
    appState:TAppState,
    reQueryEventsList?:()=>void,
}
type TEventTypesPage=TEventTypes & {
    crudType:TCrudType,
    isLoading:boolean,
    eventTypesList:TEventTypes[]
}
const INITIAL_STATE:TEventTypesPage = {
    eventTypeDesc: '',
    eventTypeId: 0,
    hotelId:0,
    hotelLocationId:0,
    modifiedBy:0,
    crudType:'save',
    isLoading:false,
    eventTypesList:[]
}
const reservation=new BookingReservation();
const EventTypes = ({hotelId,hotelLocationId,userId,appState,reQueryEventsList}:EventTypePageProps)=>{
     const [state,setState] = React.useState(INITIAL_STATE);
     const queryClient=useQueryClient();
     const toastRef=useRef(null);

    useEffect(() => {
        const initEventTypes=async ()=>{
            setStateValues({
                eventTypesList:await reQueryEventTypes()
            });
        }
        initEventTypes().catch(console.error);
    }, []);
    const reQueryEventTypes=async ()=>{
        return isAppOnline(appState)?await hotelEventTypesListQuery(queryClient,hotelLocationId)
            :await reservation.getOfflineEventTypes(hotelLocationId);
    }
     const setStateValues=(stateValues:Partial<TEventTypesPage>)=>{
         setState((prevState) => {
             return { ...prevState!, ...stateValues };
         });
     }

     const saveEventType=async ()=>{
         try{
             if(state.eventTypeDesc===''){
                 displayMessage({
                     header: 'Error',
                     message: 'Event type description cannot be empty. Try again!',
                     life: 3000,
                     infoType: 'error',
                     toastComponent: toastRef
                 });
                 return;
             }
             const eventTypeData:TEventTypes={hotelId,hotelLocationId,eventTypeId:state.eventTypeId,eventTypeDesc: state.eventTypeDesc,modifiedBy:userId}

             setStateValues({isLoading:true});
             const saveResults=isAppOnline(appState)?await reservation.addEventType(eventTypeData,state.crudType):await reservation.addEventTypeOffline(eventTypeData,state.crudType);
             if(saveResults.status===200){
                 state.crudType==='save'?await addRecordToCache<TEventTypes>(queryClient,['eventTypesList',hotelLocationId],
                     saveResults.operatedData!):await updateCacheRecord<TEventTypes>(queryClient,['eventTypesList',hotelLocationId],
                     [saveResults.operatedData,state.eventTypeId,'eventTypeId']);
                 displayMessage({
                     header: 'Success',
                     message: 'Event Type Data was successfully changed!',
                     life: 3000,
                     infoType: 'success',
                     toastComponent: toastRef
                 });
                 setStateValues({eventTypeDesc:'',crudType:'save',eventTypesList:await reQueryEventTypes()});
                 if(reQueryEventsList)
                     reQueryEventsList();
             }
         }catch(error){
             throw error;
         }finally {
             setStateValues({isLoading:false});
         }
     }
     const setupEventTypeEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
         try{
             const eventTypeId=parseInt(getTableRowId(e,'id'));
             const selectedEventType=state.eventTypesList.find(eventType=>eventType.eventTypeId===eventTypeId);
             if(selectedEventType){
                 const {eventTypeId,eventTypeDesc,hotelLocationId,hotelId}=selectedEventType;
                 setStateValues({eventTypeId,eventTypeDesc,hotelLocationId,hotelId,crudType:'update'});
             }
         }catch(error){
             throw error;
         }
     }
     const promptEventTypeDelete=()=>{}
    return (<>
        {state.isLoading && <Loader />}
        <GeneralPageProps toastRef={toastRef} />
        <div className="card">
            <div className="grid p-fluid">
                <div className="col-4">
                   <div className="field">
                       <label>Event Type Description</label>
                       <InputText value={state.eventTypeDesc} onChange={(e) => setStateValues({ eventTypeDesc: e.target.value })} />
                   </div>
                    <Button onClick={saveEventType}>{state.crudType==='save'?'Save Event Type':'Update Event Type'}</Button>
                </div>
                <div className="col-8">
                    <HotelEventTypesList tableData={state.eventTypesList} setupTableDataEdit={setupEventTypeEdit} promptTableDataDelete={promptEventTypeDelete}/>
                </div>
            </div>
        </div>
    </>)
}
export default EventTypes;
