import { THotelRoom, TTableProps } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const HotelRoomsList=({ tableData, setupTableDataEdit, promptTableDataDelete }: TTableProps<THotelRoom>)=>{

    return <>
        <h6>Hotel Rooms List</h6>
        <SimpleTable
            tableKey={'roomId'}
            columnsDef={[
                { field: 'roomNumber', header: 'Room' },
                { field: 'roomTypeDesc', header: 'Type' },
                { field: 'roomRate', header: 'Rate'},
                { field: 'capacity', header: 'Capacity' },
                { field: 'description', header: 'Description' },
                {
                    body: (rowData: THotelRoom) => <div className="underline text-blue-500 cursor-pointer">See Amenities</div>,
                    header: 'Amenities'
                },
                {
                    body: (rowData: THotelRoom) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.roomId?.toString()),
                    header: 'Edit'
                }
            ]}
            tableData={tableData}
            tableTitle={'Rooms'}
            searchValues={['roomTypeDesc']}
        />
    </>
}
export default HotelRoomsList;
