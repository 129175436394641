import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useRegistrationContext } from '../contextProviders/RegistrationContextProvider';
import { Dropdown } from 'primereact/dropdown';
import { DatePicker } from '../../utilities/components/UtilityComponents';
import { changeDateFormat } from '../../utilities/utilFunctions';


const Staffs = ()=>{
    const {state,setStateFunction:setStateValues}=useRegistrationContext();

    return (
        <>
            <div className="grid p-fluid">
                <div className="lg:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="firstName">Staff Name</label>
                                    <InputText id="firstName" type="text" value={state?.staffName} onChange={(e) => setStateValues!({ staffName: e.target.value })} autoComplete="off" />
                                </div>

                                <div className="field">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputText id="phoneNumber" type="text" value={state?.phoneNumber} onChange={(e) => setStateValues!({ phoneNumber: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="address">Address</label>
                                    <InputText id="address" type="text" value={state?.address} onChange={(e) => setStateValues!({ address: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <div className="grid">
                                        <div className="col-6">
                                            <label htmlFor="countries">User Role</label>
                                            <Dropdown
                                                value={state?.selectedUserRole}
                                                onChange={(e) =>
                                                    setStateValues!({
                                                        selectedUserRole: e.value,
                                                        userRole: e.value.code
                                                    })
                                                }
                                                options={state?.userRoles}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="countries">User Type</label>
                                            <Dropdown
                                                value={state?.selectedStaffType}
                                                onChange={(e) =>
                                                    setStateValues!({
                                                        selectedStaffType: e.value,
                                                        staffType: e.value.code
                                                    })
                                                }
                                                options={state?.staffTypes}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {state?.toolBarVisible && <div className="field">
                                    <label htmlFor="email">Email</label>
                                    <InputText id="email" type="text" value={state?.email} onChange={(e) => setStateValues!({ email: e.target.value })} autoComplete="off" />
                                </div>}
                            </div>
                            <div className="lg:col-6">
                                {state?.toolBarVisible && <div className="field">
                                    <label htmlFor="eventStartDate">Start Date</label>
                                    <DatePicker dateValue={state?.dateOfBirthDisplay} onDateChange={(e) => setStateValues!({ dateOfBirthDisplay: e.value,dateOfBirth:changeDateFormat(e.value) })} labelText="Event Start" controlId="eventStart" selectionType="single" />
                                </div>}
                                <div className="field">
                                    <label htmlFor="countries">Genders</label>
                                    <Dropdown
                                        value={state?.selectedGender}
                                        onChange={(e) =>
                                            setStateValues!({
                                                selectedGender: e.value,
                                                gender: e.value.code
                                            })
                                        }
                                        options={state?.genders}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="username">User Name</label>
                                    <InputText id="username" type="text" value={state?.username} onChange={(e) => setStateValues!({ username: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="password">Password</label>
                                    <InputText id="password" type="password" value={state?.password} onChange={(e) => setStateValues!({ password: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="repeatPassword">Repeat Password</label>
                                    <InputText id="repeatPassword" type="password" value={state?.repeatPassword} onChange={(e) => setStateValues!({ repeatPassword: e.target.value })} autoComplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Staffs;
