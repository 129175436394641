import { TCrudType, TEventTypes, TGuestReservationSaveResponse, TGuests, THotelEvents, TPayment, TReservations } from '../types';

import axiosFetch from '../axiosConfig';
import { changeDateFormat, getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import { Queue } from './Queue';
import payments from './Payments';
import reservationsMain from '../../components/reservations/ReservationsMain';
const queue = new Queue();
class BookingReservation {
    async addNewGuestReservation(guest:TGuests,reservation:TReservations,hotelId:number,hotelLocationId:number,crudType:string,paymentData:Partial<TPayment>){
        const data=await axiosFetch<TGuestReservationSaveResponse>('POST',`${getBaseURL()}/booking_reservations/new_guest_reservation`,
            {guest:{...guest,crudType},reservation:{...reservation,crudType},hotelId,hotelLocationId,payment:paymentData});
        return {status:data.status,guest:data.data?.operatedData?.guest,reservation:data.data?.operatedData?.reservation}
    }
    async addGuestReservationLocal(guest:TGuests,reservation:TReservations,paymentDetails:any){
        try{
            const savedGuest=await this.addNewGuestLocal(guest);
            const savedReservation=await this.addReservationLocal({...reservation,guestId:savedGuest.operatedData?.guestId,dateCreated:changeDateFormat(new Date())}, { ...paymentDetails,guestId:savedGuest.operatedData?.guestId });
            return {status:200,guest:savedGuest.operatedData,reservation:savedReservation.operatedData}
        }catch(error){
            throw error;
        }
    }
    async addNewGuest(guest:TGuests,crudType:string){
        const data=await axiosFetch<TGuests>('POST',`${getBaseURL()}/booking_reservations/new_guest`,{requestBody:{...guest,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData!}
    }
    async addNewReservation(reservation:TReservations,crudType:TCrudType,paymentData:any){
        const data=await axiosFetch('POST',`${getBaseURL()}/booking_reservations/new_reservation`,{requestBody:{...reservation,crudType,...paymentData}});
        return {status:data.status,operatedData:data.data?.operatedData}
    }
    async updateReservationStatus(reservationStatusData:TReservations){
        const data=await axiosFetch('POST',`${getBaseURL()}/booking_reservations/update_reservation_status`,{requestBody:reservationStatusData});
        return {status:data.status,operatedData:data.data?.operatedData}
    }
    async addNewHotelEvent(hotelEvent:THotelEvents,crudType:string){
        const data=await axiosFetch<THotelEvents>('POST',`${getBaseURL()}/booking_reservations/new_event`,{requestBody:{...hotelEvent,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData}
    }
    async addLocalGuest(guest:TGuests,crudType:TCrudType){
        return crudType==='save'?await this.addNewGuestLocal(guest):this.updateGuestLocal(guest);
    }
    async addNewGuestLocal(guest:TGuests){
        delete guest.guestId;
        try{
            return db.transaction('rw',[db.hotelGuests,db.queuedTables],async()=>{
                const newGuestId=await db.hotelGuests.add(guest);
                const newGuest=await db.hotelGuests.get(newGuestId);
                await queue.enqueue<TGuests>(newGuest!,'hotelGuests');
                return {status:200,operatedData:newGuest}
            });
        }catch(error){
            throw error;
        }
    }
    async updateGuestLocal(hotelGuest:TGuests){
        try{
            return db.transaction('rw',[db.hotelGuests,db.queuedTables],async()=>{
                const updatedGuestId=await db.hotelGuests.put(hotelGuest);
                await queue.updateQueue<TGuests>(hotelGuest,'hotelGuests','guestId',updatedGuestId!);
                return {status:200,operatedData:hotelGuest}
            })
        }catch(error){
            throw error;
        }
    }
    async addLocalReservation(reservation:TReservations,crudType:TCrudType,paymentData:any){
        return crudType==='save'?await this.addReservationLocal(reservation,paymentData):await this.updateReservationLocal(reservation);
    }
    async addReservationLocal(reservation:TReservations,paymentDetails:TPayment){
        delete reservation.reservationId;
        delete paymentDetails.paymentId;
        try{
            return db.transaction('rw',[db.hotelReservations,db.queuedTables,db.payments],async()=>{

                const newReservationId=await db.hotelReservations.add(reservation);

                const newReservation=await db.hotelReservations.get(newReservationId);

                const preparedPayment={...paymentDetails,hotelId:reservation.hotelId!,hotelLocationId:reservation.hotelLocationId!,modifiedBy:reservation.modifiedBy!,activityId:newReservationId!,paymentDate:changeDateFormat(new Date())};

                const paymentId=await db.payments.add(preparedPayment);

                const payment=await db.payments.get(paymentId);

                await queue.enqueue<TReservations>(newReservation!,'hotelReservations');
                await queue.enqueue<TPayment>(payment!,'payments');
                return {status:200,operatedData: newReservation}
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
    async updateReservationLocal(reservation:TReservations){
        try{
            const updatedReservationId=await db.hotelReservations.put(reservation);
            await queue.updateQueue<TReservations>(reservation,'hotelReservations','reservationId',updatedReservationId!);
            return {status:1,operatedData:reservation}
        }catch(error){
            throw error;
        }
    }
    async addEventLocal(hotelEvent:THotelEvents,crudType:TCrudType){
        return crudType==='save'?await this.addLocalHotelEvent(hotelEvent):await this.updateHotelEventLocal(hotelEvent);
    }
    async addLocalHotelEvent(hotelEvent:THotelEvents){
        delete hotelEvent?.hotelEventId;
        try{
            return db.transaction('rw',[db.hotelEvents,db.queuedTables],async()=>{
                const newEventId=await db.hotelEvents.add(hotelEvent);
                const newEvent=await db.hotelEvents.get(newEventId);
                await queue.enqueue<THotelEvents>(newEvent!,'hotelEvents');
                return {status:200,operatedData:newEvent}
            });
        }catch(error){
            throw error;
        }
    }
    async updateHotelEventLocal(hotelEvent:THotelEvents){
        try{
            return db.transaction('rw',[db.hotelEvents,db.queuedTables],async()=>{
               const updatedEventId=await db.hotelEvents.put(hotelEvent);
               await queue.updateQueue<THotelEvents>(hotelEvent,'hotelEvents','hotelEventId',updatedEventId!);
               return {status:200,operatedData:hotelEvent}
            });
        }catch(error){
            throw error;
        }
    }
    async updateReservationStatusLocal(reservation:TReservations){
        try{
            return db.transaction('rw',[db.hotelReservations,db.queuedTables],async()=>{
               const updatedReservationId=await db.hotelReservations.update(reservation.reservationId,{status:reservation.status,modifiedBy:reservation.modifiedBy});
               const updatedReservation=await db.hotelReservations.get(reservation.reservationId);
               console.log(updatedReservation);
               await queue.updateQueue<TReservations>(updatedReservation!,'hotelReservations','reservationId',reservation.reservationId!);
               return {status:200,operatedData:updatedReservation}
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflineGuestsList(hotelLocationId:number){
        try{
            return db.hotelGuests.where({hotelLocationId}).toArray();
        }catch(error){
            throw error;
        }
    }
    async getOfflineGuestCompanyList(hotelLocationId:number){
        try{
            const reservations=await db.hotelReservations.where({hotelLocationId}).toArray();
            const guestCompanies= reservations.filter(reservation=>reservation.financedBy==='Company').map(reservation=>{
                return {guestCompany:reservation.guestCompany}
            });
            const setFromCompanies=new Set(guestCompanies);
            return [...setFromCompanies];
        }catch(error){
            throw error;
        }
    }
    async getOfflineReservationsList(hotelLocationId:number){
        try{
            return db.hotelReservations.where({hotelLocationId}).toArray();
        }catch(error){
            throw error;
        }
    }
    async getOfflineEventsList(hotelLocationId:number){
        try{
            return db.hotelEvents.where({hotelLocationId}).toArray();
        }catch(error){
            throw error;
        }
    }
    async addEventType(eventData:TEventTypes,crudType:TCrudType){
        const data=await axiosFetch<TEventTypes>('POST',`${getBaseURL()}/booking_reservations/new_event_type`,{requestBody:{...eventData,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData}
    }
    async addEventTypeOffline(eventTypeData:TEventTypes,crudType:TCrudType){
        return crudType==='save'?await this.addLocalEventType(eventTypeData):await this.updateLocalEventType(eventTypeData);
    }
    async addLocalEventType(eventTypeData:TEventTypes){
        delete eventTypeData?.eventTypeId;
        try{
            return db.transaction('rw',[db.eventTypes,db.queuedTables],async()=>{
                const newEventTypeId=await db.eventTypes.add(eventTypeData);
                const newEventType=await db.eventTypes.get(newEventTypeId);
                await queue.enqueue<TEventTypes>(newEventType!,'eventTypes');
                return {status:200,operatedData:newEventType}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalEventType(eventTypeData:TEventTypes){
        try{
            const updatedEventTypeId=await db.eventTypes.put(eventTypeData);
            await queue.updateQueue<TEventTypes>(eventTypeData,'eventTypes','eventTypeId',updatedEventTypeId!);
            return {status:200,operatedData:eventTypeData}
        }catch(error){
            throw error;
        }
    }
    async getOfflineEventTypes(hotelLocationId:number){
        try{
            return db.eventTypes.where({hotelLocationId}).toArray();
        }catch(error){
            throw error;
        }
    }
}
export default BookingReservation;
