import { DropdownOption, TBrand, TCrudType, TItem, TLocationSettings, TPriceEditItem } from '../../../utilities/types';
import Items from '../../../utilities/classObjects/Items';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId, isAppOnline, makeSelectableItemList, onInputControlFocus, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../../../utilities/utilFunctions';
import { DatePicker, FilterSelect, GeneralPageProps, IconTextInput, Loader, NumberInputWithButtons } from '../../../utilities/components/UtilityComponents';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { CalendarChangeEvent } from 'primereact/calendar';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord } from '../../../utilities/reactQueryFunctions';
import { InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menubar } from 'primereact/menubar';
import ItemsList from '../../DataTables/ItemsList';
import Settings from '../../../utilities/classObjects/Settings';
import { validateItemsState } from '../../../utilities/joiFunctions/joiFunctions';
import useUserData from '../../../hooks/customHooks';
import { Nullable } from 'primereact/ts-helpers';
import { itemBrandsListQuery, itemsListQuery } from '../../../utilities/reactQueryUtils';

const _ = require('lodash');

type TItemState = TItem & {
    isLoading: boolean;
    showDialog: boolean;
    itemManufacturers: DropdownOption[] | undefined;
    editingState: boolean;
    showPurchasesDialog: boolean;
    showPriceAdjustmentDialog: boolean;
    priceEditItems: DropdownOption[];
    selectedPriceEditItem: DropdownOption;
    selectedPriceEditItems: TPriceEditItem[];
    showManufacturersDialog: boolean;
    brandDescription: string;
    brandId: number | string;
    isBrandEditState: boolean;
    isNewBrandItem: boolean;
    brandsList: TBrand[],
    itemsList: TItem[],
    selectedBrand: DropdownOption,
    appSettings: TLocationSettings,
    crudType: TCrudType,
    displayDate: Nullable<Date>
};

const INITIAL_STATE: TItemState = {
    itemId: 0,
    itemName: '',
    itemDescription: '',
    brandId: 0,
    itemCost: '0',
    itemPrice: '0',
    quantityInStock: '0',
    expirationDate: changeDateFormat(new Date()),
    itemManufacturers: [],
    isLoading: false,
    showDialog: false,
    editingState: false,
    showPurchasesDialog: false,
    showPriceAdjustmentDialog: false,
    priceEditItems: [],
    selectedPriceEditItem: { name: '', code: '' },
    selectedPriceEditItems: [],
    showManufacturersDialog: false,
    brandDescription: '',
    isBrandEditState: false,
    isNewBrandItem: true,
    brandsList: [],
    itemsList: [],
    selectedBrand: { name: '', code: 0 },
    hotelId: 0,
    hotelLocationId: 0,
    appSettings: defaultSettings(),
    crudType: 'save',
    displayDate: new Date()
};

const items = new Items();
const settings = new Settings();

const ItemStatePage = () => {
    const queryClient = useQueryClient();
    const [itemState, setItemState] = useState<TItemState>(INITIAL_STATE);
    const toastRef = useRef(null);
    const [userData] = useUserData();

    useEffect(() => {
        const initItemsPage = async () => {
            const appSettings = await settings.getLocationSettings();
            const itemsList = await queryItemsList(appSettings.connectivityState);
            const brandsList = await queryBrandsList(appSettings.connectivityState);
            setStateValue({
                itemId: 0,
                itemManufacturers: makeBrandsList(brandsList!),
                priceEditItems: makeSelectableItemList(itemsList!),
                itemsList: itemsList!,
                brandsList: brandsList!,
                hotelId: userData.hotelId,
                hotelLocationId: userData.hotelLocationId,
                appSettings
            });
        };
        initItemsPage().catch(console.error);
    }, []);
    const queryItemsList = async (appState: string) => {
        return isAppOnline(appState) ? await itemsListQuery(queryClient, userData.hotelLocationId) : await items.getOfflineItems(userData.hotelLocationId);
    };
    const queryBrandsList = async (appState: string) => {
        return isAppOnline(appState) ? await itemBrandsListQuery(queryClient, userData.hotelLocationId) : await items.getOfflineBrands(userData.hotelLocationId);
    };
    const makeBrandsList = (brandsList: TBrand[]) => {
        return remakeDropdownSelects(brandsList!, 'brandDescription', 'brandId');
    };

    const setStateValue = (stateValues: Partial<TItemState>) => {
        setItemState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValue({ [e.target.id]: e.target.value! });
    };
    const itemMenu = () => {
        return [
            {
                label: 'New Item',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showDialog: true })
            },
            {
                label: 'Adjust Prices',
                icon: 'pi pi-pencil',
                command: () => setStateValue({ showPriceAdjustmentDialog: true })
            },
            {
                label: 'Add Brand',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showManufacturersDialog: true, isNewBrandItem: true, isBrandEditState: false })
            },
            {
                label: 'Brands List',
                icon: 'pi pi-list',
                command: () => setStateValue({ showManufacturersDialog: true, isNewBrandItem: false })
            }
        ];
    };
    const getStateValues = (): TItem => {
        const props: Array<keyof TItem> = ['itemId', 'itemName', 'itemDescription', 'brandId', 'itemCost', 'itemPrice', 'quantityInStock', 'expirationDate', 'hotelId', 'hotelLocationId'];
        return extractStateValues<TItem>(props, itemState);
    };
    const hideDialog = () => {
        setStateValue({ showDialog: false, editingState: false });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ brandId: e.value.code, selectedBrand: e.value });
    };
    const onExpirationDateChange = (e: CalendarChangeEvent) => {
        setStateValue({ expirationDate: changeDateFormat(e.value! as Date), displayDate: e.value! as Date });
    };
    const validateItemPage = () => {
        return pageDataValidation<TItem>(validateItemsState, getStateValues(), toastRef);
    };
    const saveItem = async () => {
        if(!isAppOnline(itemState.appSettings.connectivityState)){
            displayMessage({
                header: 'Not Allowed',
                message: 'You cannot add or edit items locally. Switch to online first.',
                life: 3000,
                infoType: 'warn',
                toastComponent: toastRef
            });
            return;
        }
        try {
            if (!validateItemPage()) return;
            const stateValues = getStateValues();
            setStateValue({ isLoading: true });
            const itemSaveResponse = isAppOnline(itemState.appSettings.connectivityState) ? await items.createInstance(stateValues, itemState.crudType) : await items.addItemOffLine(stateValues, itemState.crudType);
            if (itemSaveResponse.status === 200) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Change Success',
                    message: 'Item current change activity was successful',
                    infoType: 'success',
                    life: 5000
                });
                itemState.crudType === 'save' ? await addRecordToCache<TItem>(queryClient, ['itemsList', userData.hotelLocationId], itemSaveResponse.operatedData!)
                    : await updateCacheRecord<TItem>(queryClient, ['itemsList', userData.hotelLocationId], [itemSaveResponse.operatedData, stateValues.itemId, 'itemId']);
                const itemsList=await queryItemsList(itemState.appSettings.connectivityState);
                setStateValue({ itemsList:itemsList,priceEditItems:makeSelectableItemList(itemsList!)});
                resetItemState();
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupItemEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedItemId = getTableRowId(e, 'id');
        const selectedItem = itemState.itemsList?.find((item) => item.itemId === parseInt(selectedItemId));
        const selectedBrand=itemState.itemManufacturers?.find(manufacturer=>manufacturer.code===selectedItem?.brandId);
        setStateValue({
            ...selectedItem,
            showDialog: true,
            expirationDate: changeDateFormat(selectedItem?.expirationDate as Date),
            displayDate: new Date(selectedItem?.expirationDate as Date),
            editingState: true,
            crudType: 'update',
            selectedBrand
        });
    };
    const deleteItem = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const itemId = getTableRowId(e, 'id');
            setStateValue({ isLoading: true });
            const deleteItemResponse = await items.deleteInstance(itemId);
            if (deleteItemResponse.status === 1) {
                await deleteCacheRecord<TItem>(queryClient, ['itemsList', userData.hotelLocationId], [deleteItemResponse.operatedData, itemId, 'itemId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Item was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const onItemSelectionChange = (e: DropdownChangeEvent) => {
        const selectedItem: TItem = _.find(itemState.itemsList, (listItem: TItem) => listItem.itemId === parseInt(e.value.code));
        const { itemId, itemName, itemPrice } = selectedItem;
        setStateValue({
            selectedPriceEditItem: e.value,
            selectedPriceEditItems: [
                ...itemState.selectedPriceEditItems,
                {
                    itemId,
                    itemName,
                    itemOldPrice: itemPrice,
                    itemNewPrice: 0
                }
            ]
        });
    };
    const onPriceAdjustmentDialogHide = async () => {
        setStateValue({ showPriceAdjustmentDialog: false });
    };
    const onItemNewPriceChange = async (e: InputNumberValueChangeEvent) => {
        const editingItem: TPriceEditItem = _.find(itemState.selectedPriceEditItems, (listItem: TPriceEditItem) => listItem.itemId === parseInt(e.target.id));
        if (editingItem !== undefined) {
            const updatingItem = { ...editingItem, itemNewPrice: e.target.value! };
            const selectedPriceEditItems = await updateCacheItem<TPriceEditItem>(updatingItem, editingItem, itemState.selectedPriceEditItems);
            setStateValue({ selectedPriceEditItems });
        }
    };
    const itemSellPriceInput = (rowData: TPriceEditItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemNewPrice} inputButtonsClick={onItemNewPriceChange} numberInputId={rowData.itemId.toString()} />;
    };
    const updateItemsPrices = async () => {
        try {
            setStateValue({ isLoading: true });
            const updatingItems=itemState.selectedPriceEditItems.map(item=>item.itemId).join(", ");
            const pricesUpdateResponse = await items.updatePrices(itemState.selectedPriceEditItems,updatingItems,userData.staffId);
            if(pricesUpdateResponse.status === 200) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Change Success',
                    message: 'Item current change activity was successful',
                    infoType: 'success',
                    life: 5000
                });
                for (const item of pricesUpdateResponse.data?.operatedData!) {
                    await updateCacheRecord<TItem>(queryClient, ['itemsList', userData.hotelLocationId], [item, item.itemId, 'itemId']);
                }

                setStateValue({showPriceAdjustmentDialog:false,priceEditItems:[],selectedPriceEditItem:{name:'',code:0},
                    selectedPriceEditItems:[],itemsList:await queryItemsList(itemState.appSettings.connectivityState)});
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const deletePriceListItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'id');
        const selectedPriceEditItems = itemState.selectedPriceEditItems.filter((listItem: TPriceEditItem) => listItem.itemId !== parseInt(itemId));
        setStateValue({ selectedPriceEditItems });
    };
    const addNewManufacturer = async () => {
        if(!isAppOnline(itemState.appSettings.connectivityState)){
            displayMessage({
                header: 'Not Allowed',
                message: 'You cannot add or edit item brands locally. Switch to online first.',
                life: 3000,
                infoType: 'warn',
                toastComponent: toastRef
            });
            return;
        }
        try {
            const brandDescription = itemState.brandDescription;
            const brandData: TBrand = { brandId: itemState.brandId, brandDescription: itemState.brandDescription, hotelId: itemState.hotelId, hotelLocationId: itemState.hotelLocationId };
            if (brandDescription === '') {
                displayMessage({
                    header: 'Error',
                    message: 'Add a valid description for new brand',
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 5000
                });
                return;
            }
            setStateValue({ isLoading: true });

            const newBrandResponse = isAppOnline(itemState.appSettings.connectivityState) ? await items.newItemBrand(brandData, itemState.crudType) : await items.addOfflineBrand(brandData, itemState.crudType);
            if (newBrandResponse.status === 200) {
                itemState.crudType === 'save' ? await addRecordToCache<TBrand>(queryClient, ['brandsList', userData.hotelLocationId], newBrandResponse?.operatedData!)
                    : await updateCacheRecord<TBrand>(queryClient, ['brandsList', userData.hotelLocationId], [newBrandResponse.operatedData, newBrandResponse?.operatedData!.brandId!, 'brandId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Brand was successfully saved to list',
                    infoType: 'success',
                    life: 5000
                });
                const currentBrandsList = await queryBrandsList(itemState.appSettings.connectivityState);
                setStateValue({ brandDescription: '', showManufacturersDialog: false, crudType: 'save', brandsList: currentBrandsList, itemManufacturers: makeBrandsList(currentBrandsList!) });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupBrandEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedBrandId = getTableRowId(e, 'id');
        const selectedBrand = _.find(itemState.brandsList, (listItem: TBrand) => listItem.brandId === parseInt(selectedBrandId));
        setStateValue({ brandDescription: selectedBrand.brandDescription, brandId: selectedBrand.brandId, isBrandEditState: true, isNewBrandItem: true, crudType: 'update' });
    };
    const resetItemState = () => {
        setStateValue({ crudType: 'save', showDialog: false, itemName: '', itemDescription: '', brandId: 0, selectedBrand: { name: '', code: 0 }, itemCost: '0', itemPrice: '0', expirationDate: new Date() });
    };
    const NewItemComponent = (
        <div className="p-fluid">
            <div className="grid p-formgrid">
                <IconTextInput
                    value={itemState.brandDescription}
                    onInputChange={(e) => setStateValue({ brandDescription: e.target.value })}
                    placeholderValue="Brand Description"
                    iconText="pi pi-phone"
                    componentId="brandDescription"
                    customClasses="lg:col-12 md:col-12 col-12"
                />
            </div>
            <Button onClick={addNewManufacturer}>{itemState.crudType === 'save' ? 'Add New Brand' : 'Update Brand'}</Button>
        </div>
    );
    return (
        <>
            {itemState?.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="bottom-right" />
            <div className="p-fluid lg:pl-5">
                <Menubar model={itemMenu()} />
                <ItemsList tableData={itemState.itemsList} setupTableDataEdit={setupItemEdit} promptTableDataDelete={deleteItem} />
            </div>
            <Dialog onHide={hideDialog} visible={itemState?.showDialog} className="lg:w-7" position={'top-right'} header="Add New Item">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <IconTextInput value={itemState?.itemName} onInputChange={controlChange} placeholderValue="Item Name" iconText="pi pi-user" componentId="itemName" customClasses="lg:col-6 md:col-12 col-12" />
                        <IconTextInput value={itemState?.itemDescription} onInputChange={controlChange} placeholderValue="Item Description" iconText="pi pi-user" componentId="itemDescription" customClasses="lg:col-6 md:col-12 col-12" />
                        <div className="field lg:col-5 md:col-10 col-10">
                            <FilterSelect selectableOptions={itemState?.itemManufacturers!} selectedOption={itemState?.selectedBrand!} onSelectChange={onSelectChange} elementId="itemManufacturer" defaultValue="Manufacturer" />
                        </div>
                        <div className="field lg:col-1 md:col-2 col-2">
                            <Button icon="pi pi-plus" className="p-button-rounded p-button-success mt-4" size="small" onClick={() => setStateValue({ showManufacturersDialog: true, isNewBrandItem: true, isBrandEditState: false })} />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="itemCost">Item Cost</label>
                            <InputText type="number" value={itemState?.itemCost} onChange={controlChange} id="itemCost" onBlur={onInputControlFocus} onFocus={onInputControlFocus} />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="itemPrice">Item Price</label>
                            <InputText type="number" value={itemState?.itemPrice} onChange={controlChange} id="itemPrice" onBlur={onInputControlFocus} onFocus={onInputControlFocus} />
                        </div>
                        {!itemState.editingState && (
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="quantityInStock">Quantity</label>
                                <InputText type="number" value={itemState?.quantityInStock} onChange={controlChange} id="quantityInStock" onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                            </div>
                        )}
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="expirationDate">Expiring Date</label>
                            <DatePicker dateValue={itemState?.displayDate! as Date} onDateChange={onExpirationDateChange} labelText="Expiration Date" controlId="expirationDate" selectionType="single" displayButtonBar={true} displayTime={false} />
                        </div>
                        <div className="field lg:col-2 md:col-12 col-12 mt-4">
                            <Button onClick={saveItem}>{itemState?.crudType === 'save' ? `Save Item` : `Update Item`}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog maximizable header="Adjust Item Prices" onHide={onPriceAdjustmentDialogHide} visible={itemState.showPriceAdjustmentDialog} className="lg:w-auto" position="top-right">
                <div className="card">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <div className="field lg:col-12 md:col-12 col-12">
                                <FilterSelect selectableOptions={itemState.priceEditItems} selectedOption={itemState.selectedPriceEditItem} onSelectChange={onItemSelectionChange} elementId="selectedSaleItem" defaultValue="Items" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <DataTable value={itemState.selectedPriceEditItems} emptyMessage="Select an item">
                        <Column field="itemName" header="Item" style={{ width: '30rem' }}></Column>
                        <Column field="itemOldPrice" header="Old Price" style={{ width: '10rem' }}></Column>
                        <Column header="New Price" style={{ width: '10rem' }} body={itemSellPriceInput}></Column>
                        <Column
                            header="Del"
                            style={{ width: '5rem' }}
                            body={(rowData: TPriceEditItem) => (
                                <div>
                                    <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId.toString()} onClick={deletePriceListItem} />
                                </div>
                            )}
                        ></Column>
                    </DataTable>
                </div>
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-3 md:col-12 col-12">
                            <Button onClick={updateItemsPrices}>Update Items Prices</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog onHide={() => setStateValue({ showManufacturersDialog: false })} visible={itemState.showManufacturersDialog} className={itemState.isNewBrandItem ? 'w-4' : 'w-5'} header="Add New Brand" position="top-right">
                {itemState.isNewBrandItem ? (
                    NewItemComponent
                ) : (
                    <DataTable value={itemState.brandsList} emptyMessage="Select an item" paginator rows={5}>
                        <Column field="brandDescription" header="Brand" style={{ width: '70rem' }}></Column>
                        <Column
                            body={(rowData: TBrand) => <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={setupBrandEdit} id={rowData.brandId!.toString()} />}
                            header="Edit Brand"
                            style={{ width: '30rem' }}
                        ></Column>
                    </DataTable>
                )}
            </Dialog>
        </>
    );
};
export default ItemStatePage;
