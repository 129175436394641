import { TExpenditure, TTableProps } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';
import { tableEditOption } from '../../utilities/components/UtilityComponents';
import { changeDateFormat } from '../../utilities/utilFunctions';

const ExpendituresList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<TExpenditure>)=>{

    return <>
        <SimpleTable
            tableKey={'expenditureId'}
            columnsDef={[
                {field:'categoryDescription',header:'Category'},
                {body:(rowData:TExpenditure)=><div>{changeDateFormat(new Date(rowData.expenditureDate))}</div>,header:'Date'},
                {field:'expenditureAmount',header:'Amount'},
                {field:'paymentMethod',header:'Payment Method'},
                {field:'description',header:'Description'},
                {field:'username',header:'Cost Center'},
                {
                    body: (rowData: TExpenditure) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.expenditureId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Expenditures'}
            searchValues={['categoryDescription']}
        />
    </>
}
export default ExpendituresList;
