import { DropdownOption, TCrudType, TLocationSettings, TPayment, TReservations } from '../../utilities/types';
import { Divider } from 'primereact/divider';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { FilterSelect, GeneralPageProps, Loader, tableEditOption } from '../../utilities/components/UtilityComponents';
import { changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId, getTotalPaid,
    isAppOnline, onInputControlFocus, pageDataValidation, paymentsMethods, remakeDropdownSelects } from '../../utilities/utilFunctions';
import Payments from '../../utilities/classObjects/Payments';
import useUserData from '../../hooks/customHooks';
import Settings from '../../utilities/classObjects/Settings';
import { paymentsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import SimpleTable from '../../utilities/components/SimpleTable';
import { format } from 'date-fns/format';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import { validatePayment } from '../../utilities/joiFunctions/joiFunctions';
import POSPrinter from '../POSPrinter/POSPrinter';
import { useReactToPrint } from 'react-to-print';

type TPaymentProps = {
    reservationDetails: TReservations;
}
type TPaymentPage = TPayment & {
    paymentMethods: DropdownOption[];
    selectedPaymentMethod: DropdownOption,
    isLoading:boolean,
    paymentsList:TPayment[],
    crudType:TCrudType,
    appSettings:TLocationSettings,
    totalGuestPayment:number,
    totalReservationCost:number,
    invoiceNumber:string
}
const INITIAL_STATE: TPaymentPage = {
    amount: 0,
    hotelId: 0,
    hotelLocationId: 0,
    modifiedBy: 0,
    paymentDate: changeDateFormat(new Date()),
    paymentId: 0,
    activityId: 0,
    paymentMethods: [],
    selectedPaymentMethod: { name: 'Cash', code: 'Cash' },
    paymentMode:'Cash',
    isLoading:false,
    paymentsList:[],
    crudType:'save',
    appSettings:defaultSettings(),
    totalGuestPayment:0,
    paymentChange:0,
    totalReservationCost:0,
    paymentType:'booking',
    invoiceNumber:'',
};

const payment=new Payments();
const setting=new Settings();
/*
Since both event payment and hotel booking and reservation payments saves to the
same database table, they use generic ID identifier as activityId. Hence, their
primary IDs are referred to as activityId here.
 */
const AcceptPayment = ({ reservationDetails }: TPaymentProps) => {
    const queryClient=useQueryClient();
    const [state, setState] = useState<TPaymentPage>(INITIAL_STATE);
    const toastRef=useRef(null);
    const [userData]=useUserData();
    const componentRef=useRef(null);
    const reactToPrintFn=useReactToPrint({contentRef:componentRef!,ignoreGlobalStyles:true});
    useEffect(()=>{
        const initPayments=async()=>{
            const appSettings=await setting.getLocationSettings();
            const paymentsList=await queryPaymentsList(appSettings.connectivityState);
            const totalPayments=getTotalPaid(paymentsList!);
            const totalReservationCost=(reservationDetails.numberOfDays! * reservationDetails.roomRate!);
            setStateValues({
                paymentMethods: remakeDropdownSelects(paymentsMethods,'name','name'),
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                modifiedBy:userData.staffId,
                activityId:reservationDetails.reservationId,
                paymentsList,
                appSettings,
                totalGuestPayment:totalPayments,
                paymentChange:totalPayments-totalReservationCost>0?totalPayments-totalReservationCost:0,
                totalReservationCost
            });
        }
        initPayments().catch(console.error);
    },[]);
    const setStateValues = (stateValues: Partial<TPaymentPage>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getStateValues = () => {
        const props: Array<keyof TPayment> = ['activityId','paymentId','paymentDate','paymentMode','amount','modifiedBy','hotelId','hotelLocationId','paymentType','paymentChange'];
        return extractStateValues<TPayment>(props, state);
    };
    const queryPaymentsList=async (appState:string)=>{
        return isAppOnline(appState)? await paymentsListQuery(queryClient,reservationDetails.reservationId!):await payment.getOfflinePayments(reservationDetails.reservationId!,'booking');
    }

    const completePayment=async ()=>{
        try{
            if(!pageDataValidation<TPaymentPage>(validatePayment,getStateValues(),toastRef)) return;
            setStateValues({isLoading:true});
            const paymentsSavedResult=isAppOnline(state.appSettings.connectivityState)?await payment.acceptNewPayment(getStateValues(),state.crudType):await payment.addOfflinePayment(getStateValues(),state.crudType);
            if(paymentsSavedResult.status===200){
                state.crudType === 'save'
                    ? await addRecordToCache<TPayment>(queryClient, ['reservationPayments', reservationDetails.reservationId], paymentsSavedResult.operatedData!)
                    : await updateCacheRecord<TPayment>(queryClient, ['reservationPayments', reservationDetails.reservationId], [paymentsSavedResult.operatedData,state.paymentId, 'paymentId']);
                setStateValues({paymentsList: await queryPaymentsList(state.appSettings.connectivityState)!});
                displayMessage({
                    header: 'Success',
                    message: 'Payment Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                reactToPrintFn();
                resetPayment();
            }
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const setupPaymentEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const paymentId=parseInt(getTableRowId(e,'id'));
        const selectedPayment=state.paymentsList.find(payment=>payment.paymentId===paymentId);
        if(selectedPayment!==undefined){
            const {paymentId,paymentMode,amount}=selectedPayment;
            setStateValues({paymentId,paymentMode,amount,selectedPaymentMethod:{name:paymentMode,code:paymentMode},crudType:'update'})
        }
    }
    const promptPaymentDelete=()=>{

    }
    const resetPayment=()=>{
        setStateValues({paymentId:0,amount:0,selectedPaymentMethod:{name:'',code:''},crudType:'save'});
    }
    const onPaymentAmountChange=(e:InputNumberChangeEvent)=>{
        const totalPayment=state.crudType==='save'?getTotalPaid(state.paymentsList)+e.value!:e.value!;
        const payable=reservationDetails?.numberOfDays! * reservationDetails?.roomRate!;
        const change=totalPayment-payable>0?totalPayment-payable:0;
        setStateValues({amount:e.value!,paymentChange:parseFloat(change.toFixed(2)),totalGuestPayment:totalPayment})
    }

    return <>
        {state.isLoading && <Loader />}
        <GeneralPageProps toastRef={toastRef} />
        <div className="grid">
            <div className="col-6">
                <div className="card w-auto">
                    <div className="grid">
                        <div className="col-12 font-bold text-2xl text-orange-500 pl-3"><span className="">Amount Payable</span>:<span className="ml-3">{state.totalReservationCost}</span></div>
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <div className="col-12 font-bold text-2xl text-green-500"><span className="">Amount Paid</span>:<span className="ml-3">{state.totalGuestPayment}</span></div>
                        </div>
                        <div className="col-6">
                            <div className="col-12 font-bold text-2xl text-pink-300"><span className="">Change</span>:<span className="ml-3">{state.paymentChange<0?`(${Math.abs(state.paymentChange)})`:state.paymentChange}</span></div>
                        </div>
                    </div>
                    <Divider />
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <div className="fied mt-2">
                                <label>Amount Paying</label>
                                <InputNumber className="mt-2 mb-2" onChange={onPaymentAmountChange} value={state.amount} onFocus={onInputControlFocus} onBlur={onInputControlFocus}/>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <FilterSelect selectableOptions={state.paymentMethods} selectedOption={state.selectedPaymentMethod} onSelectChange={(e) => setStateValues({ selectedPaymentMethod: e.value, paymentMode: e.value.code })} elementId="amountPaying" defaultValue="Select Method" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="fied mt-2">
                            <Button onClick={completePayment}>{state.crudType==='save'?'Add Payment':'Update Payment'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card w-auto">
                    <SimpleTable
                        tableKey={'paymentId'}
                        columnsDef={[
                            { body: (rowData: TPayment) => <div>{format(new Date(rowData.paymentDate), 'yyyy-MM-dd')}</div>, header: 'Date' },
                            { field: 'amount', header: 'Paid Amount' },
                            { field: 'paymentChange', header: 'Change' },
                            { field: 'paymentMode', header: 'Mode' },
                            { body: (rowData: TPayment) => tableEditOption(setupPaymentEdit, promptPaymentDelete, rowData.paymentId!.toString()), header: 'Edit' }
                        ]}
                        tableData={state.paymentsList}
                        tableTitle="Payments List"
                        lastTableUpdate={new Date().getTime()}
                        searchValues={['reservationDate']}
                        searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                        tableMinWidth={48}
                    />
                </div>
            </div>
        </div>
        <div className="hidden">
            <POSPrinter
                reservationDetails={reservationDetails!}
                ref={componentRef}
                userData={userData}
                amountPaid={state.totalGuestPayment!}
                invoiceNumber={state.invoiceNumber!}
            />
        </div>
    </>;
};
export default AcceptPayment;
