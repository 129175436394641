import { THotel, THotelLocation, TUser } from '../types';
import { getBaseURL } from '../utilFunctions';
import axiosFetch from '../axiosConfig';

class RegistrationObject {
    async registerNewHotel(personDetails:TUser,hotelDetails:THotel,hotelLocation:THotelLocation,crudType:string){
        const data=await axiosFetch('POST',`${getBaseURL()}/registration/register`,{personDetails:{...personDetails,crudType},
            hotelDetails:{...hotelDetails,crudType},hotelLocation:{...hotelLocation,crudType}});
        return {status:data.data?.status}
    }
}
export default RegistrationObject;
