import axiosFetch from '../axiosConfig';
import { TCrudType, TPayment } from '../types';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import { Queue } from './Queue';
const queue = new Queue();
class Payments {
    async acceptNewPayment(paymentDetails:TPayment,crudType:TCrudType){
        const data=await axiosFetch<TPayment>('POST',`${getBaseURL()}/booking_reservations/new_payment`,{requestBody:{...paymentDetails,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData};
    }
    async addOfflinePayment(paymentData: TPayment,crudType:TCrudType){
        return crudType==='save'?await this.addLocalPayment(paymentData):this.updateLocalPayment(paymentData);
    }
    async addLocalPayment(payment: TPayment){
        delete payment.paymentId;
        try{
            return db.transaction('rw',[db.payments,db.queuedTables],async()=>{
                const newPaymentId=await db.payments.add(payment);
                const newPayment=await db.payments.get(newPaymentId);
                await queue.enqueue<TPayment>(newPayment!,'payments');
                return {status:200,operatedData:newPayment}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalPayment(payment: TPayment){
        try{
            return db.transaction('rw',[db.payments,db.queuedTables],async()=>{
                const updatedItemId=await db.payments.put(payment);
                await queue.updateQueue<TPayment>(payment,'payments','paymentId',updatedItemId! as number);
                return {status:200,operatedData:payment}
            });
        }catch(error){
            throw error;
        }
    }
    async getOfflinePayments(activityId:number,paymentType:string){
        try{
            return db.transaction('rw',[db.payments],async()=>{
                return db.payments.where({activityId:activityId,paymentType}).toArray();
            });
        }catch(error){
            throw error;
        }
    }
}
export default Payments;
