import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import useUserData from './hooks/customHooks';
import { Avatar } from 'primereact/avatar';

const AppInlineMenu = (props: any) => {
    const navigate=useNavigate();
    const menuRef = useRef(null);
    const [userData]=useUserData();

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };
    const logout=()=>{
        localStorage.removeItem('userData');
        navigate('/login');
    }
    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                    <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                        <span className="layout-inline-menu-text">{userData.username}</span>
                        <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                    </button>
                    <CSSTransition nodeRef={menuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={logout}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={()=>navigate('/settings')}>
                                    <i className="pi pi-cog pi-fw"></i>
                                    <span>Settings</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link">
                                    <i className="pi pi-user pi-fw"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default AppInlineMenu;
