import { Button } from 'primereact/button';
import React from 'react';
type ToolBarItem={
    title: string,
    icon: string,
    tooltip?: string,
    onClickEvent:()=>void
}
type TLeftSideProps = {
    firstButton?:ToolBarItem;
    secondButton?:ToolBarItem;
    thirdButton?:ToolBarItem;
    fourthButton?:ToolBarItem
}
type TRightSideProps={
    onNewButtonClick?: () => void;
}
export const RightToolBar=({onNewButtonClick}:TRightSideProps)=><Button label="New" icon="pi pi-plus" style={{ marginRight: '.5em' }} onClick={onNewButtonClick}/>;
export const LeftToolBar=({firstButton,secondButton,thirdButton,fourthButton}:TLeftSideProps)=>{

    return (
        <>
            <Button label={firstButton?.title} icon={firstButton?.icon} style={{ marginRight: '.5em' }} onClick={firstButton?.onClickEvent}/>
            {secondButton && <Button label={secondButton?.title} icon={secondButton?.icon} className="p-button-secondary" onClick={secondButton.onClickEvent}/>}

            <i className="pi pi-bars p-toolbar-separator" style={{ marginRight: '.5em' }}></i>

            {thirdButton && <Button label={thirdButton.title} icon={thirdButton.icon} className="p-button-warning" style={{ marginRight: '.5em' }} />}
            {fourthButton && <Button label={fourthButton?.title} icon={fourthButton?.icon} className="p-button-success" style={{ marginRight: '.5em' }} />}
        </>
    );
}
