import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import { Fragment, useEffect, useReducer, useRef } from 'react';
import { TCalenderSettings, TLocationSettings, TReservations } from '../../utilities/types';
import { defaultSettings, displayMessage, generateRandomColor, isAppOnline, REDUCER_ACTION_TYPE, StateReducerHandler } from '../../utilities/utilFunctions';
import { DayCellContentArg, EventClickArg, EventContentArg, EventDropArg, EventInput, MoreLinkArg, WeekNumberContentArg } from '@fullcalendar/core';
import { Tag } from 'primereact/tag';
import { confirmPopup } from 'primereact/confirmpopup';
import { Badge } from 'primereact/badge';
import { Sidebar } from 'primereact/sidebar';
import { BigButton, CheckboxInput, GeneralPageProps, Loader, RadioButtonInput, ReservationMoreDetails } from '../../utilities/components/UtilityComponents';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import { CalendarChangeEvent } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import ReservationsMain from '../reservations/ReservationsMain';
import { hotelLocationReservationsListQuery } from '../../utilities/reactQueryUtils';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import Settings from '../../utilities/classObjects/Settings';
import { useQueryClient } from '@tanstack/react-query';
import useUserData from '../../hooks/customHooks';
import GuestsMain from '../guests/GuestsMain';
import GuestReservations from '../guestReservations/GuestReservations';
import { addBusinessDays } from 'date-fns';


interface BookingEventInput extends EventInput {
    allDay:boolean,
    start: Date,
    end: Date,
    backgroundColor: string,
    extendedProps: TReservations;
}

type TCalendarBookState = {
    toolBarMenu: string[];
    userSettings: TCalenderSettings,
    calendarEvents: BookingEventInput[];
    showReservationDialog: boolean,
    sideBarState: boolean,
    isLoading: boolean,
    appSettings: TLocationSettings
    panelInfo: TReservations;
    showSideBar: boolean,
    showActivitySelect: boolean,
    showAdditionalDialog: boolean,
    showGuestsDialog: boolean,
    showGuestReservationDialog: boolean
}
const INITIAL_sTATE: TCalendarBookState = {
    toolBarMenu: ['dayGridMonth', 'timeGridDay'],
    userSettings: {
        showYearGrid: false,
        showWeekGrid: false,
        initialView: 'dayGridMonth',
        showWeekend: true,
        allowScroll: true,
        showListView: false
    },
    calendarEvents: [],
    showReservationDialog: false,
    sideBarState: false,
    isLoading: false,
    appSettings: defaultSettings(),
    panelInfo: {},
    showSideBar: false,
    showActivitySelect: false,
    showAdditionalDialog: false,
    showGuestsDialog: false,
    showGuestReservationDialog: false
};

const reservation = new BookingReservation();
const settings = new Settings();
const CalendarBooking = () => {
    const [state, dispatch] = useReducer(StateReducerHandler<TCalendarBookState>, INITIAL_sTATE);
    const calenderRef = useRef<FullCalendar>(null);
    const toastRef = useRef(null);
    const queryClient = useQueryClient();
    const [userData] = useUserData();


    useEffect(() => {
        const initCalendarBooking = async () => {
            const appSettings = await settings.getLocationSettings();
            const reservationsList = isAppOnline(appSettings.connectivityState) ? await hotelLocationReservationsListQuery(queryClient, userData.hotelLocationId)
                : await reservation.getOfflineReservationsList(userData.hotelLocationId);
            setStateValues({
                calendarEvents: reservationsList?.map((reservation) => {
                    return {allDay:true, backgroundColor: generateRandomColor(), start: new Date(reservation.checkInDate!), end: new Date(reservation.checkOutDate!), extendedProps: reservation };
                })
            });
        };
        initCalendarBooking().catch(console.error);
    }, []);

    const setStateValues = (stateValues: Partial<TCalendarBookState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };

    function renderEventContent(eventInfo: EventContentArg) {
        return (
            <>
                <Tag value={`${eventInfo.event.extendedProps.guestName} [${eventInfo.event.extendedProps.roomNumber}]`}></Tag>
            </>
        );
    }

    const renderWeekNumberContent = (args: WeekNumberContentArg) => {
        return <span className="text-2xl">{args.num}</span>;
    };
    const eventClicked = (args: EventClickArg) => {
        setStateValues({ panelInfo: args.event.extendedProps, showSideBar: true });
    };
    const onEventDragDrop = async (eventDropInfo: EventDropArg) => {
        // console.log(eventDropInfo);
        displayMessage({
            header: 'Event Dragged!',
            message: 'You dragged and dropped a booking. Please know that nothing happened and your data is not affected!',
            life: 5000,
            infoType: 'warn',
            toastComponent: toastRef
        });
        // const newEvent = eventDropInfo.event;
        // const appointmentDate = `${format(new Date(newEvent.startStr), 'yyyy-MM-dd')} ${format(new Date(newEvent.startStr), 'HH:mm')}`;
        // const appointmentId = newEvent.id;
        // const appointmentPatientId = newEvent.extendedProps.patientId;
        // const appointmentInfo: TAppointment = { appointmentDate, appointmentId, appointmentPatientId };
        // setStateValues({ isLoading: true });
        // await performAppointmentUpdate(appointmentInfo);
    };
    const dateClicked = (args: DateClickArg) => {
        confirmPopup({
            target: args.dayEl,
            message: 'Do you want to add new Calendar Activity?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success',
            rejectClassName: 'p-button-danger',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            accept: () => {
                setStateValues({
                    showActivitySelect: true
                    // appointmentDate: new Date(args.dateStr)
                });
            },
            style: { width: '400px' }
        });
    };

    function renderDayNumber(arg: DayCellContentArg) {
        return <Badge value={arg.dayNumberText} size="large" severity="warning"></Badge>;
    }

    const onClickMoreLink = (args: MoreLinkArg) => {
    };
    const arrangeArray = (arr: string[]) => {
        // Define the desired order of elements
        const desiredOrder = ['multiMonthYear', 'dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek'];

        // Filter the input array to include only the elements in the desired order
        const arrangedArray = desiredOrder.filter((item) => arr.includes(item));

        // Add any remaining elements from the input array
        arr.forEach((item) => {
            if (!arrangedArray.includes(item)) {
                arrangedArray.push(item);
            }
        });

        return arrangedArray;
    };
    const setupVisibleOptions = (stateProperty: string, stateValue: string, actionValue: boolean | string) => {
        const userSettings = {
            ...state.userSettings,
            [stateProperty]: actionValue
        };
        let menuArr = [];
        if (typeof actionValue === 'boolean' && actionValue) {
            menuArr = [stateValue, ...state.toolBarMenu];
        } else {
            menuArr = state.toolBarMenu.filter((menu: any) => menu !== stateValue);
        }
        const toolBarMenu = arrangeArray(menuArr);
        setStateValues({ ...state, userSettings, toolBarMenu });
    };
    const setUserSetting = (e: CheckboxChangeEvent) => {
        setupVisibleOptions(e.target.id, e.target.name, e.checked!);
    };
    const setInitialView = (e: RadioButtonChangeEvent) => {
        const userSettings: TCalenderSettings = {
            ...state.userSettings,
            initialView: e.target.id
        };

        setStateValues({ userSettings: userSettings });

        setupVisibleOptions('initialView', '', e.target.id);

        calenderRef.current?.getApi().changeView(e.target.id);
    };
    const setShowWeekend = (e: CalendarChangeEvent) => {
        const userSettings: TCalenderSettings = { ...state.userSettings, showWeekend: e.checked! };

        setStateValues({ ...state, userSettings });
    };
    const setAllowDragDrop = (e: CalendarChangeEvent) => {
        const userSettings: TCalenderSettings = { ...state.userSettings, allowScroll: e.checked! };
        setStateValues({ ...state, userSettings });
    };
    const CalendarMenu = () => {
        return (
            <Fragment>
                <div className="flex align-items-center justify-content-between mb-4">
                    <h5>Bookings Calendar</h5>
                    <h5 className="underline text-blue-600 cursor-pointer" onClick={(event) => setStateValues({ sideBarState: true })}>Customise Calendar</h5>
                </div>
            </Fragment>
        );
    };

    return <>
        {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid grid mt-2">
                <div className="card">
                    <div className="lg:col-12">
                        <CalendarMenu />
                        <FullCalendar
                            ref={calenderRef}
                            plugins={[multiMonthPlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            initialView={state.userSettings.initialView}
                            headerToolbar={{
                                left: 'prev,next today', // Left-aligned buttons
                                center: 'title', // Centered title
                                right: state.toolBarMenu.join(',') // Right-aligned buttons
                            }}
                            views={{
                                multiMonthYear: { buttonText: 'Year' },
                                dayGridMonth: { buttonText: 'Month' },
                                timeGridWeek: { buttonText: 'Week' },
                                timeGridDay: { buttonText: 'Day' },
                                listWeek: { buttonText: 'List' }
                            }}
                            weekends={state.userSettings.showWeekend}
                            events={state.calendarEvents}
                            eventContent={renderEventContent}
                            weekNumberContent={renderWeekNumberContent}
                            eventClick={eventClicked}
                            eventDrop={onEventDragDrop}
                            droppable={true}
                            dateClick={dateClicked}
                            editable={true}
                            selectable={state.userSettings.allowScroll}
                            dragScroll={state.userSettings.allowScroll}
                            showNonCurrentDates={false}
                            fixedWeekCount={false}
                            eventMaxStack={3}
                            dayMaxEvents={2}
                            height="auto"
                            dayCellContent={renderDayNumber}
                            moreLinkClick={onClickMoreLink}
                        />
                    </div>
                </div>
            </div>
            <Sidebar visible={state.sideBarState} position="right" onHide={() => setStateValues({ sideBarState: false })}>
                <h6>Calendar Settings</h6>
                <div className="card p-fluid">
                    <h5>Plugins</h5>
                    <CheckboxInput inputName="multiMonthYear" checkLabel="Show Year Option" checkedState={state.userSettings.showYearGrid} inputId="showYearGrid" onCheckChange={setUserSetting} />
                    <CheckboxInput inputName="timeGridWeek" checkLabel="Show Week Option" checkedState={state.userSettings.showWeekGrid} inputId="showWeekGrid" onCheckChange={setUserSetting} />
                    <CheckboxInput inputName="listWeek" checkLabel="Show List View" checkedState={state.userSettings.showListView} inputId="showListView" onCheckChange={setUserSetting} />
                </div>
                <div className="card p-fluid">
                    <h5>Initial View</h5>
                    <RadioButtonInput radioLabel="Year" inputId="multiMonthYear" inputName="initialYear" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'multiMonthYear'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Month" inputId="dayGridMonth" inputName="initialMonth" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'dayGridMonth'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Week" inputId="timeGridWeek" inputName="initialWeek" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'timeGridWeek'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Day" inputId="timeGridDay" inputName="initialDay" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'timeGridDay'} customClasses="mt-2" />
                </div>
                <div className="card p-fluid">
                    <h5>Other Options</h5>
                    <CheckboxInput inputName="visibleWeekendRadio" checkLabel="Show Weekends" checkedState={state.userSettings.showWeekend} inputId="showWeekend" onCheckChange={setShowWeekend} />
                    <CheckboxInput inputName="chkAllowDragDrop" checkLabel="Allow Drag/Drop" checkedState={state.userSettings.allowScroll} inputId="allowDragDrop" onCheckChange={setAllowDragDrop} />
                </div>
            </Sidebar>
            {state.showAdditionalDialog && <Dialog onHide={() => setStateValues({ showAdditionalDialog: false, showGuestsDialog: false, showReservationDialog: false, showGuestReservationDialog: false })} visible={state.showAdditionalDialog} maximized>
                {state.showReservationDialog==true ? <ReservationsMain /> : state.showGuestsDialog==true ? <GuestsMain /> : <GuestReservations />}
            </Dialog>}
            <Dialog header="Select Calendar Activity" onHide={() => setStateValues({ showActivitySelect: false })} visible={state.showActivitySelect} className="w-6 h-12rem align-content-center justify-content-center">
                <div className="flex align-items-center justify-content-center">
                    <div className="col-4">
                        <BigButton buttonAction={() => setStateValues({ showAdditionalDialog: true, showGuestsDialog: true })} buttonTitle="Add Guest" />
                    </div>
                    <div className="col-4">
                        <BigButton buttonAction={() => setStateValues({ showAdditionalDialog: true, showReservationDialog: true })} buttonTitle="Add Reservation" />
                    </div>
                    <div className="col-4">
                        <BigButton buttonAction={() => setStateValues({ showAdditionalDialog: true, showGuestReservationDialog: true })} buttonTitle="Guest/Reservation" />
                    </div>
                </div>
            </Dialog>
            <Sidebar onHide={() => setStateValues({ showSideBar: false })} position="top" visible={state.showSideBar} className="h-20rem">
                <ReservationMoreDetails panelInfo={state.panelInfo} />
            </Sidebar>
    </>;
};
export default CalendarBooking;
