import { db } from '../../offline/db';
const _=require('lodash');

class Queue {
    async initialize(){
        const queuedData=await db.queuedTables.get(1);
        if(queuedData){
            await db.queuedTables.put({
                tableId:1,
                roomTypes:queuedData.roomTypes!==undefined?queuedData.roomTypes:[],
                hotelRooms:queuedData.hotelRooms!==undefined?queuedData.hotelRooms:[],
                hotelGuests:queuedData.hotelGuests!==undefined?queuedData.hotelGuests:[],
                hotelReservations:queuedData.hotelReservations!==undefined?queuedData.hotelReservations:[],
                hotelEvents:queuedData.hotelEvents!==undefined?queuedData.hotelEvents:[],
                hotelStaff:queuedData.hotelStaff!==undefined?queuedData.hotelStaff:[],
                hotels:queuedData.hotels!==undefined?queuedData.hotels:[],
                hotelLocation:queuedData.hotelLocation!==undefined?queuedData.hotelLocation:[],
                expenditures:queuedData.expenditures!==undefined?queuedData.expenditures:[],
                items:queuedData.items!==undefined?queuedData.items:[],
                purchases:queuedData.purchases!==undefined?queuedData.purchases:[],
                adjustments:queuedData.adjustments!==undefined?queuedData.adjustments:[],
                sales:queuedData.sales!==undefined?queuedData.sales:[],
                eventTypes:queuedData.eventTypes!==undefined?queuedData.eventTypes:[],
                brands:queuedData.brands!==undefined?queuedData.brands:[],
                payments:queuedData.payments!==undefined?queuedData.payments:[],
                expenditureCategory:queuedData.expenditureCategory!==undefined?queuedData.expenditureCategory:[]
            });
        }else{
            await this.createNew()
        }
    }

    async enqueue<T>(item:T,tableDescription:string){
        try{
           db.transaction('rw',db.queuedTables,async()=>{
                const tableOnlyRecord=await db.queuedTables.get(1);
                let updatedTableData=tableOnlyRecord![tableDescription];
                updatedTableData.push(item);
                tableOnlyRecord![tableDescription]=updatedTableData;
                await db.queuedTables.put(tableOnlyRecord!);
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
    async updateQueue<T>(item:T,tableDescription:string,tableIdProperty:keyof T,updatingItemId:number){
        try{
            db.transaction('rw',db.queuedTables,async()=>{
                const tableOnlyRecord = await db.queuedTables.get(1);
                let updatedTableData = tableOnlyRecord![tableDescription];
                const index=_.findIndex(updatedTableData,{[tableIdProperty]:updatingItemId});
                if(index!==-1){
                    updatedTableData[index]=item;
                    await db.queuedTables.update(1,{[tableDescription]:updatedTableData});
                }
            })
        }catch(error){
            throw error;
        }
    }
    async createNew(){
        try{
            await db.queuedTables.put({
                tableId:1,
                roomTypes:[],
                hotelRooms:[],
                hotelGuests:[],
                hotelReservations:[],
                hotelEvents:[],
                eventTypes:[],
                hotelStaff:[],
                hotels:[],
                hotelLocation:[],
                expenditures:[],
                items:[],
                purchases:[],
                adjustments:[],
                sales:[],
                brands:[],
                payments:[],
                expenditureCategory:[]
            });
        }catch(error){
            throw error;
        }
    }
}
export { Queue };
