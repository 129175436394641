import SimpleTable from '../../utilities/components/SimpleTable';
import { TEventTypes, TTableProps } from '../../utilities/types';
import { tableEditOption } from '../../utilities/components/UtilityComponents';
import React from 'react';

const HotelEventTypesList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<TEventTypes>)=>{

    return <>
        <SimpleTable
            tableKey="eventTypeId"
            columnsDef={[
                {field:'eventTypeDesc',header:'Event Type'},
                {
                    body: (rowData: TEventTypes) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.eventTypeId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Event Types'}
            searchValues={['eventTypeDesc']}
            tableMinWidth={30}
        />
    </>
}
export default HotelEventTypesList;
