import SimpleTable from '../../utilities/components/SimpleTable';
import { TEventTypes, TExpenditureCategory, TTableProps } from '../../utilities/types';
import { tableEditOption } from '../../utilities/components/UtilityComponents';
import React from 'react';

const ExpenseCategoryList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<TExpenditureCategory>)=>{
    return <>
        <SimpleTable
            tableKey="categoryId"
            columnsDef={[
                {field:'categoryDescription',header:'Category'},
                {
                    body: (rowData: TExpenditureCategory) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.categoryId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Expense Categories'}
            searchValues={['categoryDescription']}
            tableMinWidth={30}
        />
    </>
}
export default ExpenseCategoryList;
