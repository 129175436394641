import { TCategory, TCrudType, TExpenditure, TExpenditureCategory, THotel } from '../types';
import axiosFetch from '../axiosConfig';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import { Queue } from './Queue';
const queue = new Queue();
class Expenses {
    async addNewExpenditure(expenditure:TExpenditure,crudType:TCrudType){
        const data=await axiosFetch<TExpenditure>('POST',`${getBaseURL()}/expenditure/new_expenditure`,{requestBody:{...expenditure,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData};
    }
    async addNewExpenditureCategory(expenditureCategory:TCategory,crudType:TCrudType){
        const data=await axiosFetch<TExpenditureCategory>('POST',`${getBaseURL()}/expenditure/new_category`,{requestBody:{...expenditureCategory,crudType}});
        return {status:data.status,operatedData:data.data?.operatedData};
    }
    async addExpenditureOffline(expenditure:TExpenditure,crudType:TCrudType){
        return crudType==='save'?await this.addExpenditureLocal(expenditure):await this.updateExpenditureLocal(expenditure);
    }
    async addExpenditureLocal(expenditure:TExpenditure){
        delete expenditure.expenditureId;
        try{
            return db.transaction('rw',[db.expenses,db.queuedTables],async()=>{
                const newExpenditureId=await db.expenses.add(expenditure);
                const newExpenditure=await db.expenses.get(newExpenditureId);
                await queue.enqueue<TExpenditure>(newExpenditure!,'expenditures');
                return {status:200,operatedData:newExpenditure}
            });
        }catch(error){
            throw error;
        }
    }
    async updateExpenditureLocal(expenditure:TExpenditure){
        try{
            return db.transaction('rw',[db.expenses,db.queuedTables],async()=>{
                const updatedExpenditureId=await db.expenses.put(expenditure);
                console.log(updatedExpenditureId);
                await queue.updateQueue<TExpenditure>(expenditure,'expenditures','expenditureId',updatedExpenditureId!);
                return {status:200,operatedData:expenditure}
            });
        }catch(error){
            throw error;
        }
    }
    async getExpendituresOffline(hotelLocationId:number){
        return db.expenses.where({locationId:hotelLocationId}).toArray();
    }

    async addOfflineCategory(category:TExpenditureCategory,crudType:TCrudType){
        return crudType==='save'?await this.addLocalExpenseCategory(category):await this.updateLocalExpenseCategory(category);
    }
    async addLocalExpenseCategory(categoryData:TExpenditureCategory){
        delete categoryData.categoryId;
        try{
            return db.transaction('rw',[db.expenditureCategory,db.queuedTables],async()=>{
                const newCategoryId=await db.expenditureCategory.add(categoryData);
                const newCategory=await db.expenditureCategory.get(newCategoryId);
                await queue.enqueue<TExpenditureCategory>(newCategory!,'expenditureCategory');
                return {status:200,operatedData:newCategory}
            });
        }catch(error){
            throw error;
        }
    }
    async updateLocalExpenseCategory(categoryData:TExpenditureCategory){
        try{
            return db.transaction('rw',[db.expenditureCategory,db.queuedTables],async()=>{
                const updatedExpenditureId=await db.expenditureCategory.put(categoryData);
                await queue.updateQueue<TExpenditureCategory>(categoryData,'expenditureCategory','categoryId',updatedExpenditureId!);
                return {status:200,operatedData:categoryData}
            });
        }catch(error){
            throw error;
        }
    }
    async getExpenditureCategoriesOffline(hotelLocationId:number){
        return db.expenditureCategory.where({hotelLocationId}).toArray();
    }
}
export default Expenses;
