import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { InputNumber, InputNumberChangeEvent, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import Joi from 'joi';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useQueryClient } from '@tanstack/react-query';
import { DropdownOption, TItem, TLocationSettings, TSales, TSalesItem, TSelectedPurchaseListItem } from '../../../utilities/types';
import Items from '../../../utilities/classObjects/Items';
import useUserData from '../../../hooks/customHooks';
import { changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId, isAppOnline, makeSelectableItemList, onInputControlFocus, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../../../utilities/utilFunctions';
import { DatePicker, DiscountComponent, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, tableEditOption } from '../../../utilities/components/UtilityComponents';
import { addRecordToCache, updateCacheRecord } from '../../../utilities/reactQueryFunctions';
import SimpleTable from '../../../utilities/components/SimpleTable';
import { Menubar } from 'primereact/menubar';
import { hotelLocationGuestsListQuery, itemsListQuery, salesListQuery } from '../../../utilities/reactQueryUtils';
import Settings from '../../../utilities/classObjects/Settings';
import { validateSales } from '../../../utilities/joiFunctions/joiFunctions';
const _ = require('lodash');

type TSalesState = TSales & {
    isLoading: boolean;
    showSalesDialog: boolean;
    salesItemsList: DropdownOption[];
    selectedSaleItem: DropdownOption;
    editState: boolean;
    paymentChange: number;
    editingItemId: number;
    quantityEditOptions: string[];
    selectedEditOption: string;
    editQuantityValue: number;
    editingItemOriginalQty: number;
    itemsInEdit: TSalesItem[];
    discountOptions: string[];
    discountValue: string;
    guestsList:DropdownOption[],
    itemsList:TItem[],
    salesList:TSales[],
    selectedGuest:DropdownOption,
    appSettings:TLocationSettings,
    displayDate:Date
};
const INITIAL_STATE: TSalesState = {
    guestId: 0,
    amountPaid: 0,
    modifiedBy: 0,
    salesDate: changeDateFormat(new Date()),
    salesId: 0,
    salesItems: [],
    isLoading: false,
    showSalesDialog: false,
    salesItemsList: [],
    selectedSaleItem: {name:'',code:0},
    editState: false,
    paymentChange: 0,
    editingItemId: 0,
    quantityEditOptions: ['Add', 'Return'],
    selectedEditOption: '',
    editQuantityValue: 0,
    editingItemOriginalQty: 0,
    itemsInEdit: [],
    salesDiscount: { discountOption: 'Cash Discount', discountPercentage: 0, discountAmount: '0' },
    discountOptions: ['Cash Discount', 'Percentage Discount'],
    discountValue: '0',
    guestsList:[],
    itemsList:[],
    salesList:[],
    selectedGuest:{name:'',code:0},
    appSettings:defaultSettings(),
    hotelId:0,
    hotelLocationId:0,
    displayDate:new Date(),
    discountAmount:'0'
};
const items = new Items();
const validateItemQuantityAdd = Joi.object({
    selectedEditOption: Joi.string().messages({ 'string.empty': 'Select Action for quantity edit!' }),
    editQuantityValue: Joi.number().min(1).messages({ 'number.min': 'Quantity value should be more than 0','number.base':'Enter a valid number' })
});
const settings = new Settings();
const Sales = () => {
    const queryClient = useQueryClient();
    const [salesState, setSalesState] = useState<TSalesState>(INITIAL_STATE);
    const toastRef = useRef(null);
    const [userData] = useUserData();
    const quantityEditRef = useRef<OverlayPanel>(null);
    const discountOPRef = useRef<OverlayPanel>(null);

    useEffect(() => {
        const initSales=async ()=>{
            const appSettings = await settings.getLocationSettings();
            const guests=await hotelLocationGuestsListQuery(queryClient,userData.staffId);
            const itemsList=await itemsListQuery(queryClient,userData.staffId);
            const salesList=await querySalesList(appSettings.connectivityState)
            setStateValue({
                guestsList: remakeDropdownSelects(guests!, 'guestName', 'guestId'),
                salesItemsList: makeSelectableItemList(itemsList!),
                modifiedBy: userData.staffId,
                salesId: 0,
                itemsList:itemsList,
                appSettings,
                salesList:salesList,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId
            });
        }
        initSales().catch(console.error);
    }, []);
    const querySalesList=async (appState:string)=>{
        return isAppOnline(appState)?await salesListQuery(queryClient,userData.staffId):await items.getOfflineSales(userData.hotelLocationId);
    }
    const setStateValue = (stateValues: Partial<TSalesState>) => {
        setSalesState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getStateValues = (): TSales => {
        const props: Array<keyof TSales> = ['salesId','guestId','salesDate','salesItems','modifiedBy','amountPaid','amountPaid','salesDiscount','modifiedBy','hotelId','hotelLocationId'];
        return extractStateValues<TSales>(props, salesState);
    };
    const salesMenu = () => {
        return [
            {
                label: 'New Sales',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showSalesDialog: true })
            }
        ];
    };
    const onSalesDialogHide = () => {
        setStateValue({ showSalesDialog: false, editState: false, salesItems: [], guestId: 0 });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ selectedGuest:e.value,guestId:e.value.code });
    };
    const onItemSelectionChange = (e: DropdownChangeEvent) => {
        const selectedItem: TItem = _.find(salesState.itemsList, (listItem: TItem) => listItem.itemId === e.value.code);
        const { itemId, itemName, itemCost, itemPrice } = selectedItem;
        setStateValue({
            selectedSaleItem: e.value,
            salesItems: [
                ...salesState.salesItems,
                {
                    itemId,
                    itemName,
                    itemQty: 1,
                    itemCost: parseFloat(itemCost),
                    itemPrice: parseFloat(itemPrice),
                    qtyAdded: 0,
                    qtyReturned: 0,
                    itemTotalCost: parseFloat(itemPrice)
                }
            ]
        });
    };
    const onItemPriceButtonClicked = async (e: InputNumberValueChangeEvent) => {
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === parseInt(e.target.name));
        if (editingItem !== undefined)
            await setItemPriceChange(editingItem,e.value!);
    };
    const itemSellPriceInput = (rowData: TSalesItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemPrice}
                                       inputButtonsClick={onItemPriceButtonClicked} numberInputName={rowData.itemId.toString()} inputValueChange={onItemSellPriceValueChange}/>;
    };
    const onItemSellPriceValueChange=async (e:InputNumberChangeEvent)=>{
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === parseInt(((e.originalEvent.target as HTMLElement).parentElement?.firstChild as HTMLElement).getAttribute('name')!));
        if(editingItem !== undefined)
            await setItemPriceChange(editingItem,e.value!);
    }
    const setItemPriceChange=async (editingItem:TSalesItem,itemPrice:number)=>{
        const updatingItem = { ...editingItem, itemPrice: itemPrice!, itemTotalCost: itemPrice! * editingItem.itemQty };
        const salesItems = await updateCacheItem<TSalesItem>(updatingItem, editingItem, salesState.salesItems);
        setStateValue({ salesItems });
    }
    const onSalesAmountValueChange=(e:InputNumberChangeEvent)=>{
        setStateValue({ amountPaid: e.value! });
    }
    const onItemQtyButtonClicked = async (e: InputNumberValueChangeEvent) => {
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === parseInt(e.target.name));
        if (editingItem !== undefined)
            await setItemQtyChange(editingItem,e.value!);
    };
    const itemQuantityInput = (rowData: TSalesItem) => {
        return <NumberInputWithButtons allowDecimalValues={false}
                                       disableState={salesState.editState} inputValue={rowData.itemQty} inputButtonsClick={onItemQtyButtonClicked}
                                       numberInputName={rowData.itemId.toString()} inputValueChange={onItemQuantityValueChange}/>;
    };
    const onItemQuantityValueChange=async (e:InputNumberChangeEvent)=>{
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === parseInt(((e.originalEvent.target as HTMLElement).parentElement?.firstChild as HTMLElement).getAttribute('name')!));
        if(editingItem !== undefined)
            await setItemQtyChange(editingItem, e.value!);
    }
    const setItemQtyChange=async (editingItem: TSalesItem,quantity:number) => {
        const updatingItem: TSalesItem = { ...editingItem, itemQty: quantity, itemTotalCost: editingItem.itemPrice * quantity };
        const newSaleItems = await updateCacheItem<TSalesItem>(updatingItem, editingItem, salesState.salesItems);
        setStateValue({ salesItems: newSaleItems, editQuantityValue: 0 });
    }
    const deleteItemFromList = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = parseInt(getTableRowId(e, 'id'));
        if (salesState.editState) {
            if (_.find(salesState.itemsInEdit, (listItem: TSalesItem) => listItem.itemId === itemId)) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Remove Lock',
                    message: 'Item cannot be removed from list. You can choose to return all the quantities!',
                    infoType: 'warn',
                    life: 5000
                });
                return;
            }
        }

        const listItems = salesState.salesItems.filter((item) => item.itemId !== itemId);
        setStateValue({ salesItems: listItems });
    };
    const getItemsTotalCost = () => {
        return salesState.salesItems.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.itemTotalCost;
        }, 0);
    };
    const onSalesAmountPaidChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ amountPaid: e.value! });
    };

    const getPaymentChange = () => {
        const paymentChange = getItemsTotalCost() - (salesState.amountPaid + parseFloat(salesState.salesDiscount.discountAmount));
        return paymentChange > 0 ? 0 : Math.abs(paymentChange);
    };
    const onCompleteSale = async () => {
        try {
            if (!pageDataValidation(validateSales, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const salesResponse = isAppOnline(salesState.appSettings.connectivityState)?await items.newSales(getStateValues()):await items.addLocalSales(getStateValues());

            if (salesResponse.status === 200) {
                await addRecordToCache<TSales>(queryClient, ['salesList',userData.hotelLocationId], salesResponse.operatedData!);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Sales was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupSalesEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const salesId = parseInt(getTableRowId(e, 'id'));
        const selectedSales: TSales = _.find(salesState.salesList, (listItem: TSales) => listItem.salesId === salesId);
        if (selectedSales !== undefined) {
            const { salesId, salesDate, salesItems, guestId, modifiedBy, amountPaid, discountAmount,hotelId,hotelLocationId } = selectedSales;
            const parsedSaleItems = typeof salesItems !== 'object' ? JSON.parse(salesItems) : salesItems;
            const listItems = parsedSaleItems.map((listItem: TSalesItem) => {
                return { ...listItem, itemTotalCost: (listItem.itemQty - listItem.qtyReturned + listItem.qtyAdded) * listItem.itemPrice };
            });
            setStateValue({
                salesId,
                salesDate: changeDateFormat(new Date(salesDate)),
                guestId,
                salesItems: listItems,
                amountPaid,
                modifiedBy,
                showSalesDialog: true,
                editState: true,
                itemsInEdit: listItems,
                salesDiscount: { ...salesState.salesDiscount, discountAmount: discountAmount! },
                displayDate:new Date(salesDate),
                hotelId,
                hotelLocationId,
            });
        }
    };
    const deleteSales = () => {};
    const editItemQty = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = parseInt(getTableRowId(e, 'name'));
        const selectedItem: TSalesItem = _.find(salesState.salesItems, (listItem: TSalesItem) => listItem.itemId === itemId);
        setStateValue({ editingItemId: itemId, editingItemOriginalQty: selectedItem.itemQty });
        quantityEditRef.current?.toggle(e);
    };
    const completeQuantityEditAction = async () => {
        if (!pageDataValidation(validateItemQuantityAdd, { selectedEditOption: salesState.selectedEditOption, editQuantityValue: salesState.editQuantityValue }, toastRef)) {
            return;
        }
        const affectingItem: TSalesItem = _.find(salesState.salesItems, (listItem: TSalesItem) => listItem.itemId === salesState.editingItemId);
        const addedQty = salesState.selectedEditOption === 'Add' ? salesState.editQuantityValue : 0;
        const returnedQty = salesState.selectedEditOption === 'Return' ? salesState.editQuantityValue : 0;
        const itemTotalCost = (affectingItem.itemQty - (affectingItem.qtyReturned + returnedQty) + (affectingItem.qtyAdded + addedQty)) * affectingItem.itemPrice;

        //check if item exists among editing items;
        if (!_.find(salesState.itemsInEdit, (saleItem: TSalesItem) => saleItem.itemId === salesState.editingItemId)) {
            const updatedItem: TSalesItem = { ...affectingItem, itemQty: addedQty, itemTotalCost: affectingItem.itemPrice * addedQty };
            const newItems = await updateCacheItem<TSalesItem>(updatedItem, affectingItem, salesState.salesItems);
            setStateValue({ salesItems: newItems, editQuantityValue: 0 });
            return;
        }
        const affectingItemEdited: TSalesItem = { ...affectingItem, qtyReturned: returnedQty + affectingItem.qtyReturned, qtyAdded: addedQty + affectingItem.qtyAdded, itemTotalCost };
        const newSalesItems = await updateCacheItem<TSalesItem>(affectingItemEdited, affectingItem, salesState.salesItems);
        setStateValue({ salesItems: newSalesItems, editQuantityValue: 0 });
    };
    const updateSales = async () => {
        try {
            if (!pageDataValidation<TSales>(validateSales, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const salesUpdateResponse = isAppOnline(salesState.appSettings.connectivityState)?await items.updateSales(getStateValues()):await items.updateLocalSales(getStateValues());
            if (salesUpdateResponse.status === 200) {
                await updateCacheRecord<TSales>(queryClient, ['salesList',userData.hotelLocationId], [salesUpdateResponse.operatedData, salesState.salesId, 'salesId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Sale was successfully updated!',
                    infoType: 'success',
                    life: 5000
                });
                setStateValue({salesList:await querySalesList(salesState.appSettings.connectivityState)})
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const resetState = () => {
        setStateValue({ salesId:0, salesDate: changeDateFormat(new Date()), guestId: 0, salesItems: [], amountPaid: 0, salesDiscount: { discountAmount: '0', discountPercentage: 0, discountOption: '' } });
    };
    const onSelectButtonChange = (e: SelectButtonChangeEvent) => {
        if (e.value === 'Return' && !_.find(salesState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === salesState.editingItemId)) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: 'You cannot issue return for this item. It is just being added!',
                infoType: 'warn',
                life: 5000
            });
            return;
        }
        setStateValue({ selectedEditOption: e.value });
    };
    const onDiscountOptionChange = (e: SelectButtonChangeEvent) => {
        setStateValue({ salesDiscount: { ...salesState.salesDiscount, discountOption: e.value } });
    };
    const onDiscountAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValue({ discountValue: e.target.value });
    };
    const applyDiscount = () => {
        const totalSales = getItemsTotalCost();
        const discountPercent = salesState.salesDiscount.discountOption === 'Cash Discount' ? (parseFloat(salesState.discountValue) / totalSales) * 100 : parseFloat(salesState?.discountValue!);
        const discountAmount = salesState.salesDiscount.discountOption === 'Cash Discount' ? salesState.discountValue : ((parseFloat(salesState?.discountValue!) / 100) * totalSales).toFixed(2);
        setStateValue({ salesDiscount: { ...salesState.salesDiscount, discountPercentage: discountPercent, discountAmount } });
        displayMessage({
            toastComponent: toastRef,
            header: 'Discount Applied',
            message: 'Discount was successfully applied to sales',
            infoType: 'success',
            life: 5000
        });
    };
    return (
        <>
            {salesState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <Menubar model={salesMenu()}/>
                <SimpleTable
                    tableKey={'salesId'}
                    columnsDef={[
                        { body: (rowData: TSales) => <div>{changeDateFormat(new Date(rowData.salesDate))}</div>, header: 'Date' },
                        { field: 'locationName', header: 'Location' },
                        { field: 'guestName', header: 'Guest' },
                        { field: 'username', header: 'User' },
                        { body:()=><div><span className="underline text-blue-600 cursor-pointer">View More...</span></div>, header: 'View Items' },
                        { body: (rowData: TSales) => tableEditOption(setupSalesEdit, deleteSales, rowData.salesId?.toString()), header: 'Edit' }
                    ]}
                    tableData={salesState.salesList}
                    tableTitle="Sales List"
                    lastTableUpdate={new Date().getTime()}
                    searchValues={['fullName', 'salesDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Patient Name, Sales Date or Sales Person"
                />
            </div>
            <Dialog onHide={onSalesDialogHide} visible={salesState.showSalesDialog} maximized>
                <div className="lg:min-h-full">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <label htmlFor="salesDate">Sales Date</label>
                                    <DatePicker
                                        dateValue={salesState.displayDate}
                                        onDateChange={(e: CalendarChangeEvent) => setStateValue({ salesDate: changeDateFormat(e.value as Date),displayDate:e.value! as Date })}
                                        labelText="Sales Date"
                                        controlId="salesDate"
                                        selectionType="single"
                                        displayButtonBar={true}
                                        displayTime={false}
                                    />
                                </div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={salesState.guestsList} selectedOption={salesState.selectedGuest} onSelectChange={onSelectChange} elementId="patientId" defaultValue="Patients" />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={salesState.salesItemsList} selectedOption={salesState.selectedSaleItem!} onSelectChange={onItemSelectionChange} elementId="selectedSaleItem" defaultValue="Items" />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="grid">
                                <DataTable value={salesState.salesItems} emptyMessage="Select an item to continue">
                                    <Column
                                        header="Item"
                                        style={{ width: '30rem' }}
                                        body={(rowData: TSalesItem) => (
                                            <div>
                                                {`${rowData.itemName}`}{' '}
                                                <span>
                                                    {rowData.qtyReturned > 0 || rowData.qtyAdded > 0 ? (
                                                        <>
                                                            <Badge value={rowData.qtyReturned} severity="danger" className="mr-2" />
                                                            <Badge value={rowData.qtyAdded} severity="success" />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    ></Column>
                                    <Column header="Sell Price" style={{ width: '20rem' }} body={itemSellPriceInput}></Column>
                                    <Column header="Qty" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                                    <Column field="itemTotalCost" header="Total Cost" style={{ width: '20rem' }}></Column>
                                    <Column
                                        body={(rowData: TSalesItem) => (
                                            <div>
                                                <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId.toString()} onClick={deleteItemFromList} />

                                                <Button icon="pi pi-pencil" className="ml-2 p-button-warning" name={rowData.itemId.toString()} onClick={editItemQty} />
                                            </div>
                                        )}
                                        header="Edit"
                                        style={{ width: '10rem' }}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="p-fluid mt-4">
                            <div className="grid">
                                <div className="col-4">Total Cost</div>
                                <div className="col-4">{getItemsTotalCost()}</div>
                            </div>
                            <div className="grid mt-3">
                                <div className="col-4">Total Paid:</div>
                                <div className="col-2 align-items-start">
                                    <NumberInputWithButtons inputValue={salesState.amountPaid} inputButtonsClick={onSalesAmountPaidChange} numberInputId="salesAmountPaid" inputValueChange={onSalesAmountValueChange}/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-4">Discount</div>
                                <div className="col-4">{salesState.salesDiscount.discountAmount}</div>
                            </div>
                            <div className="grid">
                                <div className="col-4">Payment Change</div>
                                <div className="col-4">{getPaymentChange()}</div>
                            </div>
                            <div className="grid">
                                <div className="col-2">
                                    <Button onClick={(e) => discountOPRef.current?.toggle(e)}>Allow Discount</Button>
                                </div>
                                <div className="col-2">
                                    <Button onClick={!salesState.editState ? onCompleteSale : updateSales}>{!salesState.editState ? `Complete Sales` : `Update Sales`}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <OverlayPanel ref={quantityEditRef} className="lg:w-3">
                <div className="card">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <label>Select What to do</label>
                            <SelectButton className="mt-2" value={salesState.selectedEditOption} onChange={onSelectButtonChange} options={salesState.quantityEditOptions} />
                        </div>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-12 field">
                            <label>Editing Quantity</label>
                            <NumberInputWithButtons inputValue={salesState.editQuantityValue} inputButtonsClick={(e)=>setStateValue({editQuantityValue:e.value!})} inputValueChange={(e)=>setStateValue({editQuantityValue:e.value!})}/>
                        </div>
                    </div>
                    <Button className="mt-2 w-7" onClick={completeQuantityEditAction}>{`${salesState.selectedEditOption} Quantity`}</Button>
                </div>
            </OverlayPanel>
            <DiscountComponent
                discountOPRef={discountOPRef}
                discountOptions={salesState.discountOptions}
                onDiscountOptionChange={onDiscountOptionChange}
                discountSelectedOption={salesState.salesDiscount.discountOption}
                discountAmount={salesState.discountValue}
                onDiscountAmountChange={onDiscountAmountChange}
                applyDiscountAction={applyDiscount}
            />
        </>
    );
};
export default Sales;
