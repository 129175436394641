import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { displayMessage, pageDataValidation } from '../utilities/utilFunctions';
import { validateLogin } from '../utilities/joiFunctions/joiFunctions';
import { useNavigate } from 'react-router-dom';
import AppUsers from '../utilities/classObjects/AppUsers';
import { GeneralPageProps, Loader } from '../utilities/components/UtilityComponents';
import { Queue } from '../utilities/classObjects/Queue';

const users=new AppUsers();
const queueTable=new Queue();
export const Login = (props: any) => {
    const router=useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const toastRef = useRef(null);

    const onUserLogin = async () => {
        try {
            if (!pageDataValidation(validateLogin, { username, password }, toastRef)) return;
            const appState=localStorage.getItem('appState');
            setIsLoading(true);
            const results = appState===null||appState==='onLine'?await users.performUserLogin(password, username)
                :await users.verifyLocalLogin(username, password);

            if (results?.isUser) {
                localStorage.setItem('userData', JSON.stringify(results.user));
                await queueTable.initialize();
                router('/');
            } else {
                displayMessage({
                    header: 'Invalid Credentials',
                    message: 'Your details could not be verified on our platform. Try again',
                    life: 3000,
                    infoType: 'error',
                    toastComponent: toastRef
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    const onFormSubmit = async (e: any) => {
        e.preventDefault();
        await onUserLogin();
    };

    return (
        <>
            {isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="login-body">
                <div className="login-image">
                    <img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
                </div>
                <div className="login-panel p-fluid">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-logo" alt="login-logo" />
                            {/*<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-appname" alt="login-appname" />*/}
                            <h6 className="ml-3 text-4xl">Harmony</h6>
                        </div>
                        <form onSubmit={onFormSubmit}>
                        <div className="form-container">
                        <span className="p-input-icon-left">
                            <i className="pi pi-envelope"></i>
                            <InputText onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Email" autoComplete="off" />
                        </span>
                                <span className="p-input-icon-left">
                            <i className="pi pi-key"></i>
                            <InputText onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" autoComplete="off" />
                        </span>
                                <button className="flex p-link">Forgot your password?</button>
                            </div>
                            <div className="button-container">
                                <Button type="submit" label="Login" onClick={onFormSubmit}></Button>
                                <span>
                            Don’t have an account?<button className="p-link">Sign-up here</button>
                        </span>
                            </div>
                        </form>
                    </div>

                    <div className="login-footer flex align-items-center">
                        <div className="flex align-items-center login-footer-logo-container">
                            <img src="assets/layout/images/logo-gray.png" className="login-footer-logo" alt="login-footer-logo" />
                            <img src="assets/layout/images/appname-gray.png" className="login-footer-appname" alt="login-footer-appname" />
                        </div>
                        <span>Copyright 2021</span>
                    </div>
                </div>
            </div>
        </>
    );
};
