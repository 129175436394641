import { DropdownOption, TCrudType, TExpenditure, TLocationSettings } from '../../utilities/types';
import React, { useEffect, useReducer, useRef } from 'react';
import {
    changeDateFormat, defaultSettings, displayMessage, extractStateValues, getTableRowId, isAppOnline, onInputControlFocus, pageDataValidation, paymentsMethods,
    REDUCER_ACTION_TYPE, remakeDropdownSelects, StateReducerHandler
} from '../../utilities/utilFunctions';
import { DatePicker, FilterSelect, GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { Nullable } from 'primereact/ts-helpers';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import useUserData from '../../hooks/customHooks';
import { expenditureListQuery, expenseCategoriesListQuery, hotelLocationsListQuery, hotelsListQuery } from '../../utilities/reactQueryUtils';
import Settings from '../../utilities/classObjects/Settings';
import { useQueryClient } from '@tanstack/react-query';
import Hotel from '../../utilities/classObjects/Hotel';
import { DropdownChangeEvent } from 'primereact/dropdown';
import HotelLocation from '../../utilities/classObjects/HotelLocation';
import { Button } from 'primereact/button';
import Expenses from '../../utilities/classObjects/Expenses';
import { validateExpenditures } from '../../utilities/joiFunctions/joiFunctions';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import ExpendituresList from '../DataTables/ExpendituresList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import ExpenseCategory from './ExpenseCategory';

type TExpenditurePage = TExpenditure & {
    displayDate: Nullable<Date>,
    expenditureCategories: DropdownOption[],
    hotelsList: DropdownOption[],
    hotelLocations: DropdownOption[],
    selectedHotel: DropdownOption,
    selectedLocation: DropdownOption,
    selectedExpenditureCategory: DropdownOption,
    appSettings: TLocationSettings,
    crudType: TCrudType,
    paymentMethods: DropdownOption[],
    selectedPaymentMethod: DropdownOption,
    isLoading: boolean,
    showDialogBox: boolean,
    expenditureList: TExpenditure[],
    showExpenseCategoryDialog:boolean
}
const INITIAL_STATE: TExpenditurePage = {
    categoryDescription: '',
    dateCreated: changeDateFormat(new Date()),
    description: '',
    expenditureAmount: 0,
    expenditureCategory: 0,
    expenditureDate: changeDateFormat(new Date()),
    expenditureId: 0,
    hotelId: 0,
    locationId: 0,
    hotelName: '',
    locationName: '',
    modifiedBy: 0,
    paymentMethod: '',
    username: '',
    displayDate: new Date(),
    expenditureCategories: [],
    hotelsList: [],
    hotelLocations: [],
    selectedHotel: { name: '', code: 0 },
    selectedLocation: { name: '', code: 0 },
    selectedExpenditureCategory: { name: '', code: 0 },
    appSettings: defaultSettings(),
    crudType: 'save',
    paymentMethods: [],
    selectedPaymentMethod: { name: '', code: 0 },
    isLoading: false,
    showDialogBox: false,
    expenditureList: [],
    showExpenseCategoryDialog:false
};
const settings = new Settings();
const hotel = new Hotel();
const locationObject = new HotelLocation();
const expenses = new Expenses();
const Expenditures = () => {
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(StateReducerHandler<TExpenditurePage>, INITIAL_STATE);
    const [userData] = useUserData();
    const toastRef = useRef(null);

    useEffect(() => {
        const initExpenditure = async () => {
            const appSettings = await settings.getLocationSettings();
            const hotelsList = isAppOnline(appSettings.connectivityState) ? await hotelsListQuery(queryClient, userData.staffId)
                : await hotel.getOffLineHotelsList(userData.staffId);
            const expenditureCategories = await queryExpenditureCategories(appSettings.connectivityState);
            setStateValues({
                hotelsList: remakeDropdownSelects(hotelsList!, 'hotelName', 'hotelId'),
                appSettings,
                expenditureCategories: remakeDropdownSelects(expenditureCategories!, 'categoryDescription', 'categoryId'),
                paymentMethods: remakeDropdownSelects(paymentsMethods, 'name', 'name'),
                modifiedBy: userData.staffId,
                username:userData.username,
            });
        };
        initExpenditure().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TExpenditurePage>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const queryExpenditureCategories=async (appState:string)=>{
        return isAppOnline(appState) ? await expenseCategoriesListQuery(queryClient, userData.hotelLocationId)
            : await expenses.getExpenditureCategoriesOffline(userData.hotelLocationId);
    }
    const onHotelChange = async (e: DropdownChangeEvent) => {
        if (e.value == undefined) return;
        const hotelLocations = isAppOnline(state.appSettings.connectivityState) ? await hotelLocationsListQuery(queryClient, e.value.code)
            : await locationObject.getOfflineLocations(e.value.code);

        if (hotelLocations !== undefined)
            setStateValues({ hotelId: e.value.code, selectedHotel: e.value, hotelLocations: remakeDropdownSelects(hotelLocations, 'locationName', 'hotelLocationId') });
    };
    const onLocationChange = (e: DropdownChangeEvent) => {
        setStateValues({ locationId: e.value.code, selectedLocation: e.value });
    };
    const onExpenditureCategoryChange = (e: DropdownChangeEvent) => {
        setStateValues({ selectedExpenditureCategory: e.value, expenditureCategory: e.value.code,categoryDescription:e.value.name });
    };
    const onPaymentMethodChange = (e: DropdownChangeEvent) => {
        setStateValues({ paymentMethod: e.value.name, selectedPaymentMethod: e.value });
    };
    const getExpenditureDetails = (): TExpenditure => {
        const props: Array<keyof TExpenditure> = ['expenditureId', 'expenditureDate', 'expenditureAmount', 'expenditureCategory', 'description', 'paymentMethod', 'modifiedBy', 'hotelId', 'locationId'];
        return extractStateValues<TExpenditure>(props, state);
    };
    const resetExpenditure = () => {
        setStateValues({
            expenditureId: 0,
            expenditureDate: changeDateFormat(new Date()),
            displayDate: new Date(),
            expenditureAmount: 0,
            expenditureCategory: 0,
            description: '',
            paymentMethod: '',
            hotelId: 0,
            locationId: 0,
            selectedHotel: { name: '', code: 0 },
            selectedLocation: { name: '', code: 0 },
            selectedPaymentMethod: { name: '', code: 0 },
            selectedExpenditureCategory: { name: '', code: 0 },
            crudType: 'save'
        });
    };
    const saveExpenditure = async () => {
        try {
            const expensesDetails = getExpenditureDetails();
            if (!pageDataValidation(validateExpenditures, expensesDetails, toastRef)) return;
            const extendedExpenditure={ ...expensesDetails, categoryDescription: state.categoryDescription, username: state.username, modifiedBy: state.modifiedBy,dateCreated: changeDateFormat(new Date()) };
            setStateValues({isLoading:true});
            const saveExpenditureResult = isAppOnline(state.appSettings.connectivityState) ? await expenses.addNewExpenditure(expensesDetails, state.crudType)
                : await expenses.addExpenditureOffline(extendedExpenditure, state.crudType);

            if (saveExpenditureResult.status === 200) {
                state.crudType === 'save' ? await addRecordToCache<TExpenditure>(queryClient, ['expenditureList', userData.hotelLocationId], saveExpenditureResult.operatedData!)
                    : await updateCacheRecord<TExpenditure>(queryClient, ['expenditureList', userData.hotelLocationId], [saveExpenditureResult.operatedData!, state.expenditureId, 'expenditureId']);
                displayMessage({
                    header: 'Success',
                    message: 'Room Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetExpenditure();
            }
        } catch (error) {
            throw error;
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const onDialogShow = async () => {
        try {
            setStateValues({ isLoading: true });
            const expendituresList = isAppOnline(state.appSettings.connectivityState) ? await expenditureListQuery(queryClient, userData.hotelLocationId)
                : await expenses.getExpendituresOffline(userData.hotelLocationId);
            setStateValues({ expenditureList: expendituresList });
        } catch (error) {
            throw error;
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupExpenditureEdit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedExpenditure = state.expenditureList.find(expense => expense.expenditureId === parseInt(getTableRowId(e, 'id')));
        const expenditureHotel = state.hotelsList.find(hotel => hotel.code === selectedExpenditure?.hotelId);
        const expenditurePaymentMethod = state.paymentMethods.find(paymentMethod => paymentMethod.code === selectedExpenditure?.paymentMethod);
        const selectedExpenditureType = state.expenditureCategories.find(category => category.code === selectedExpenditure?.expenditureCategory);

        if (selectedExpenditure) {
            const { expenditureId, expenditureCategory, expenditureDate, expenditureAmount, description, paymentMethod, hotelId, locationId, modifiedBy, categoryDescription, username } = selectedExpenditure;
            setStateValues({
                expenditureId,
                expenditureCategory,
                hotelId,
                locationId,
                expenditureDate: changeDateFormat(new Date(expenditureDate)),
                displayDate: new Date(expenditureDate),
                expenditureAmount,
                description,
                paymentMethod,
                selectedHotel: expenditureHotel,
                selectedPaymentMethod: expenditurePaymentMethod,
                selectedExpenditureCategory: selectedExpenditureType,
                modifiedBy,
                categoryDescription,
                username,
                crudType: 'update',
                showDialogBox: false
            });
            // @ts-ignore
            onHotelChange({
                originalEvent: undefined, preventDefault(): void {
                }, stopPropagation(): void {
                }, value: expenditureHotel
            }).then(async () => {
                const hotelLocations = isAppOnline(state.appSettings.connectivityState) ? await hotelLocationsListQuery(queryClient, expenditureHotel?.code as number)
                    : await locationObject.getOfflineLocations(expenditureHotel?.code as number);
                const expenseLocation=hotelLocations?.find(location=>location.hotelLocationId === selectedExpenditure?.hotelId);
                setStateValues({selectedLocation:{name:expenseLocation?.locationName!,code:locationId}});
            });
        }
    };
    const remakeCategoryOptions=async ()=>{
        const categoriesList=await queryExpenditureCategories(state.appSettings.connectivityState);
        setStateValues({expenditureCategories:remakeDropdownSelects(categoriesList!,'categoryDescription','categoryId')});
    }
    return <>
        {state.isLoading && <Loader />}
        <GeneralPageProps toastRef={toastRef} />
        <div className="col-12">
            <Toolbar start={<LeftToolBar firstButton={{title:'List',icon:'pi pi-list',onClickEvent:() => setStateValues({ showDialogBox: true })}} />} end={<RightToolBar onNewButtonClick={()=>setStateValues({showExpenseCategoryDialog:true})}/>}></Toolbar>
        </div>
        <div className="p-fluid grid mt-2">
            <div className="col-6">
                <div className="field">
                    <DatePicker
                        dateValue={state.displayDate}
                        onDateChange={(e) => setStateValues({ displayDate: e.value!, expenditureDate: changeDateFormat(new Date(e.value! as Date)) })}
                        labelText="Expenditure Date *"
                        controlId="expenditureDate"
                        selectionType="single"
                        displayButtonBar={false}
                        displayTime={false}
                        showCalendarAsInline={true}
                        maximumDateValue={new Date()}
                    />
                </div>
            </div>
            <div className="col-6">
                <div className="grid">
                    <div className="field col-12">
                        <label>Expenditure Description *</label>
                        <InputTextarea value={state.description} onChange={(e) => setStateValues({ description: e.target.value })} rows={2} cols={30} />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="field">
                            <FilterSelect selectableOptions={state.hotelsList} selectedOption={state.selectedHotel} onSelectChange={onHotelChange} elementId="hotelId" defaultValue="Hotels *" showClearIcon={true} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field">
                            <FilterSelect selectableOptions={state.hotelLocations} selectedOption={state.selectedLocation} onSelectChange={onLocationChange} elementId="hotelLocationId" defaultValue="Hotel Location *" showClearIcon={true} />
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="grid">
                            <div className="col-10">
                                <div className="field">
                                    <FilterSelect selectableOptions={state.expenditureCategories} selectedOption={state.selectedExpenditureCategory} onSelectChange={onExpenditureCategoryChange} elementId="expenditureTypeId" defaultValue="Expenditure Type *" />
                                </div>
                            </div>
                            <div className="col-2">
                                <Button icon="pi pi-plus" className="mt-4" onClick={()=>setStateValues({showExpenseCategoryDialog:true})}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field">
                            <FilterSelect selectableOptions={state.paymentMethods} selectedOption={state.selectedPaymentMethod} onSelectChange={onPaymentMethodChange} elementId="paymentMethod" defaultValue="Payment Method *" showClearIcon={true} />
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="field">
                            <label>Expenditure Amount *</label>
                            <InputText
                                onFocus={onInputControlFocus}
                                onBlur={onInputControlFocus}
                                value={state.expenditureAmount.toString()}
                                type="number"
                                onChange={(e) => setStateValues({ expenditureAmount: parseFloat(e.target.value) })}
                                id="expenditureAmount"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field pt-4">
                            <Button onClick={saveExpenditure}>{state.crudType === 'save' ? 'Save Expenditure' : 'Update Expenditure'}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position="top" onShow={onDialogShow}>
            <ExpendituresList tableData={state.expenditureList} setupTableDataEdit={setupExpenditureEdit} promptTableDataDelete={() => {
            }} />
        </Dialog>
        {state.showExpenseCategoryDialog && <Dialog onHide={()=>setStateValues({showExpenseCategoryDialog:false})}
                                                    visible={state.showExpenseCategoryDialog} position="top" className="w-7" header="Add New Expenditure Category">
            <ExpenseCategory reQueryCategoryList={remakeCategoryOptions}/>
        </Dialog>}
    </>;
};
export default Expenditures;
