import { DropdownOption, TCrudType, THotelEvents, TLocationSettings, TPayment } from '../../utilities/types';
import { changeDateFormat, defaultSettings, displayMessage, getStateData, getTableRowId, getTotalPaid, isAppOnline, onInputControlFocus, pageDataValidation, paymentsMethods, remakeDropdownSelects } from '../../utilities/utilFunctions';
import React, { useEffect, useRef, useState } from 'react';
import Payments from '../../utilities/classObjects/Payments';
import Settings from '../../utilities/classObjects/Settings';
import { eventsPaymentsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import useUserData from '../../hooks/customHooks';
import { Divider } from 'primereact/divider';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { FilterSelect, GeneralPageProps, Loader, tableEditOption } from '../../utilities/components/UtilityComponents';
import { Button } from 'primereact/button';
import SimpleTable from '../../utilities/components/SimpleTable';
import { format } from 'date-fns/format';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import { validatePayment } from '../../utilities/joiFunctions/joiFunctions';

type TEventPaymentProp={
    hotelEventDetails:THotelEvents
}
type TEventPaymentPage=TPayment & {
    paymentMethods: DropdownOption[];
    selectedPaymentMethod: DropdownOption,
    isLoading:boolean,
    paymentsList:TPayment[],
    crudType:TCrudType,
    appSettings:TLocationSettings,
    totalPaid:number,
    totalEventCost:number,
    receivable:number
}
const INITIAL_STATE:TEventPaymentPage={
    amount: 0,
    hotelId: 0,
    hotelLocationId: 0,
    modifiedBy: 0,
    paymentDate: changeDateFormat(new Date()),
    paymentId: 0,
    activityId: 0,
    paymentMethods: [],
    selectedPaymentMethod: { name: 'Cash', code: 'Cash' },
    paymentMode:'Cash',
    isLoading:false,
    paymentsList:[],
    crudType:'save',
    appSettings:defaultSettings(),
    totalPaid:0,
    paymentChange:0,
    totalEventCost:0,
    paymentType:'event',
    receivable:0
}
const payment=new Payments();
const setting=new Settings();
/*
Since both event payment and hotel booking and reservation payments saves to the
same database table, they use generic ID identifier as activityId. Hence, their
primary IDs are referred to as activityId here.
 */
const EventPayment=({hotelEventDetails}:TEventPaymentProp)=>{
    const [state,setState]=useState<TEventPaymentPage>(INITIAL_STATE);
    const queryClient=useQueryClient();
    const [userData]=useUserData();
    const toastRef=useRef(null);
    useEffect(() => {
        const initEventPayment=async()=>{
            const appSettings=await setting.getLocationSettings();
            const eventPaymentsList=await queryEventPaymentList(appSettings.connectivityState);
            const totalPaid=getTotalPaid(eventPaymentsList!);

            setStateValues({
                paymentMethods: remakeDropdownSelects(paymentsMethods,'name','name'),
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                modifiedBy:userData.staffId,
                paymentsList:eventPaymentsList,
                appSettings:appSettings,
                totalEventCost:hotelEventDetails.eventCost,
                totalPaid,
                receivable:totalPaid-hotelEventDetails.eventCost,
                activityId:hotelEventDetails.hotelEventId
            });
        }
        initEventPayment().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TEventPaymentPage>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const queryEventPaymentList=async (appState:string)=>{
        return isAppOnline(appState)?await eventsPaymentsListQuery(queryClient,hotelEventDetails.hotelEventId!):await payment.getOfflinePayments(hotelEventDetails.hotelEventId!,'event');
    }
    const onPaymentAmountChange=(e:InputNumberChangeEvent)=>{
        const totalPayment=state.crudType==='save'?getTotalPaid(state.paymentsList)+e.value!:e.value!;
        const payChange=totalPayment-state.totalEventCost;
        setStateValues({amount:e.value!,receivable:parseFloat((totalPayment-state.totalEventCost).toFixed(2)),totalPaid:totalPayment,paymentChange:payChange<0?0:payChange});

    }
    const completePayment=async ()=>{
        try{
            if(!pageDataValidation<TEventPaymentPage>(validatePayment,getEventPaymentState(),toastRef)) return;
            setStateValues({isLoading:true});
            const eventPaymentResults=isAppOnline(state.appSettings.connectivityState)?await payment.acceptNewPayment(getEventPaymentState(),state.crudType):await payment.addOfflinePayment(getEventPaymentState(),state.crudType);
            if(eventPaymentResults.status===200){
                state.crudType === 'save'
                    ? await addRecordToCache<TPayment>(queryClient, ['hotelEventsPayments', hotelEventDetails.hotelEventId], eventPaymentResults.operatedData!)
                    : await updateCacheRecord<TPayment>(queryClient, ['hotelEventsPayments', hotelEventDetails.hotelEventId], [eventPaymentResults.operatedData,state.paymentId, 'paymentId']);
                setStateValues({paymentsList: await queryEventPaymentList(state.appSettings.connectivityState)!});
                displayMessage({
                    header: 'Success',
                    message: 'Payment Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetEventPayment();
            }
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const setupEventPaymentEdit=async (e:React.MouseEvent<HTMLButtonElement>)=>{
        const paymentId=parseInt(getTableRowId(e,'id'));
        const selectedPayment=state.paymentsList.find(payment=>payment.paymentId===paymentId);
        if(selectedPayment!==undefined){
            const {paymentId,paymentMode,amount}=selectedPayment;
            setStateValues({paymentId,paymentMode,amount,selectedPaymentMethod:{name:paymentMode,code:paymentMode},crudType:'update'})
        }
    }
    const getEventPaymentState=()=>{
        return getStateData(['activityId','paymentId','paymentDate','paymentChange','paymentMode','amount','modifiedBy','hotelId','hotelLocationId','paymentType'],state);
    }
    const promptEventPaymentDelete=async ()=>{

    }
    const resetEventPayment=()=>{
        setStateValues({paymentId:0,amount:0,selectedPaymentMethod:{name:'',code:''},crudType:'save'});
    }
    return <>
        {state.isLoading && <Loader />}
        <GeneralPageProps toastRef={toastRef} />
    <div className="card">
        <div className="grid p-fluid">
            <div className="col-5">
                <div className="grid">
                    <div className="col-12 font-bold text-2xl text-orange-500 pl-3"><span className="">Amount Payable</span>:<span className="ml-3">{state.totalEventCost}</span></div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="col-12 font-bold text-2xl text-green-500"><span className="">Amount Paid</span>:<span className="ml-3">{state.totalPaid}</span></div>
                    </div>
                    <div className="col-6">
                        <div className="col-12 font-bold text-2xl text-pink-300"><span className="">Bal</span>:<span className="ml-3">{state.receivable < 0 ? `(${Math.abs(state.receivable)})` : state.receivable}</span></div>
                    </div>
                </div>
                <Divider />
                <div className="grid p-fluid">
                    <div className="col-12">
                        <div className="fied mt-2">
                            <label>Amount Paying</label>
                            <InputNumber className="mt-2 mb-2" onChange={onPaymentAmountChange} value={state.amount} onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                        </div>
                    </div>
                </div>
                <div className="grid p-fluid">
                    <div className="col-12">
                        <FilterSelect selectableOptions={state.paymentMethods} selectedOption={state.selectedPaymentMethod} onSelectChange={(e) => setStateValues({ selectedPaymentMethod: e.value, paymentMode: e.value.code })} elementId="paymentMethod" defaultValue="Select Method" />
                    </div>
                </div>
                <div className="grid">
                    <div className="fied mt-2">
                        <Button onClick={completePayment}>{state.crudType === 'save' ? 'Add Payment' : 'Update Payment'}</Button>
                    </div>
                </div>
            </div>
            <div className="col-7">
                <div className="card w-auto">
                    <SimpleTable
                        tableKey={'paymentId'}
                        columnsDef={[
                            { body: (rowData: TPayment) => <div>{format(new Date(rowData.paymentDate), 'yyyy-MM-dd')}</div>, header: 'Date' },
                            { field: 'amount', header: 'Paid Amount' },
                            { field: 'paymentMode', header: 'Mode' },
                            { body: (rowData: TPayment) => tableEditOption(setupEventPaymentEdit, promptEventPaymentDelete, rowData.paymentId!.toString()), header: 'Edit' }
                        ]}
                        tableData={state.paymentsList}
                        tableTitle="Payments List"
                        lastTableUpdate={new Date().getTime()}
                        searchValues={['reservationDate']}
                        searchFieldPlaceHolder="Search by Full Name"
                        tableMinWidth={40}
                    />
                </div>
            </div>
        </div>
    </div>
    </>
}
export default EventPayment;
