import { TReservations, TTableProps } from '../../utilities/types';
import SimpleTable from '../../utilities/components/SimpleTable';
import { tableEditOption } from '../../utilities/components/UtilityComponents';
import React from 'react';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { MenuItem } from 'primereact/menuitem';
import { format } from 'date-fns/format';

const ReservationsList=({tableData,setupTableDataEdit,promptTableDataDelete,showReservationDialog,bookingStatusItems}:TTableProps<TReservations>
    &{showReservationDialog:(e:React.MouseEvent<HTMLButtonElement>)=>void,bookingStatusItems:MenuItem[]})=>{

    return <>
        <SimpleTable
            tableKey="reservationId"
            columnsDef={[
                {field:'guestName',header:'Guest'},
                {field:'roomNumber',header:'Room'},
                {header:'Check In',body:(rowData:TReservations)=><div>{format(new Date(rowData.checkInDate!),'yyyy-MM-dd HH:mm')}</div>},
                {header:'Check Out',body:(rowData:TReservations)=><div>{format(new Date(rowData.checkOutDate!),'yyyy-MM-dd HH:mm')}</div>},
                {field:'numberOfDays',header:'Days'},
                {field:'roomRate',header:'Rate'},
                {header:'Payable',body:(rowData:TReservations)=><div>{rowData.numberOfDays!*rowData.roomRate!}</div>},
                {field:'bookingType',header:'Type'},
                {body:(rowData:TReservations)=><SplitButton  icon="pi pi-plus" model={bookingStatusItems} raised label={rowData.status} id={rowData.reservationId?.toString()}/>,header:'Status'},
                {body:(rowData:TReservations)=><Button icon="pi pi-money-bill" onClick={showReservationDialog} data-reservation-id={rowData.reservationId}/>,header:'Payment'},
                {
                    body: (rowData: TReservations) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.reservationId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Reservations'}
            searchValues={['guestName']}
        />
    </>
}
export default ReservationsList;
