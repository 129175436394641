import React, { useEffect, useReducer, useRef } from 'react';
import {
    displayMessage, extractStateValues, getGenders, getNationalIdTypes,
    getTableRowId, isAppOnline, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, StateReducerHandler
} from '../../utilities/utilFunctions';
import { TGuestAndReservationsState, TGuests } from '../../utilities/types';
import { GuestReservationContext } from '../contextProviders/GuestReservationContextProvider';
import Guests from './Guests';
import { Button } from 'primereact/button';
import { validateGuest } from '../../utilities/joiFunctions/joiFunctions';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import useUserData from '../../hooks/customHooks';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { hotelLocationGuestsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import { Dialog } from 'primereact/dialog';
import GuestsList from '../DataTables/GuestsList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import Settings from '../../utilities/classObjects/Settings';

const INITIAL_STATE: TGuestAndReservationsState = {
    toolBarVisible: true,
    age: 0,
    guestName: '',
    emailAddress: '',
    phoneNumber: '',
    nationalIdType: '',
    idNumber: '',
    gender: '',
    nationalIDTypes: [],
    showDialogBox: false,
    guestsList: [],
    crudeType: 'save',
    guestId: 0,
    appState: 'onLine',
    hotelId:0,
    hotelLocationId:0,
    modifiedBy:0,
    placeOfStay:''
};
const guest = new BookingReservation();
const settings=new Settings();

const GuestsMain = () => {
    const [state, dispatch] = useReducer(StateReducerHandler<TGuestAndReservationsState>, INITIAL_STATE);
    const toastRef = useRef(null);
    const [userData] = useUserData();
    const queryClient = useQueryClient();

    useEffect(() => {
        const initGuest = async () => {
            const appSettings=await settings.getLocationSettings();

            setStateValues({
                nationalIDTypes: remakeDropdownSelects(getNationalIdTypes, 'name', 'code'),
                genders: remakeDropdownSelects(getGenders, 'name', 'code'),
                appState: appSettings.connectivityState,
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                modifiedBy:userData.staffId,
            });
        };
        initGuest().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TGuestAndReservationsState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getGuestDetails = (): TGuests => {
        const props: Array<keyof TGuests> = ['guestName', 'emailAddress', 'phoneNumber', 'nationalIdType', 'idNumber', 'age', 'gender', 'guestId','hotelId','hotelLocationId','modifiedBy','placeOfStay'];
        return extractStateValues<TGuests>(props, state);
    };
    const saveGuest = async () => {
        try {
            const guestData = getGuestDetails();
            if (!pageDataValidation(validateGuest, guestData, toastRef)) return;
            setStateValues({ isLoading: true });
            const savedGuestResults =  isAppOnline(state.appState) ? await guest.addNewGuest(guestData,state.crudeType!) : await guest.addLocalGuest(guestData, state.crudeType!);
            if (savedGuestResults.status === 200) {
                state.crudeType === 'save'
                    ? await addRecordToCache<TGuests>(queryClient, ['guestsList', userData.hotelLocationId], savedGuestResults.operatedData!)
                    : await updateCacheRecord<TGuests>(queryClient, ['guestsList', userData.hotelLocationId], [savedGuestResults.operatedData, state.guestId, 'guestId']);
                displayMessage({
                    header: 'Success',
                    message: 'Guest Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetGuest();
            }
        } catch (error) {
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupGuestEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedGuest = state.guestsList!.find((guest) => guest.guestId === parseInt(getTableRowId(e, 'id')));
        if (selectedGuest) {
            const { guestId, guestName, emailAddress, gender, hotelId, hotelLocationId, idNumber, nationalIdType, phoneNumber,age,placeOfStay } = selectedGuest;
            setStateValues({
                guestId,
                guestName,
                emailAddress,
                age: age,
                gender: selectedGuest.gender,
                selectedGender: { name: gender!, code: gender! },
                selectedNationalID: { name: nationalIdType!, code: nationalIdType! },
                nationalIdType,
                hotelId,
                hotelLocationId,
                idNumber: idNumber?.toString(),
                placeOfStay,
                phoneNumber,
                crudeType: 'update',
                showDialogBox: false
            });
        }
    };
    const resetGuest = () => {
        setStateValues({
            guestId: 0,
            guestName: '',
            emailAddress: '',
            age: 0,
            gender: '',
            selectedGender: { name: '', code: '' },
            selectedNationalID: { name: '', code: '' },
            nationalIdType: '',
            hotelId: 0,
            hotelLocationId: 0,
            idNumber: '',
            phoneNumber: '',
            crudeType: 'save',
            placeOfStay:''
        });
    };
    const onDialogShow = async () => {
        try{
            setStateValues({isLoading:true});
            const guestList = isAppOnline(state.appState)?await hotelLocationGuestsListQuery(queryClient, userData.hotelLocationId):await guest.getOfflineGuestsList(userData.hotelLocationId);
            setStateValues({
                guestsList: guestList
            });
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{title:'List',icon:'pi pi-list',onClickEvent:() => setStateValues({ showDialogBox: true })}} />} end={<RightToolBar/>}></Toolbar>
            </div>
            <GuestReservationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <Guests />
            </GuestReservationContext.Provider>
            <div className="field">
                <Button onClick={saveGuest}>{state.crudeType === 'save' ? 'Save Guest' : 'Update Guest'}</Button>
            </div>
            <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position={'top'} onShow={onDialogShow}>
                <GuestsList tableData={state.guestsList!} setupTableDataEdit={setupGuestEdit} promptTableDataDelete={() => {}} />
            </Dialog>
        </>
    );
};
export default GuestsMain;
