import SimpleTable from '../../utilities/components/SimpleTable';
import { TGuests, THotel, TTableProps } from '../../utilities/types';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const GuestsList=({tableData,setupTableDataEdit,promptTableDataDelete}:TTableProps<TGuests>)=>{

    return <>
        <SimpleTable
            tableKey={'guestId'}
            columnsDef={[
                {field:'guestName',header:'Hotel'},
                {field:'emailAddress',header:'Email'},
                {field:'phoneNumber',header:'Phone'},
                {field:'nationalIdType',header:'ID Type'},
                {field:'idNumber',header:'ID Number'},
                {field:'gender',header:'Gender'},
                {
                    body: (rowData: TGuests) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.guestId?.toString()),
                    header: 'Edit'
                },
            ]}
            tableData={tableData}
            tableTitle={'Guests'}
            searchValues={['guestName']}
        />
    </>
}
export default GuestsList;
