import React, { useEffect, useReducer, useRef } from 'react';
import {
    displayCountry, displayMessage, extractStateValues, getTableRowId, isAppOnline,
    pageDataValidation, REDUCER_ACTION_TYPE, StateReducerHandler
} from '../../utilities/utilFunctions';
import { TCrudType, THotelLocation, TRegistrationState } from '../../utilities/types';
import { RegistrationContext } from '../contextProviders/RegistrationContextProvider';
import Locations from './Locations';
import { Button } from 'primereact/button';
import { validateNewHotelLocation } from '../../utilities/joiFunctions/joiFunctions';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import HotelLocation from '../../utilities/classObjects/HotelLocation';
import useUserData from '../../hooks/customHooks';
import { Dialog } from 'primereact/dialog';
import LocationsList from '../DataTables/LocationsList';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { hotelLocationsDetailedListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import { getName } from 'country-list';
import { TLocationSettings } from '../../utilities/types';
import Settings from '../../utilities/classObjects/Settings';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';

type TLocationMain = TRegistrationState & {
    locationsList: THotelLocation[];
    crudType:TCrudType,
    locationSettings:TLocationSettings;
};
const INITIAL_STATE: TLocationMain = {
    toolBarVisible: true,
    showInnerSaveButton: true,
    locationName: '',
    locationAddress: '',
    locationEmail: '',
    city: '',
    locationPhoneNumber: '',
    postCode: '',
    countries: [],
    showDialogBox: false,
    locationsList: [],
    hotelLocationId:0,
    crudType:'save',
    locationSettings:{connectivityState:'offLine'},
    modifiedBy:0
};

const hotelLocation = new HotelLocation();
const settings=new Settings();
const LocationsMain = () => {
    const [state, dispatch] = useReducer(StateReducerHandler<TLocationMain>, INITIAL_STATE);
    const toastRef = useRef(null);
    const [userData, setUserData] = useUserData();
    const queryClient = useQueryClient();

    useEffect(() => {
        const initLocations = async () => {
            const appSettings=await settings.getLocationSettings();
            setStateValues({
                countries: displayCountry(), //0556719691
                hotelId: userData.hotelId,
                locationSettings:appSettings,
                modifiedBy:userData.staffId
            });
        };
        initLocations().catch(console.error);
    }, []);

    const setStateValues = (stateValues: Partial<TLocationMain>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getLocationDetails = () => {
        const props: Array<keyof THotelLocation> = ['locationName', 'locationAddress', 'locationEmail', 'locationPhoneNumber', 'city', 'postCode', 'countryCode', 'hotelId','hotelLocationId','modifiedBy'];
        return extractStateValues<THotelLocation>(props, state);
    };
    const saveLocalLocation = async () => {
        if(!isAppOnline(state.locationSettings.connectivityState)){
            displayMessage({
                header: 'Not Allowed',
                message: 'You cannot add or edit Hotel Locations locally. Switch to online first.',
                life: 3000,
                infoType: 'warn',
                toastComponent: toastRef
            });
            return;
        }
        try {
            const hotelLocationData: THotelLocation = getLocationDetails();
            if (!pageDataValidation(validateNewHotelLocation, hotelLocationData, toastRef)) return;
            setStateValues({isLoading:true});
            const saveLocationResults = await hotelLocation.addNewLocation(hotelLocationData,state.crudType);
            if (saveLocationResults.status === 200) {
                state.crudType==='save'?await addRecordToCache<THotelLocation>(queryClient,['hotelLocationsListDetailed',userData.hotelId],
                    saveLocationResults.operatedData!):await updateCacheRecord<THotelLocation>(queryClient,['hotelLocationsListDetailed',userData.hotelId],
                    [saveLocationResults.operatedData,saveLocationResults.operatedData?.hotelLocationId,'hotelLocationId']);
                displayMessage({
                    header: 'Activity Success',
                    message: 'Hotel Location data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetState();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setStateValues({ isLoading: false});
        }
    };
    const setupLocationEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const selectedLocation= state.locationsList.find((location:THotelLocation)=>location.hotelLocationId===parseInt(getTableRowId(e,'id')));
        if(selectedLocation!==undefined){
            const {city,countryCode,hotelLocationId,hotelName,locationAddress,locationEmail,locationName,locationPhoneNumber,postCode,url}=selectedLocation;
            setStateValues({
               city,
               countryCode,
                hotelLocationId,
                selectedCountry:{name:getName(countryCode!)!,code:countryCode!},
                hotelName,
                locationAddress,
                locationEmail,
                locationPhoneNumber,
                locationName,
                postCode,
                url,
                crudType:'update',
                showDialogBox:false
            });
        }
    }
    const resetState=()=>{
        setStateValues({
            city:'',
            countryCode:'',
            hotelLocationId:userData.hotelLocationId,
            selectedCountry:{name:'',code:0!},
            hotelName:'',
            locationAddress:'',
            locationEmail:'',
            locationPhoneNumber:'',
            locationName:'',
            postCode:'',
            url:'',
            crudType:'save'
        });
    }
    const onDialogShow=async ()=>{
        setStateValues({isLoading:true});
        const hotelLocations = isAppOnline(state.locationSettings.connectivityState)?await hotelLocationsDetailedListQuery(queryClient, userData.hotelId):await hotelLocation.getOfflineLocations(userData.hotelId);
        setStateValues({
            locationsList: hotelLocations,
            isLoading:false
        });
    }
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{icon:'pi pi-list',title:'List',onClickEvent:() => setStateValues({ showDialogBox: true })}} />} end={<RightToolBar/>}></Toolbar>
            </div>
            <RegistrationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <Locations key={'SelfLocations'} />
            </RegistrationContext.Provider>
            <Button onClick={saveLocalLocation}>{state.crudType==='save'?'Save Location':'Update Location'}</Button>
            {state.showDialogBox && (
                <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position={'top'} onShow={onDialogShow}>
                    <LocationsList tableData={state.locationsList} setupTableDataEdit={setupLocationEdit} promptTableDataDelete={()=>{}}/>
                </Dialog>
            )}
        </>
    );
};
export default LocationsMain;
