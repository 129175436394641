import { db } from '../../offline/db';
import { TLocationSettings, TSyncData } from '../types';
import { changeDateFormat, defaultSettings, getBaseURL } from '../utilFunctions';
import axiosFetch from '../axiosConfig';

class Settings {
    async saveSettings(settings:TLocationSettings) {
        try{
            return db.transaction('rw',[db.appSettings],async()=>{
                return  db.appSettings.put({settingsId:1,...settings});
            });
        }catch(error){
            throw error;
        }
    }
    async getLocationSettings() {
        try {
            const localSettings = await db.appSettings.get(1);
            return localSettings !== undefined ? localSettings : defaultSettings();
        } catch (error) {
            throw error;
        }
    }

    async syncRemoteDataToLocal(personId:number,hotelId:number,hotelLocationId:number){
        try{
            const data = await axiosFetch<TSyncData>('POST',`${getBaseURL()}/general/sync_remote_data`,{personId,hotelId,hotelLocationId});
            console.log(data?.data?.operatedData);
            if(data.data){
                const {hotelsList,locationsList,staffsList,roomTypesList,roomsList,guestsList,reservationsList,eventsList,eventTypesList,expenditureCategoryList,expendituresList,
                    brandsList,itemCategoryList,itemsList,purchaseList,salesList,adjustmentList,suppliersList,paymentsList,userMenu}=data.data.operatedData!;
                return db.transaction('rw',[db.hotels,db.hotelLocation,db.hotelStaff,db.roomTypes,db.hotelRooms,db.hotelGuests,
                    db.hotelReservations,db.hotelEvents,db.eventTypes,db.expenditureCategory,db.expenses,db.brands,
                    db.itemCategory,db.items,db.purchases,db.sales,db.adjustments,db.suppliers,db.payments,db.userMenu],async()=>{

                    await db.hotels.bulkPut(hotelsList);

                    await db.hotelLocation.bulkPut(locationsList);

                    await db.hotelStaff.bulkPut(staffsList);

                    await db.roomTypes.bulkPut(roomTypesList);
                    await db.hotelRooms.bulkPut(roomsList);
                    await db.hotelGuests.bulkPut(guestsList);
                    await db.hotelReservations.bulkPut(reservationsList.map((reservation)=>{
                        return {...reservation,checkInDate:changeDateFormat(new Date(reservation.checkInDate!)),
                            checkOutDate:changeDateFormat(new Date(reservation.checkOutDate!))};
                    }));
                    await db.hotelEvents.bulkPut(eventsList.map((hotelEvent)=>{
                        return {...hotelEvent,eventStartDate:changeDateFormat(new Date(hotelEvent.eventStartDate)),
                            eventEndDate:changeDateFormat(new Date(hotelEvent.eventEndDate))}
                    }));
                    await db.eventTypes.bulkPut(eventTypesList);
                    await db.expenditureCategory.bulkPut(expenditureCategoryList);
                    await db.expenses.bulkPut(expendituresList.map((expense)=>{
                        return {...expense,expenditureDate:changeDateFormat(new Date(expense.expenditureDate))}
                    }));
                    await db.brands.bulkPut(brandsList);
                    await db.itemCategory.bulkPut(itemCategoryList);
                    await db.items.bulkPut(itemsList);
                    await db.purchases.bulkPut(purchaseList);
                    await db.sales.bulkPut(salesList);
                    await db.adjustments.bulkPut(adjustmentList);
                    await db.suppliers.bulkPut(suppliersList);
                    await db.payments.bulkPut(paymentsList);
                    await db.userMenu.bulkPut(userMenu);
                    return {status:data.status}
                });
            }
        }catch(error){
            console.log(error);
            throw error;
        }
    }
    async syncLocalDataToRemote(personId:number,hotelId:number,hotelLocationId:number){
        try{
            const queuedData=await db.queuedTables.get(1);
            const {roomTypes,hotelRooms,hotelGuests,hotelReservations,payments,eventTypes,hotelEvents,expenditures,expenditureCategory,sales,purchases,adjustments}=queuedData!;

            const preparedRoomTypes=roomTypes.map((roomType)=>{return {...roomType,crudType:'save'}});
            const preparedHotelRooms=hotelRooms.map((room)=>{
                return {...room,crudType:'save'};
            });
            const preparedHotelGuests=hotelGuests.map((guest)=>{
                return {...guest,crudType:'save'};
            });
            const preparedHotelReservations=hotelReservations.map((reservation)=>{
                return {...reservation,crudType:'save'};
            });
            const preparedHotelEvents=hotelEvents.map((hotelEvent)=>{
                return {...hotelEvent,crudType:'save'};
            });
            const preparedEventTypes=eventTypes.map((eventType)=>{
                return {...eventType,crudType:'save'};
            });
            const reservationPayments=payments.filter(payment=>payment.paymentType==='booking').map((payment)=>{
                return {...payment,crudType:'save'};
            });
            const eventPayments=payments.filter(payment=>payment.paymentType==='event').map((payment)=>{
                return {...payment,crudType:'save'};
            });
            const preparedExpenditures=expenditures.map((expenditure)=>{
                return {...expenditure,crudType:'save'}
            });
            const preparedExpenditureCategory=expenditureCategory.map((expenditureCategory)=>{
                return {...expenditureCategory,crudType:'save'}
            })
            const data = await axiosFetch<number>('POST',`${getBaseURL()}/general/sync_local_data`,{personId,hotelId,hotelLocationId,
                syncItems:{hotelRooms:preparedHotelRooms,hotelGuests:preparedHotelGuests,hotelReservations:preparedHotelReservations,
                    hotelEvents:preparedHotelEvents,sales,purchases,adjustments,roomTypes:preparedRoomTypes,
                    eventTypes:preparedEventTypes,eventPayments:eventPayments,reservationPayments,expenditures:preparedExpenditures,
                    expenditureCategory:preparedExpenditureCategory}});
            return {operatedData:data.data?.operatedData}
        }catch(error){
            throw error;
        }
    }
}
export default Settings;
