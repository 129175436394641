import { useState, useEffect } from 'react';
import { TUser } from '../utilities/types';

const useUserData = () => {
    // Retrieve user data from localStorage, or default to an empty object if it doesn't exist
    const getUserData = () => {
        const userData = localStorage.getItem('userData');
        return userData ? JSON.parse(userData) : null;
    };

    // State to hold user data
    const [userData, setUserData] = useState(getUserData);

    // Update localStorage whenever userData state changes
    useEffect(() => {
        if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
        } else {
            localStorage.removeItem('userData');
        }
    }, [userData]);

    // Function to update user data
    const updateUserData = (newUserData:TUser) => {
        setUserData(newUserData);
    };

    return [userData, updateUserData];
};

export default useUserData;
