import React, { useState, useRef, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Timeline } from 'primereact/timeline';
import useUserData from '../hooks/customHooks';
import { useNavigate } from 'react-router-dom';
import { changeDateFormat, defaultSettings, getAccumulatedMonthlyExpenditures, getAccumulatedPayment,
    getAccumulatedRevenue, getBarChartOptions, getMonthlyCountSales, getRandomHexDecimalColor, getRevenueChart,
    getRoomsDistributionsChart, graphChart, isAppOnline } from '../utilities/utilFunctions';
import { getDailySummaryQuery } from '../utilities/reactQueryUtils';
import ReportActions from '../utilities/classObjects/ReportActions';
import { TChart, TDailySummaryProps, TDashboardMeta, TLocationSettings, TReservations, TRoomsDistribution } from '../utilities/types';
import { useQueryClient } from '@tanstack/react-query';
import Settings from '../utilities/classObjects/Settings';
import { DashboardSnippet, GeneralPageProps, Loader, RoomsDistributionLabel } from '../utilities/components/UtilityComponents';

const visitorChart = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Plan',
            data: [630, 630, 695, 695, 695, 760, 760, 760, 840, 840, 840, 840],
            borderColor: ['#FC6161'],
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            type: 'line',
            fill: false,
            barPercentage: 0.5,
            stepped: true
        },
        {
            label: 'Growth actual',
            data: [600, 671, 660, 665, 700, 610, 810, 790, 710, 860, 810, 780],
            backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
            fill: true,
            barPercentage: 0.5
        }
    ]
};

const countryChartOptions = {
    responsive: true
};

const revenueChart = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Sales',
            data: [37, 34, 21, 27, 10, 18, 15],
            borderColor: '#EEE500',
            pointBackgroundColor: '#EEE500',
            backgroundColor: 'rgba(238, 229, 0, 0.05)',
            fill: true,
            tension: 0.4
        },
        {
            label: 'Revenue',
            data: [31, 27, 30, 37, 23, 29, 20],
            borderColor: '#00D0DE',
            pointBackgroundColor: '#00D0DE',
            backgroundColor: 'rgba(0, 208, 222, 0.05)',
            fill: true,
            tension: 0.4
        },
        {
            label: 'Expenses',
            data: [21, 7, 13, 3, 19, 11, 6],
            borderColor: '#FC6161',
            pointBackgroundColor: '#FC6161',
            backgroundColor: 'rgba(253, 72, 74, 0.05)',
            fill: true,
            tension: 0.4
        },
        {
            label: 'Customer',
            data: [47, 31, 35, 20, 46, 39, 25],
            borderColor: '#0F8BFD',
            pointBackgroundColor: '#0F8BFD',
            backgroundColor: 'rgba(15, 139, 253, 0.05)',
            fill: true,
            tension: 0.4
        }
    ]
};


type TDashboard={
    appSettings:TLocationSettings,
    dashboardSummary?:TDailySummaryProps,
    earned:number,
    dashboardMeta:TDashboardMeta,
    bookingsChart:TChart,
    barChartOptions:any,
    revenueChart:TChart,
    revenueChartOptions:any,
    roomsDistributionChart:TChart,
    roomsDistribution:TRoomsDistribution[],
    incomingReservations:any,
    isLoading:boolean,
}
const INITIAL_STATE:TDashboard={
    appSettings:defaultSettings(),
    earned:0,
    dashboardMeta:{bookingsReservations:[],payments:[],expenditures:[],roomsBooking:[],incomingReservations:[]},
    bookingsChart:{labels:[],datasets:[]},
    barChartOptions:{},
    revenueChart:{labels:[],datasets:[]},
    revenueChartOptions:{},
    roomsDistributionChart:{labels:[],datasets:[]},
    roomsDistribution:[],
    incomingReservations:[],
    isLoading:true,
    dashboardSummary:{bookingsPayment:0,reservationsPayment:0,eventsPayment:0,totalExpenditure:0,bookingsCount:0,eventsCount:0,reservationsCount:0}
}
const report = new ReportActions();
const setting=new Settings();
const Dashboard = () => {
    const [state,setState]=useState<TDashboard>(INITIAL_STATE);
    const queryClient=useQueryClient();
    const navigate=useNavigate();
    const toastRef=useRef(null);
    const bookingsYear = [
        { name: '2024', code: '0' },
        { name: '2025', code: '1' }
    ];

    const visitorYear = [
        { name: '2020', code: '0' },
        { name: '2019', code: '1' }
    ];
    const revenueMonth = [
        { name: 'January - July 2021', code: '0' },
        { name: 'August - December 2020', code: '1' }
    ];


    const [selectedVisitorYear, setSelectedVisitorYear] = useState(visitorYear[0]);
    const [selectedRevenueMonth, setSelectedRevenueMonth] = useState(revenueMonth[0]);


    const bookingsChartRef = useRef<any>(null);

    const revenue = useRef<any>(null);
    const [userData]=useUserData();

    useEffect(() => {
        if(!userData)
            navigate('/login');
        const initDashboard=async ()=>{
            const appSettings=await setting.getLocationSettings();

            const dashboardMeta=isAppOnline(appSettings.connectivityState)?await report.getDashBoardMeta(userData.hotelLocationId):await report.getLocalDashboardMeta(userData.hotelLocationId);


            const monthlyReservationsCount=getMonthlyCountSales(parseInt(bookingsYear[0].name),extractData('Reservation',dashboardMeta.operatedData?.bookingsReservations!));
            const monthlyBookingsCount=getMonthlyCountSales(parseInt(bookingsYear[0].name),extractData('Booking',dashboardMeta.operatedData?.bookingsReservations!));
            const accumulatedPaymentsMonthly=getAccumulatedPayment(parseInt(bookingsYear[0].name),dashboardMeta.operatedData?.payments!);
            const accumulatedExpenditureMonthly=getAccumulatedMonthlyExpenditures(parseInt(bookingsYear[0].name),dashboardMeta.operatedData?.expenditures!);
            const accumulatedMonthlyRevenue=getAccumulatedRevenue(accumulatedPaymentsMonthly,accumulatedExpenditureMonthly);
            const roomsBookedTotalCount=getBookingCountTotal(dashboardMeta.operatedData!.roomsBooking!);
            const incomingReservations=dashboardMeta.operatedData?.incomingReservations.map((incoming:any)=>{
                return {...incoming,phoneColor:getRandomHexDecimalColor(),iconColor:getRandomHexDecimalColor(),checkInDate:changeDateFormat(incoming.checkInDate)}
            });

            const bookedRooms=dashboardMeta.operatedData!.roomsBooking!.map((room:TRoomsDistribution)=>{ return {...room,customColor:getRandomHexDecimalColor(),
                percentageShare:(room.bookedCount/roomsBookedTotalCount)*100}});

            const data = isAppOnline(appSettings.connectivityState!) ? await getDailySummaryQuery(queryClient, changeDateFormat(new Date()), changeDateFormat(new Date()), userData?.hotelLocationId!)
                : await report.getLocalSummary(userData?.hotelLocationId!,changeDateFormat(new Date()),changeDateFormat(new Date()));
            const dailySummary: TDailySummaryProps = typeof data![0].dailySummary === 'string' ? JSON.parse(data![0].dailySummary) : data![0].dailySummary;
            console.log(dailySummary)
            const revenue=parseFloat(dailySummary.eventsPayment as string)+parseFloat(dailySummary.reservationsPayment as string)+parseFloat(dailySummary.bookingsPayment as string);

            setStateValue({
                dashboardSummary: dailySummary,
                earned:revenue-parseFloat(dailySummary.totalExpenditure! as string),
                appSettings,
                bookingsChart:graphChart(monthlyReservationsCount,monthlyBookingsCount),
                barChartOptions:getBarChartOptions(Math.max(...monthlyReservationsCount)+100),
                revenueChart:getRevenueChart(accumulatedPaymentsMonthly,accumulatedExpenditureMonthly,accumulatedMonthlyRevenue),
                revenueChartOptions:getBarChartOptions(Math.max(...accumulatedPaymentsMonthly,...accumulatedExpenditureMonthly)),
                roomsDistributionChart:getRoomsDistributionsChart(bookedRooms),
                roomsDistribution:bookedRooms,
                incomingReservations:incomingReservations,
                isLoading:false
            });
        }
        initDashboard().catch(console.error);
    }, []);
    const setStateValue = (stateValues: Partial<TDashboard>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const extractData=(bookingType:string,dataList:TReservations[])=>{
        return dataList.filter(data=>data.bookingType===bookingType);
    }
    const getBookingCountTotal=(roomsBooking:TRoomsDistribution[])=>{
       return roomsBooking.reduce((previousValue,currentValue)=>{
            return previousValue+currentValue.bookedCount;
        },0);
    }
    const changeRevenueChart = (event: any) => {
        setSelectedRevenueMonth(event.value);
        const dataSet1 = [
            [37, 34, 21, 27, 10, 18, 15],
            [31, 27, 30, 37, 23, 29, 20],
            [21, 7, 13, 3, 19, 11, 6],
            [47, 31, 35, 20, 46, 39, 25]
        ];
        const dataSet2 = [
            [31, 27, 30, 37, 23, 29, 20],
            [47, 31, 35, 20, 46, 39, 25],
            [37, 34, 21, 27, 10, 18, 15],
            [21, 7, 13, 3, 19, 11, 6]
        ];

        if (event.value.code === '1') {
            revenueChart.datasets[0].data = dataSet2[parseInt('0')];
            revenueChart.datasets[1].data = dataSet2[parseInt('1')];
            revenueChart.datasets[2].data = dataSet2[parseInt('2')];
            revenueChart.datasets[3].data = dataSet2[parseInt('3')];
        } else {
            revenueChart.datasets[0].data = dataSet1[parseInt('0')];
            revenueChart.datasets[1].data = dataSet1[parseInt('1')];
            revenueChart.datasets[2].data = dataSet1[parseInt('2')];
            revenueChart.datasets[3].data = dataSet1[parseInt('3')];
        }

        revenue.current.refresh();
    };

    const changeVisitorChart = (event: any) => {
        setSelectedVisitorYear(event.value);
        const dataSet1 = [
            [630, 630, 695, 695, 695, 760, 760, 760, 840, 840, 840, 840],
            [600, 671, 660, 665, 700, 610, 810, 790, 710, 860, 810, 780]
        ];
        const dataSet2 = [
            [580, 580, 620, 620, 620, 680, 680, 680, 730, 730, 730, 730],
            [550, 592, 600, 605, 630, 649, 660, 690, 710, 720, 730, 780]
        ];

        if (event.value.code === '1') {
            visitorChart.datasets[0].data = dataSet2[parseInt('0')];
            visitorChart.datasets[1].data = dataSet2[parseInt('1')];
        } else {
            visitorChart.datasets[0].data = dataSet1[parseInt('0')];
            visitorChart.datasets[1].data = dataSet1[parseInt('1')];
        }

        bookingsChartRef.current.refresh();
    };


    const marker = (item: any) => {
        return (
            <span className="custom-marker" style={{ backgroundColor: item.iconColor }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const content = (item: any) => {
        return (
            <>
                <div className="flex align-items-center justify-content-between">
                    <p>{item.guestName}</p>
                    <h6 style={{ color: item.phoneColor }}> {item.phoneNumber}</h6>
                </div>
                <span>{item.checkInDate}</span>
            </>
        );
    };

    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="layout-dashboard">
                <div className="grid">
                    <DashboardSnippet snippetData={parseFloat(state.dashboardSummary?.bookingsPayment! as string) + parseFloat(state.dashboardSummary?.reservationsPayment as string)} title='BOOKINGS' keyValue="DS1" />
                    <DashboardSnippet snippetData={parseFloat(state.dashboardSummary?.eventsPayment! as string)} title='EVENTS' keyValue="DS2" />
                    <DashboardSnippet snippetData={parseFloat(state.dashboardSummary?.totalExpenditure! as string)} title='EXPENDITURE' keyValue="DS3" />
                    <DashboardSnippet snippetData={state.earned!} title='EARNINGS' keyValue="EARNINGS" />
                    <div className="col-12 md:col-8">
                        <div className="card widget-visitor-graph">
                            {/*<div className="card-header">*/}
                            {/*    <span>Reservations And Bookings</span>*/}
                            {/*    <Dropdown options={visitorYear} value={selectedVisitorYear} optionLabel="name" onChange={changeVisitorChart}></Dropdown>*/}
                            {/*</div>*/}
                            <div className="graph">
                                <h6>Revenue</h6>
                                <Chart ref={bookingsChartRef} type="bar" data={state.bookingsChart} options={state.barChartOptions} id="visitor-chart"></Chart>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="card widget-timeline">
                            <div className="timeline-header flex justify-content-between align-items-center">
                                <p>Incoming Reservations</p>
                                <div className="header-icons">
                                    <i className="pi pi-refresh"></i>
                                    <i className="pi pi-filter"></i>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <Timeline value={state.incomingReservations} marker={marker} content={content} className="custimized-timeline" />
                            </div>
                            <div className="timeline-footer flex align-items-center justify-content-center">
                                <button className="p-link">
                                    View all Reservations <i className="pi pi-arrow-down"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="card widget-country-graph">
                            <div className="country-title">Room Distribution</div>
                            <div className="country-graph flex justify-content-center">
                                <Chart type="doughnut" id="rooms-chart" data={state.roomsDistributionChart} options={countryChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                            </div>
                            <div className="country-content">
                                <ul>
                                    {
                                        state.roomsDistribution !== undefined && state.roomsDistribution.map((room, index) => {
                                            return <RoomsDistributionLabel backgroundColor={room.customColor} roomNumber={room.roomNumber} bookedCount={room.bookedCount} percentShare={room.percentageShare} key={`RD${index}`} />
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-8">
                        <div className="card widget-revenue-graph">
                            {/*<div className="card-header">*/}
                            {/*    <span>Monthly revenue</span>*/}
                            {/*    <Dropdown options={revenueMonth} value={selectedRevenueMonth} optionLabel="name" onChange={changeRevenueChart}></Dropdown>*/}
                            {/*</div>*/}

                            <div className="graph">
                                <Chart ref={revenue} type="line" id="revenue-chart" data={state.revenueChart} options={state.revenueChartOptions}></Chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
