import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useRegistrationContext } from '../contextProviders/RegistrationContextProvider';
import { Button } from 'primereact/button';

const Hotels = () => {
    const { state, setStateFunction: setStateValues } = useRegistrationContext();
    return (
        <>
            <div className="grid p-fluid">
                <div className="lg:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="hotelName">Hotel Name</label>
                                    <InputText id="hotelName" type="text" value={state?.hotelName} onChange={(e) => setStateValues!({ hotelName: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <label htmlFor="institutionPhone">Hotel Phone</label>
                                    <InputText id="institutionPhone" type="text" value={state?.hotelPhone} onChange={(e) => setStateValues!({ hotelPhone: e.target.value })} autoComplete="off" />
                                </div>
                            </div>
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="institutionAddress">Hotel Email</label>
                                    <InputText id="institutionAddress" type="text" value={state?.emailAddress} onChange={(e) => setStateValues!({ emailAddress: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    {state?.showInnerSaveButton && <Button>Save Hotel</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Hotels;
