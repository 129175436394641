import { DropdownOption, TAppState, TCrudType, THotelEvents } from '../../utilities/types';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useReducer, useRef } from 'react';
import {
    changeDateFormat, displayMessage, extractStateValues, getTableRowId, isAppOnline, onInputControlFocus,
    pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, StateReducerHandler
} from '../../utilities/utilFunctions';
import { InputText } from 'primereact/inputtext';
import { DatePicker, EventMoreDetails, GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import useUserData from '../../hooks/customHooks';
import { useQueryClient } from '@tanstack/react-query';
import { hotelEventsListQuery, hotelEventTypesListQuery } from '../../utilities/reactQueryUtils';
import { validateHotelEvent } from '../../utilities/joiFunctions/joiFunctions';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import HotelEventsList from '../DataTables/HotelEventsList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import Settings from '../../utilities/classObjects/Settings';
import EventTypes from './EventTypes';
import EventPayment from './EventPayment';

type THotelEventState = THotelEvents & {
    eventTypes: DropdownOption[];
    selectedEventType: DropdownOption;
    isLoading:boolean,
    showDialogBox:boolean,
    hotelEventsList:THotelEvents[],
    crudType:TCrudType,
    appState:TAppState,
    showEventTypesDialog:boolean,
    showEventMoreDetailsDialog:boolean,
    selectedEvent?:THotelEvents
};
const INITIAL_STATE: THotelEventState = {
    eventCost: 0,
    eventEndDate: changeDateFormat(new Date()),
    eventHost: '',
    eventStartDate: changeDateFormat(new Date()),
    eventTypeId: 0,
    hotelEventId: 0,
    eventTypes: [],
    selectedEventType: { name: '', code: 0 },
    eventEndDateDisplay:new Date(),
    eventStartDateDisplay:new Date(),
    hotelId:0,
    hotelLocationId:0,
    isLoading:false,
    showDialogBox:false,
    hotelEventsList:[],
    crudType:'save',
    appState:'onLine',
    modifiedBy:0,
    showEventTypesDialog:false,
    showEventMoreDetailsDialog:false
};
const reservation=new BookingReservation();
const setting=new Settings();
const HotelEvents = () => {
    const [state,dispatch]=useReducer(StateReducerHandler<THotelEventState>,INITIAL_STATE);
    const [userData]=useUserData();
    const queryClient=useQueryClient();
    const toastRef=useRef(null);

    useEffect(()=>{
        const initEvents=async ()=>{
            const appSettings=await setting.getLocationSettings();
            const eventTypes=await queryEventTypesList(appSettings.connectivityState);
            setStateValues({
                eventTypes:remakeDropdownSelects(eventTypes!,'eventTypeDesc','eventTypeId'),
                hotelId:userData.hotelId,
                hotelLocationId:userData.hotelLocationId,
                appState:appSettings.connectivityState,
                modifiedBy:userData.staffId
            });
        }
        initEvents().catch(console.error);
    },[]);

    const queryEventTypesList=async (appState:string)=>{
        return isAppOnline(appState)?await hotelEventTypesListQuery(queryClient,userData.hotelLocationId)
            :await reservation.getOfflineEventTypes(userData.hotelLocationId);
    }
    const setStateValues = (stateValues: Partial<THotelEventState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const getEventDetails=():THotelEvents=>{
        const props: Array<keyof THotelEvents> = ['eventTypeId','eventStartDate','eventEndDate','eventHost','eventCost','hotelId','hotelLocationId','hotelEventId','modifiedBy'];
        return extractStateValues<THotelEvents>(props, state);
    }
    const saveEvent=async ()=>{
        try{
            const eventDetails=getEventDetails();
            if(!pageDataValidation(validateHotelEvent,eventDetails,toastRef)) return;

            setStateValues({isLoading:true});
            const savedEventResults=state.appState==='onLine'?await reservation.addNewHotelEvent(eventDetails,state.crudType)
                :await reservation.addEventLocal({...eventDetails,eventTypeDesc:state.eventTypeDesc,dateCreated:changeDateFormat(new Date())},state.crudType);
            if(savedEventResults?.status===200){
                state.crudType==='save'?await addRecordToCache<THotelEvents>(queryClient,['hotelEventsList',userData.hotelLocationId],
                    savedEventResults.operatedData!):await updateCacheRecord<THotelEvents>(queryClient,['hotelEventsList',userData.hotelLocationId],
                    [savedEventResults.operatedData,state.hotelEventId,'hotelEventId']);
                displayMessage({
                    header: 'Success',
                    message: 'Event Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetEvent();
            }
        }catch(error){

        }finally{
            setStateValues({isLoading:false});
        }
    }
    const setupEventEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const selectedEvent=state.hotelEventsList.find(item=>item.hotelEventId===parseInt(getTableRowId(e,'id')));
        if(selectedEvent){
            const {hotelEventId,eventTypeId,eventStartDate,eventEndDate,eventHost,eventCost,hotelId,hotelLocationId,eventTypeDesc}=selectedEvent;
            setStateValues({
                hotelEventId,
                eventTypeId,
                eventStartDate:changeDateFormat(new Date(eventStartDate)),
                eventEndDate:changeDateFormat(new Date(eventEndDate)),
                eventEndDateDisplay:new Date(eventEndDate),
                eventStartDateDisplay:new Date(eventStartDate),
                eventHost,
                eventCost,
                hotelId,
                hotelLocationId,
                showDialogBox:false,
                eventTypeDesc,
                selectedEventType:{name:eventTypeDesc!,code:eventTypeId},
                crudType:'update'
            });
        }
    }
    const resetEvent=()=>{
        setStateValues({
            hotelEventId:0,
            eventTypeId:0,
            eventStartDate:changeDateFormat(new Date()),
            eventEndDate:changeDateFormat(new Date()),
            eventEndDateDisplay:new Date(),
            eventStartDateDisplay:new Date(),
            eventHost:'',
            eventCost:0,
            eventTypeDesc:'',
            selectedEventType:{name:'',code:''},
            crudType:'save'
        });
    }
    const onDialogShow=async ()=>{
        try{
            setStateValues({isLoading:true});
            const eventsList=isAppOnline(state.appState)?await hotelEventsListQuery(queryClient,userData.hotelLocationId)
                :await reservation.getOfflineEventsList(userData.hotelLocationId);
            setStateValues({
                hotelEventsList:eventsList
            });
        }catch(error){
            throw error;
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const onAddNewEventType=()=>{
        setStateValues({showEventTypesDialog:true});
    }
    const remakeEventTypesList=async ()=>{
        const eventTypesList=await queryEventTypesList(state.appState);
        if(eventTypesList){
            setStateValues({eventTypes:remakeDropdownSelects(eventTypesList,'eventTypeDesc','eventTypeId')});
        }
    }
    const showEventDetails=(e:React.MouseEvent<HTMLButtonElement>)=>{
        const eventId=parseInt((e.target as HTMLButtonElement).getAttribute('data-event-id')!);
        const selectedEvent=state.hotelEventsList.find(eventItem=>eventItem.hotelEventId===eventId);
        setStateValues({selectedEvent,showEventMoreDetailsDialog:true});
    }
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{title:'List',icon:'pi pi-list',onClickEvent:() => setStateValues({ showDialogBox: true })}} />} end={<RightToolBar onNewButtonClick={onAddNewEventType}/>}></Toolbar>
            </div>
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="eventHost">Event Host</label>
                                    <InputText id="eventHost" type="text" value={state?.eventHost} onChange={(e) => setStateValues!({ eventHost: e.target.value })} autoComplete="off" />
                                </div>
                                <div className="field">
                                    <div className="grid">
                                        <div className="col-11">
                                            <label htmlFor="genders">Event Type</label>
                                            <Dropdown
                                                value={state?.selectedEventType}
                                                onChange={(e) =>
                                                    setStateValues!({
                                                        selectedEventType: e.value,
                                                        eventTypeId: e.value.code,
                                                        eventTypeDesc: e.value.name
                                                    })
                                                }
                                                options={state?.eventTypes}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-1 mt-3">
                                            <Button icon="pi pi-plus" onClick={()=>setStateValues({showEventTypesDialog:true})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="eventCost">Event Cost</label>
                                    <InputNumber id="eventCost" value={state.eventCost} onChange={(e) => setStateValues({ eventCost: e.value! })} onFocus={onInputControlFocus} onBlur={onInputControlFocus}/>
                                </div>
                            </div>
                            <div className="lg:col-6">
                                <div className="field">
                                    <label htmlFor="eventStartDate">Start Date</label>
                                    <DatePicker dateValue={state?.eventStartDateDisplay} onDateChange={(e) => setStateValues!({ eventStartDateDisplay: e.value, eventStartDate: changeDateFormat(e.value) })} labelText="Event Start" controlId="eventStart" selectionType="single" />
                                </div>
                                <div className="field">
                                    <label htmlFor="eventEndDate">End Date</label>
                                    <DatePicker dateValue={state?.eventEndDateDisplay} onDateChange={(e) => setStateValues!({ eventEndDateDisplay: e.value, eventEndDate: changeDateFormat(e.value) })} labelText="Event End" controlId="eventEnd" selectionType="single" />
                                </div>
                                <div className="field">
                                    <Button className="mt-4" onClick={saveEvent}>{state.crudType==='save'?'Save Event':'Update Event'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog onHide={()=>setStateValues({showDialogBox:false})} visible={state.showDialogBox} position={'top'} onShow={onDialogShow}>
                <HotelEventsList tableData={state.hotelEventsList} setupTableDataEdit={setupEventEdit} promptTableDataDelete={()=>{}} showEventDetails={showEventDetails}/>
            </Dialog>
            <Dialog onHide={()=>setStateValues({showEventTypesDialog:false})} visible={state.showEventTypesDialog} position="top" className="w-7" header="Add Event Type">
                <EventTypes hotelId={userData.hotelId} hotelLocationId={userData.hotelLocationId} appState={state.appState} userId={userData.staffId} reQueryEventsList={remakeEventTypesList}/>
            </Dialog>
            {state.showEventMoreDetailsDialog && <Dialog onHide={()=>setStateValues({showEventMoreDetailsDialog:false})}
                                                         visible={state.showEventMoreDetailsDialog} position="top" className="w-10">
                <EventMoreDetails panelInfo={state.selectedEvent!}/>
                <EventPayment hotelEventDetails={state.selectedEvent!}/>
            </Dialog>}
        </>
    );
};
export default HotelEvents;
