import { QueryClient, QueryKey } from '@tanstack/react-query';
const _=require('lodash');

export async function addNewItemToCache<T>(existingArray: T[], newItem: T) {
    return [...existingArray, newItem];
}
export async function updateCacheItem<T>(updatedItem: T, itemBeforeUpdate: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeUpdate);
    const newItems: T[] = [...allItems];
    _.set(newItems, indexOfItem, updatedItem);

    return newItems;
}
export async function deleteCacheItem<T>(deletingItem: T, itemBeforeDelete: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeDelete);

    const clonedItems = [...allItems];

    _.pullAt(clonedItems, indexOfItem);

    return clonedItems;
}

export async function addRecordToCache<T>(queryClient: QueryClient, keyInCache: QueryKey, newItem: T) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const updatedArray = await addNewItemToCache(existingArray, newItem);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, updatedArray);
    } catch (error) {
        console.log(error);
    }
}

export async function updateCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: any) {
    try {
        const updatedItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const selectedRecord = existingArray?.find((item: any) => item[itemIdProp] === itemId);
        if (selectedRecord) {
            const updatedArray = await updateCacheItem<T | string>(updatedItem, selectedRecord!, existingArray);

            // Update the cache with the updated array

            queryClient.setQueryData(keyInCache, [...updatedArray]);
        }
    } catch (error: any) {
        console.log(error);
    }
}
export async function deleteCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: any[]) {
    try {

        const deletingItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        const selectedRecord = existingArray.find((rec: any) => rec[itemIdProp] === itemId);

        const remainingData = await deleteCacheItem(deletingItem, selectedRecord, existingArray);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, remainingData);
    } catch (error) {
        console.log(error);
    }
}
