import SimpleTable from '../../utilities/components/SimpleTable';
import { TItem, TTableProps } from '../../utilities/types';
import { format } from 'date-fns/format';
import { tableEditOption } from '../../utilities/components/UtilityComponents';

const ItemsList=({ tableData, setupTableDataEdit, promptTableDataDelete }: TTableProps<TItem>)=>{

    return (
        <>
            <SimpleTable
                tableKey={'itemId'}
                columnsDef={[
                    { field: 'itemName', header: 'Name' },
                    { field: 'itemCost', header: 'Cost' },
                    { field: 'itemPrice', header: 'Price' },
                    { field: 'quantityInStock', header: 'Quantity' },
                    { body: (rowData: TItem) => <div>{format(new Date(rowData.expirationDate as Date), 'yyyy-MM-dd')}</div>, header: 'Expiring' },
                    { body: (rowData: TItem) => tableEditOption(setupTableDataEdit, promptTableDataDelete, rowData.itemId.toString()), header: 'Edit' }
                ]}
                tableData={tableData}
                tableTitle="Items List"
                lastTableUpdate={new Date().getTime()}
                searchValues={['itemName', 'itemDescription', 'expirationDate']}
                searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
            />
        </>
    )
}
export default ItemsList;
